export default {
    mainTitle: 'BZabc',
    notFound: '404',
    about: '關於我們',
    parentsStudents: '家長和學生',
    schoolsTeachers: '學校和教師',
    termsAndConditions: '條款和條件',
    methodology: '方法',
    techSpecs: '系統技術規範',
    privacyPolicy: '隱私政策',
    parents: '家長',
    home: 'Home',
}