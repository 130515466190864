import { all } from 'redux-saga/effects';
import appSagas from './app/sagas';
import storeSagas from './store/sagas';

function* rootSaga () {
  yield all([
    appSagas,                     
    storeSagas
  ])
}

export default rootSaga;