export default {
// Header and main links //
  about: "關於",
  login: "註冊",
  signin: "登入",
  signup: "報名",
  sendGift: "送禮物",
  subscription: "訂閱",
  publishers: "出版商",
  platform: "平台",
  parentsStudents: "學生和家長",
  schoolsTeachers: "學校和老師",
  subscriptionStartingFrom: "開始 <0>$8.99</0>/月",
  workBooksAndDownloads: "作業本\n&下載",
  bookstore: "書店",
  ourbookstore: "我們的書店",
  overview: "概述",
  pdf: "PDF",
// End - Header and main links //
// Top Titles//
  welcomeToGravityBrain: "Elearning for Everyone",
  giveGiftOfLearning: "BZabc 學習平台",
  professionallyDesignedCourses:
    "針對年輕學習者的拼字、數學以及語言",
// END Top Titles //
// App Download Center //
  AppDownload: "應用程式下載",
  AppDownloadDescription: "在使用Kids App之前，學習者必須由其學校或父母/監護人註冊並報名參加課程。",
// END App Download Center //
// The BZabc Learning Platform //
  welcomeToWebsite: "BZabc個人學習或學校連結",
  aboutUsNote:
    "家長可以要求孩子參加家庭學習課程，也可以由學校要求，然後家長可以連結到孩子的帳戶。 連結孩子的帳戶最好的方法是使用孩子的憑據線上登錄BZabc.tv並將自己添加到他們的帳戶中。 這將使您能夠訪問他們的報告以及學校的訊息。",
  splashBannerPoint1: "用於個人學習或學校連結",
  splashBannerPoint2: "訂閱可使家長註冊學生課程",
  splashBannerPoint3: "個人或學校課程的及時報告",
  splashBannerPoint4: "即時報告和訊息",
  LetsStartButton: "開始吧",
// END The BZabc Learning Platform //
// BZabc Kids App //
  BZabcMethod: "BZabc兒童 App",
  practicalSkillDevelopment:
    "iOS. Android. 無論時間、地點",
  practicalSkillDevelopmentDescription:
    "一旦家長或學校註冊了課程，BZabc兒童 App可以不受限制地安裝在任何移動設備上，並可以在任何具有互聯網連接的地方使用。 ",
  consistency: "Eye Candy",
  consistencyDescription:
    "BZabc提供動畫教學電影，然後每節課都有一個簡短的測驗。 孩子擁有最好的學習改變！",
  totalPhysicalResponse: "Dancing Shoes Optional",
  totalPhysicalResponseDescription:
    "每次訂閱都包含動作歌曲，因為詹姆斯·阿舍（James Asher）教授採用了“全身反應”方法 ",
  dailyPractice: "Continuity: 每日練習",
  dailyPracticeDescription:
    "每天在您的每個BZabc課程上花費幾分鐘。 一致性對於您的學習道路上很重要。 設置完成後，孩子們可以通過我們的自動指導和一鍵式登入輕鬆地獨自作業。",
// END BZabc Kids App //
// Tech Specs //
  techSpecs: "技術規格",
  forAllPlatforms: "對於所有平台",
  forAllPlatformsDescription:
    "BZabc兒童 APP可在Android，iOS以及Windows 7和10上使用。我們的家長APP也可在所有平台上使用。",
  blendedLearning: "混合式學習",
  blendedLearningDescription:
    "資料連接在軟體，內容和作業簿之間，從而在整個學習過程中保持連續性和一致性。",
  personalGuidance: "個人指導",
  personalGuidanceDescription:
    "BZabc APP利用學生的表現來指導他們整個課程。雲端數據的追蹤和處理意味著無論何時何地學習，學生都會在正確的頁面上",
  trackingReporting: "追蹤/報告",
  trackingReportingDescription:
    "在我們的系統上追蹤學生的表現。分數和錯誤報告數據可即時提供給家長。這使家長可以看到孩子何時作業以及他們可能面臨的任何挑戰。",
// END Tech Specs //
// Bottom Titles//
  bottomCourseInfo:
    "針對您孩子的拼寫、數學和語言",
  ourPlatformInfo: "BZabc學習平台",
// END Bottom Titles //
// Bottom Links //
  store: "商店",
  privacy: "隱私政策",
  terms: "服務條款",
 // END Bottom Links //
//Additional Info //
  learningPlatform: "學習平台",
  learnMore: "了解更多",
  productsNotFound: "找不到產品...",
  appDownloadCenter: "應用程式下載",
  appDownloadCenterDescription: "在使用Kids App之前，學習者必須由其學校或父母/監護人註冊並報名參加課程。",
  more: "更多...",
  parentsApp: "BZabc 家長",
  kidsApp: "BZabc 兒童",
// END Additional Info //
};
