import translations from './translations';
export default {
  ...translations,
  
    // Principaux titres //
    giveGiftOfLearning: "La Plateforme d'Apprentissage BZabc",
    professionallyDesignedCourses:
      "Orthographe, mathématiques et langues\npour les jeunes apprenants",
    generalInfoGB:
      "GravityBrain Inc. se consacre à produire des systèmes d'apprentissage électronique avec des résultats significatifs, des idées utiles et des outils pratiques pour la communauté éducative, en utilisant une technologie axée sur les données pour exploiter les matériaux et méthodes traditionnels.",
    // FIN Principaux titres //
    // Centre de Téléchargement de l'Application //
    AppDownload: "Centre de Téléchargement de l'Application",
    AppDownloadDescription:
      "Avant d'utiliser l'application pour enfants, les apprenants doivent être inscrits et inscrits\n dans des cours par leur école ou leurs parents/tuteurs.",
    // FIN Centre de Téléchargement de l'Application //
    // La Plateforme d'Apprentissage BZabc //
    aboutGravityBrainNote: "Paragraphe sur GravityBrain de 1 à 3 phrases",
    welcomeToGravityBrain: "Apprentissage en ligne pour Tous",
    InteractiveTestingAndTraining: "Tests et Formation : Jeunes Apprenants",
    aboutBZabcNote:
      "BZabc est conçu pour les jeunes apprenants, dans le but d'améliorer considérablement l'acquisition de compétences dans les matières de base telles que le langage et les mathématiques. Hautement interactif, riche en médias, rapports en temps réel et messagerie instantanée ne sont que quelques-unes des fonctionnalités.",
    authoringTools: "Outils de Publication et d'Autorisation",
    aboutAuthoringToolsNote:
      "Les éditeurs indépendants peuvent publier des cours interactifs basés sur la vidéo sur GravityBrain via GravityBrain Pubtool. Les écrivains, auteurs, artistes visuels et d'enregistrement peuvent collaborer pour créer leurs propres programmes d'études, avec des opportunités de partage des revenus.",
    multimediaDevelopment: "Développement Multimédia",
    aboutMMDevelopmentNote:
      "GravityBrain Inc. s'est associé à Inno-Care.ca pour produire tout le contenu multimédia sur notre système. Inno-Care est une organisation à but non lucratif qui propose des tarifs de studio compétitifs en animation 3D, A/V et production sonore, tout en offrant un emploi significatif aux personnes ayant des besoins spéciaux.",
    trainingSystemSAAS: "SAAS d'Apprentissage en Ligne",
    aboutTrainingSystemsSAASNote:
      "Pour les gouvernements ou les grandes écoles qui souhaitent exploiter leur propre service, nous proposons un système de gestion de l'apprentissage robuste avec de nombreuses fonctionnalités : apprentissage personnalisé basé sur les données, outils de rapport, publication, applications, messagerie et bien d'autres fonctionnalités.",
    contactInfo:
      "Email : office@gravitybrain.com\nTéléphone : 1-306-570-6488\nSkype : gravity_brain\nBP 568 Hamiota, \nR0M 0T0, CANADA",
    splashBannerPoint1: "Enregistrement et inscription rapide",
    splashBannerPoint2: "Leçons engageantes et interactives",
    splashBannerPoint3: "Apprentissage personnalisé basé sur les données",
    splashBannerPoint4: "Rapports et messagerie en temps réel",
    LetsStartButton: "Commençons",
    // FIN La Plateforme d'Apprentissage BZabc //
    // La Méthode BZabc - Avantages //
    BZabcMethod: "La Méthode BZabc",
    practicalSkillDevelopment: "Développement de Compétences Pratiques",
    practicalSkillDevelopmentDescription:
      "Écouter. Parler. Lire. Écrire.\n BZabc aide les étudiants à internaliser ces compétences essentielles, en encourageant la participation à une variété d'activités.",
    consistency: "Consistance",
    consistencyDescription:
      "Les matériaux sont connectés entre le logiciel, le contenu et les cahiers d'exercices, offrant une cohérence tout au long du processus d'apprentissage. Un environnement d'apprentissage véritablement intégré et connecté.",
    totalPhysicalResponse: "Réponse Physique Totale",
    totalPhysicalResponseDescription:
      "À la maison ou en classe, le contenu excitant encourage la participation à de nombreux niveaux. Des objectifs d'apprentissage clairs et atteignables sont très gratifiants, maintenant les étudiants engagés et motivés.",
    dailyPractice: "Continuité : Pratique Quotidienne",
    dailyPracticeDescription:
      "L'application BZabc Kids est facile à utiliser et guide les jeunes apprenants dans toutes les activités, numériques et écrites, tout au long de leur cours. Jours, semaines et mois.",
    // FIN La Méthode BZabc - Avantages //
    // Spécifications Techniques //
    techSpecs: "Spécifications Techniques",
    forAllPlatforms: "Pour Toutes les Plateformes",
    forAllPlatformsDescription:
      "L'application BZabc Kids est disponible sur Android, iOS et Windows 7 & 10. Notre application pour parents est également disponible sur toutes les plateformes.",
    blendedLearning: "Apprentissage Mixte",
    blendedLearningDescription:
      "Les matériaux sont connectés entre le logiciel, le contenu et les cahiers d'exercices, assurant ainsi une continuité et une cohérence tout au long du processus d'apprentissage.",
    personalGuidance: "Orientation Personnelle",
    personalGuidanceDescription:
      "L'application BZabc utilise les performances des étudiants pour les guider tout au long de leurs cours. Le suivi et le traitement des données basés sur le cloud signifient que où et quand ils apprennent, les étudiants sont sur la bonne voie.",
    trackingReporting: "Suivi / Rapports",
    trackingReportingDescription:
      "Les performances des étudiants sont suivies sur notre système. Les scores et les rapports d'erreurs sont disponibles en temps réel pour les parents. Cela permet aux parents de voir quand les enfants ont travaillé et les défis qu'ils ont pu rencontrer.",
    // FIN Spécifications Techniques //
    // Titres Inférieurs //
    bottomCourseInfo: "Orthographe, mathématiques et langues\n pour jeunes apprenants",
    ourPlatformInfo: "La Plateforme d'Apprentissage BZabc",
    // FIN Titres Inférieurs //
    // Liens Inférieurs //
    store: "Boutique",
    privacy: "Politique de Confidentialité",
    terms: "Conditions d'Utilisation",
    // FIN Liens Inférieurs //
    // Informations Supplémentaires //
    learningPlatform: "Plateforme d'Apprentissage",
    learnMore: "En savoir plus",
    productsNotFound: "Produits non trouvés...",
    appDownloadCenter: "Centre de Téléchargement de l'Application",
    appDownloadCenterDescription:
      "Avant d'utiliser l'application pour enfants, les apprenants doivent être inscrits et inscrits dans des cours par leur école ou leurs parents/tuteurs.",
    more: "plus...",
    parentsApp: "BZabc Parents",
    kidsApp: "BZabc Kids",
  
  
// END Additional Info //
};
