import translations from "./translations";
export default {
  ...translations,
  giveGiftOfLearning: "학습 플랫폼",
  professionallyDesignedCourses:
    "유치원에서 6학년까지의 모든 학종을 제공합니다.",
  pageDescription: "학교 사용자 계정",
  pricipalsAndAdmins: "교장과 관리자",
  principalAndAdminsDescription:
    "* 자신 및 모든 직원, 교사, 학생을 등록하세요. CSV 업로드를 사용하여 쉽고 빠르게 처리하세요.\n* 학생과 교사를 홈룸으로 그룹화하세요.\n* 발행된 강좌에 전체 홈룸 또는 개인을 등록하거나, 자체 콘텐츠 또는 우리 콘텐츠를 사용하여 자체 강좌를 생성하세요.\n* 학생 보고서를 집계 또는 개별 형식으로 확인하세요.\n* 메시지 그룹을 생성하고 권한을 제어하며 부모님과 통신하기 위해 SMS 서비스를 활용하세요.",
  SAAS: "국가, 주 또는 지방을 위한 SAAS",
  SAASDescription:
    "* 모회사인 GravityBrain Inc.는 저렴한 비용의 소프트웨어 서비스를 제공합니다. 자세한 내용은 문의하세요.",
  schoolSignup: "학교 등록",
  contactInfo:
    "이메일: office@gravitybrain.com\n전화: 1-306-570-6488\nSkype: gravity_brain\nPO Box 568 Hamiota, \nR0M 0T0, 캐나다",

  gravityBrainInc: "GravityBrain Inc.",
  purpose: "목적",
  purposeDescription:
    "GravityBrain은 2007년 설립 이후 온라인 학습 소프트웨어를 개발해 왔습니다. 우리의 미션은 언제나 학생, 부모, 학교, 출판사, 정부 기관 등 교육 커뮤니티의 요구를 포괄하는 서비스를 제공하는 것이었습니다. 이는 매우 큰 목표입니다.",
  services: "서비스",
  servicesDescription:
    "우리의 서비스 측면에서, 우리는 언어, 수학, 사회과학, 과학 등 모든 핵심 과목의 기술 훈련을 제공하는 단일 솔루션을 제공합니다.\n* 데이터 중심의 맞춤형 학습\n* 문제 영역에 대한 깊은 통찰력을 제공하는 학교 및 부모를 위한 보고 도구\n* 권한 기반의 개인 정보 보호, 가벼운 중앙화된 메시징 시스템. 이를 통해 부모님은 자녀의 교사와 한 곳에서 연결됩니다.\n* 출판 도구를 사용하여 출판사, 아티스트 또는 학교가 자체 자료를 시스템에 게시하고 개인적 또는 공개적인 배포를 선택하고 수익을 공유할 수 있습니다.",
  BZabcKidsApp: "BZabc 어린이 앱",
  BZabcKidsAppDescription:
    "* 아이들이 매력적인 애니메이션 비디오를 시청하고 대화형 퀴즈를 풉니다. 참여가 학습의 열쇠입니다!\n* 학생들은 수업을 진행하는 동안 항상 올바른 페이지에 안내됩니다.\n* 아이들은 이미지 클릭으로 쉽게 사용자 인터페이스에 액세스할 수 있습니다.\n* 영어 및 철자 수업은 작성 당시에 이용 가능하며, 수학, 스페인어, 프랑스어의 새로운 강좌가 개발 중입니다.",
  BZabcParentsApp: "BZabc 부모 앱",
  BZabcParentsAppDescription:
    "* 개인 공부 또는 학교 연결에 사용\n* 부모님은 자녀의 학교와 BZabc ID를 통해 연결할 수 있습니다.\n* 개인 또는 학교 강좌를 위한 실시간 보고 및 메시징 액세스\n* 구독을 통해 부모님은 자녀를 개인 강좌에 등록할 수 있습니다.",
  schoolPricipalAdmin: "학교 사용자 계정: 교장과 관리자",
  schoolPrincipalAdminDescription:
    "* 자신 및 모든 직원, 교사 및 학생을 등록하세요. CSV 업로드를 사용하여 쉽고 빠르게 처리하세요.\n* 학생과 교사를 홈룸으로 그룹화하세요.\n* 발행된 강좌에 전체 홈룸 또는 개인을 등록하거나, 자체 콘텐츠 또는 우리 콘텐츠를 사용하여 자체 강좌를 생성하세요.\n* 학생 보고서를 집계 또는 개별 형식으로 확인하세요.\n* 메시지 그룹을 생성하고 권한을 제어하며 부모님과 통신하기 위해 SMS 서비스를 활용하세요.",
  teachers: "교사",
  teachersDescription:
    "* 수업/시험 일정을 관리하세요.\n* 그룹 또는 개인 학생에 대한 자동 보고서 확인\n* 수동 보고서 카드 생성\n* SMS 내에서 개별 또는 그룹으로 학생과 부모님에게 메시지 보내기\n* 수업 계획, 워크시트 등을 게시하고 판매 수익에 커미션을 받으세요.",
  schoolBoardsBandCouncils: "학교 이사회, 부대회",
  schoolBoardBandCouncilsDescription:
    "* 추가 권한 레이어가 있는 모든 학교 제어\n* 귀하의 지역의 모든 학교 관리\n* 자세한 내용 및 지원은 문의하세요.",
  publishingAuthoringArtist: "출판, 저작, 예술가",
  publishers: "출판사",
  publishersDescription:
    "* 인터랙티브하고 데이터 중심의 학습을 위해 인쇄된 워크북을 BZabc 학습 플랫폼에 출판하여 제공하세요.\n* 구독 및 강좌 라이선스 구매로부터 수익을 올리세요.\n* 현재 채널 또는 온라인 스토어를 통해 인쇄 자료로부터 지속적인 수익을 올리세요.",
  contentCreators: "콘텐츠 제작자",
  contentCreatorsDescription:
    "* BZabc는 BZabc 시리즈에 기여하고자 하는 분들을 위해 수익 공유를 제공합니다.\n* 수업 계획서, 워크시트 등을 시스템에 게시하세요. 판매 수익의 80%를 받으세요.\n* 학습 비디오, 음성, 이미지를 공유하세요. 사용되면 로열티를 받을 수 있습니다.",
  SAASCountryStateProvince: "국가, 주, 또는 지방을 위한 SAAS",
  SAASCountryStateProvinceDescription:
    "GravityBrain Inc.는 전체 플랫폼에 대한 저렴한 소프트웨어 서비스를 제공합니다. 위의 모든 기능에 추가로, 견고하고 비용 효율적이며 안전한 시스템을 위한 서버 설치 지원을 제공합니다. 자세한 내용은 문의하세요.",

  AppDownload: "앱 다운로드 센터",
  AppDownloadDescription:
    "어린이 앱을 사용하기 전에 학습자는 학교 또는 부모/보호자에 의해 등록되고 강좌에 등록되어야 합니다. 자세한 내용은 문서를 참조하거나 시작하기 영상을 시청하세요.",

  welcomeToWebsite: "BZabc 학교 웹도구",
  aboutUsNote:
    "강좌 라이선스를 제공하는 동시에 BZabc에는 학교에 무료로 사용할 수 있는 다양한 웹 도구가 있습니다.",
  splashBannerPoint1: "학생 및 교사 대규모 등록",
  splashBannerPoint2: "홈룸별 쉬운 수업 등록",
  splashBannerPoint3: "세밀한 세부 정보가 있는 강력한 보고 시스템",
  splashBannerPoint4: "권한 기반 실시간 메시지 시스템",
  LetsStartButton: "시작",

  BZabcMethod: "학교용 BZabc",
  practicalSkillDevelopment: "함께 학습",
  practicalSkillDevelopmentDescription:
    "다수의 수업, 학생, 부모 및 조교가 있는 홈룸을 효과적으로 관리하세요. 간단한 홈룸, 수업 및 강력한 메시지 시스템 설정 및 관리",
  consistency: "향상된 교육",
  consistencyDescription:
    "가끔은 안내가 필요한 경우도 있고 혼자서 일할 때도 있습니다. BZabc는 교사 주도 교실에서 작동하도록 구성되어 있어 각 학생의 필요에 대한 더 나은 통찰력을 제공합니다.",
  totalPhysicalResponse: "움직이자!",
  totalPhysicalResponseDescription:
    "아이들은 움직임을 좋아합니다.\nBZabc는 이를 토대로 한 총체적 신체 반응 방법을 통해 더 나은 학습 결과를 이끌어냅니다.",
  dailyPractice: "교사를 위한 편리한 도구",
  dailyPracticeDescription:
    "강좌 관리 도구, 학생 중심 계획 및 평가 템플릿 빌더.",

  techSpecs: "기술 사양",
  forAllPlatforms: "모든 플랫폼용",
  forAllPlatformsDescription:
    "BZabc 어린이 앱은 Android, iOS 및 Windows 7 및 10에서 사용할 수 있습니다. 부모용 앱도 모든 플랫폼에서 사용할 수 있습니다.",
  blendedLearning: "혼합 학습",
  blendedLearningDescription:
    "소프트웨어, 콘텐츠 및 문제집 사이의 연결을 제공하여 학습 과정 전체에서 일관성과 일관성을 제공합니다.",
  personalGuidance: "개인 지도",
  personalGuidanceDescription:
    "BZabc 앱은 학생의 성과를 활용하여 강좌 전반에 걸쳐 학생을 안내합니다. 클라우드 기반 데이터 추적 및 처리를 활용하므로 어디서든 언제든 학습할 수 있습니다.",
  trackingReporting: "추적 / 보고",
  trackingReportingDescription:
    "학생의 성적은 우리 시스템에서 추적됩니다. 점수 및 오류 보고 데이터는 부모님에게 실시간으로 제공됩니다. 이를 통해 부모님은 학생이 언제 공부했는지와 마주한 어떤 어려움이 있는지 확인할 수 있습니다.",

  store: "스토어",
  privacy: "개인정보 처리방침",
  terms: "서비스 약관",

  bottomCourseInfo:
    "유치원에서 6학년까지의 모든 학종을 제공합니다.",
  ourPlatformInfo: "BZabc 학습 플랫폼",

  learningPlatform: "학습 플랫폼",
  learnMore: "자세히 알아보기",
  productsNotFound: "제품을 찾을 수 없습니다...",
  appDownloadCenter: "앱 다운로드 센터",
  appDownloadCenterDescription:
    "어린이 앱을 사용하기 전에 학습자는 학교 또는 부모/보호자에 의해 강좌에 등록 및 등록되어야 합니다.",

  parentsApp: "BZabc 부모 앱",
  kidsApp: "BZabc 어린이 앱",
};
