export default {
    mainTitle: 'BZabc',
    notFound: '404',
    about: 'Acerca de Nosotros',
    parentsStudents: 'Padres y Estudiantes',
    schoolsTeachers: 'Escuelas y Profesores',
    termsAndConditions: 'Términos y Condiciones',
    methodology: 'Metodología',
    techSpecs: 'Especificaciones Técnicas',
    privacyPolicy: 'Política de Privacidad',
    parents: 'Padres',
    home: 'Home',
}