import { all } from 'redux-saga/effects';

import {
  GET_RECORDS_FAIL,
  GET_SINGLE_RECORD_FAIL
} from './actions';

import {yieldErrorToasts} from '../../helpers/utils';

const storeSagas = all([
  yieldErrorToasts([
    GET_RECORDS_FAIL,
    GET_SINGLE_RECORD_FAIL
  ])
]);

export default storeSagas;