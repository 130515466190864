import translations from "./translations";
export default {
  ...translations,
  giveGiftOfLearning: "Regala el Don del Aprendizaje",
  professionallyDesignedCourses:
    "Ofrece todos los cursos académicos\ndesde el jardín de infantes hasta el sexto grado.",
  pageDescription: "Cuentas de Usuario de la Escuela",
  pricipalsAndAdmins: "Directores y Administradores",
  principalAndAdminsDescription:
    "* Registra a todos los empleados, profesores y estudiantes. Usa la carga CSV para un procesamiento rápido y sencillo.\n* Agrupa a estudiantes y profesores en aulas.\n* Matricula a aulas completas o individuos en nuestros cursos publicados, o crea los tuyos propios usando nuestro contenido o el tuyo.\n* Ve informes de estudiantes en formato agregado o individual.\n* Utiliza el servicio de SMS para crear grupos de mensajes, controlar permisos y comunicarte con los padres.",
  SAAS: "SAAS para Países, Estados o Provincias",
  SAASDescription:
    "* La empresa matriz GravityBrain Inc. ofrece Servicios de Software asequibles para toda nuestra plataforma. Ponte en contacto con nosotros para más detalles.",
  schoolSignup: "Registro Escolar",
  contactInfo:
    "Email: office@gravitybrain.com\nTeléfono: 1-306-570-6488\nSkype: gravity_brain\nApartado Postal 568 Hamiota, MB\nR0M 0T0, Canadá",

  gravityBrainInc: "GravityBrain Inc.",
  purpose: "Propósito",
  purposeDescription:
    "GravityBrain ha estado desarrollando Software de Aprendizaje en línea desde su fundación en 2007. Nuestra misión siempre ha sido proporcionar un servicio que abarque las necesidades de toda la comunidad educativa: estudiantes, padres, escuelas, editoriales y agencias gubernamentales. Es una gran responsabilidad.",
  services: "Servicios",
  servicesDescription:
    "En términos de nuestros servicios, ofrecemos una solución única que proporciona capacitación en habilidades en todas las materias principales: idiomas, matemáticas, estudios sociales, ciencias.\n* Aprendizaje personalizado orientado por datos.\n* Herramientas de informes para escuelas y padres con conocimientos profundos sobre áreas problemáticas.\n* Un sistema de mensajería centralizado, ligero y basado en permisos. Esto significa que los padres están conectados a los profesores de sus hijos en un solo lugar.\n* Herramientas de publicación que permiten a editoriales, artistas o las propias escuelas publicar material en nuestro sistema, con la capacidad de elegir la distribución privada o pública y compartir ingresos.",
  BZabcKidsApp: "Aplicación BZabc para Niños",
  BZabcKidsAppDescription:
    "* Los niños ven videos animados atractivos y responden a cuestionarios interactivos. La participación es clave para el aprendizaje.\n* Los alumnos son guiados a través de las lecciones, siempre en la página correcta.\n* Los niños tienen acceso fácil a su interfaz con inicio de sesión por clic en imagen.\n* Cursos en Inglés y Ortografía disponibles en el momento de la escritura, con nuevos cursos en Matemáticas, Español y Francés en desarrollo.",
  BZabcParentsApp: "Aplicación BZabc para Padres",
  BZabcParentsAppDescription:
    "* Úsalo para estudio privado o vinculado a la escuela.\n* Los padres pueden conectarse a la escuela de sus hijos a través de su ID de BZabc.\n* Acceso a informes y mensajes en tiempo real para cursos privados o escolares.\n* La suscripción permite a los padres inscribir a los alumnos en cursos privados.",
  schoolPricipalAdmin: "Cuentas de Usuario de la Escuela: Directores y Administradores",
  schoolPrincipalAdminDescription:
    "* Registra a todos los empleados, profesores y estudiantes. Usa la carga CSV para un procesamiento rápido y sencillo.\n* Agrupa a estudiantes y profesores en aulas.\n* Matricula a aulas completas o individuos en nuestros cursos publicados, o crea los tuyos propios usando nuestro contenido o el tuyo.\n* Ve informes de estudiantes en formato agregado o individual.\n* Utiliza el servicio de SMS para crear grupos de mensajes, controlar permisos y comunicarte con los padres.",
  teachers: "Profesores",
  teachersDescription:
    "* Administra el horario de clases/exámenes.\n* Ve informes automáticos sobre estudiantes en grupos o individualmente.\n* Crea tarjetas de informes manualmente.\n* Envía mensajes a estudiantes y padres individualmente o en grupos dentro de nuestro sistema de SMS.\n* Publica tus planes de clase, actividades en nuestra tienda y recibe comisiones por las ventas.",
  schoolBoardsBandCouncils: "Consejos Escolares, Consejos de Bandas",
  schoolBoardBandCouncilsDescription:
    "* Todos los controles escolares con capas adicionales de permisos.\n* Gestión de todas las escuelas en tu región.\n* Ponte en contacto con nosotros para obtener más información y soporte.",
  publishingAuthoringArtist: "Publicación, Autoría, Artista",
  publishers: "Editoriales",
  publishersDescription:
    "* Complementa tus libros de ejercicios impresos con aprendizaje interactivo y orientado por datos publicando en la Plataforma de Aprendizaje BZabc.\n* Genera ingresos con suscripciones y compras de licencias de cursos.\n* Sigue generando ingresos con materiales impresos a través de tus canales actuales y/o a través de nuestra tienda en línea.",
  contentCreators: "Creadores de Contenido",
  contentCreatorsDescription:
    "* La BZabc ahora ofrece participación en ganancias para aquellos que deseen contribuir a la serie BZabc.\n* Publica tus planes de clase, actividades en nuestra tienda. Recibe el 80% de las ventas, menos descuentos y comisiones de ventas que tú controlas.\n* Comparte tus videos de aprendizaje, sonidos e imágenes. Cada vez que se usen, recibirás una comisión.",
  SAASCountryStateProvince: "SAAS para Países, Estados o Provincias",
  SAASCountryStateProvinceDescription:
    "GravityBrain Inc. ofrece Servicios de Software asequibles para toda nuestra plataforma. Además de todas las funciones anteriores, ofrecemos asistencia en la configuración del servidor para un sistema robusto, eficiente en costos y seguro. Ponte en contacto con nosotros para obtener más detalles.",

  AppDownload: "Centro de Descarga de Aplicaciones",
  AppDownloadDescription:
    "Antes de usar la Aplicación para Niños, los Alumnos deben estar registrados y matriculados\n en cursos por la escuela. Consulte nuestra documentación o vea el video de inicio para obtener más detalles.",

  welcomeToWebsite: "Herramientas Web de la Escuela BZabc",
  aboutUsNote:
    "Además de ofrecer licencias de cursos, BZabc tiene varias herramientas web disponibles de forma gratuita para las escuelas.",
  splashBannerPoint1: "Registro masivo para alumnos y profesores",
  splashBannerPoint2: "Matriculación fácil por aula",
  splashBannerPoint3: "Sistemas de informes robustos con detalles minuciosos",
  splashBannerPoint4: "Sistema de mensajería en tiempo real basado en permisos",
  LetsStartButton: "Comenzar",

  BZabcMethod: "BZabc para Escuelas",
  practicalSkillDevelopment: "Aprendizaje Juntos",
  practicalSkillDevelopmentDescription:
    "Salas con varias clases, alumnos, padres y TAs,... Mantén todo organizado con BZabc. Configuración fácil y manejo de salas, clases y un sistema de mensajería robusto",
  consistency: "Enseñanza Mejorada",
  consistencyDescription:
    "A veces, los niños necesitan guía; otras veces, necesitan trabajar solos. BZabc está estructurado para funcionar dentro de un aula dirigida por el profesor, brindando mejores conocimientos sobre las necesidades de cada alumno.",
  totalPhysicalResponse: "¡Vamos a Moverse!",
  totalPhysicalResponseDescription:
    "A los niños les encanta moverse.\nBZabc lo incorpora en el método de Respuesta Física Total, lo que lleva a mejores resultados de aprendizaje.",
  dailyPractice: "Herramientas Geniales para Profesores",
  dailyPracticeDescription:
    "Herramientas de gestión de clases. Constructores de plantillas de clases y evaluaciones centradas en el alumno.",

  techSpecs: "Especificaciones Técnicas",
  forAllPlatforms: "Para Todas las Plataformas",
  forAllPlatformsDescription:
    "La Aplicación para Niños de BZabc está disponible en Android, iOS y Windows 7 y 10. La Aplicación para Padres también está disponible en todas las plataformas.",
  blendedLearning: "Aprendizaje Híbrido",
  blendedLearningDescription:
    "Los materiales están conectados entre el software, el contenido y los libros, proporcionando continuidad y coherencia a lo largo del proceso de aprendizaje.",
  personalGuidance: "Orientación Personalizada",
  personalGuidanceDescription:
    "La Aplicación BZabc utiliza el rendimiento del alumno para guiarlo a lo largo de sus cursos. El seguimiento y procesamiento de datos basados en la nube significan que, donde y cuando aprenden, los alumnos están en la página correcta.",
  trackingReporting: "Seguimiento / Reportes",
  trackingReportingDescription:
    "El rendimiento del alumno se rastrea en nuestro sistema. Los datos de calificaciones y reportes de errores están disponibles en tiempo real para los padres. Esto les permite ver cuándo estudiaron los hijos y qué desafíos enfrentaron.",

  store: "Tienda",
  privacy: "Política de Privacidad",
  terms: "Términos de Servicio",

  bottomCourseInfo:
    "Ofrece todas las disciplinas académicas desde el jardín de infantes hasta el sexto grado.",
  ourPlatformInfo: "La Plataforma de Aprendizaje BZabc",

  learningPlatform: "Plataforma de Aprendizaje",
  learnMore: "Saber Más",
  productsNotFound: "Productos no encontrados...",
  appDownloadCenter: "Centro de Descarga de Aplicaciones",
  appDownloadCenterDescription:
    "Antes de usar la Aplicación para Niños, los Alumnos deben estar registrados y matriculados en cursos por la escuela o por los padres/responsables.",

  parentsApp: "BZabc para Padres",
  kidsApp: "BZabc para Niños",
};
