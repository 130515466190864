export const LOAD = '[App] LOAD';
export const LOAD_SUCCESS_AUTHENTICATED = '[App] LOAD_SUCCESS_AUTHENTICATED';
export const LOAD_SUCCESS_UNAUTHENTICATED = '[App] LOAD_SUCCESS_UNAUTHENTICATED';
export const LOAD_FAIL = '[App] LOAD_FAIL';

export function load()
{
  return {
    type: LOAD_SUCCESS_UNAUTHENTICATED
  }
}