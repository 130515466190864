export default {
    errors: {
        400: 'Pedido Ruim',
        401: 'Não Autorizado',
        403: 'Você não tem as permissões certas',
        404: 'Registro não encontrado',
        405: 'Pedido Ruim. Ação não permitida...',
        422: 'Dado não processável',
        409: 'Pedido Ruim',
        429: 'Muitas Tentativas. Por favor tente depois...',
        500: 'Erro de servidor. Por favor tente de novo depois',
        canceled: 'O pedido foi cancelado',
        unknown: 'Erro desconhecido. Por favor tente de novo depois'
    }
}