import translations from './translations';
export default {
  ...translations,
  // 頂部標題 //

  giveGiftOfLearning: "學習平台",
  professionallyDesignedCourses:
    "提供幼稚園至六年級所有學科。",
  pageDescription: "學校用戶帳戶",
  pricipalsAndAdmins: "校長和管理員",
  principalAndAdminsDescription:
    "* 註冊您自己以及所有教職員工、教師和學生。使用 CSV 上傳進行快速處理\n* 將學生和教師分組成班級\n* 將整個班級或個人註冊到我們發布的課程中，或者使用我們的內容或您自己的內容創建您自己的課程\n* 查看學生報告以集體形式或個人形式\n* 利用簡訊服務創建消息群組、控制權限並與家長溝通。",
  SAAS: "面向國家、州或省份的SAAS",
  SAASDescription:
    "* 母公司GravityBrain Inc. 提供我們整個平台的低成本軟體即服務，請聯繫我們以獲取詳細信息",
  schoolSignup: "學校註冊",
  contactInfo:
    "電子郵件：office@gravitybrain.com\n電話：1-306-570-6488\nSkype：gravity_brain\nPO Box 568 Hamiota, \nR0M 0T0, CANADA",

  gravityBrainInc: "GravityBrain Inc.",
  purpose: "目的",
  purposeDescription: "GravityBrain自2007年成立以來一直在開發電子學習軟體。我們的使命始終是提供一項服務，滿足整個教育界的需求：學生、家長、學校、出版商和政府機構。這是一個龐大的訂單。",
  services: "服務",
  servicesDescription: "就我們的服務而言，我們提供一個單一解決方案，涵蓋所有核心科目的技能培訓：語言、數學、社會研究、科學\n* 個性化的數據驅動學習\n* 學校和家長的報告工具，深入洞察問題領域\n* 基於權限的私人、輕量級集中式消息系統。意味著家長可以在一個地方與他們的孩子教師聯繫\n* 發布工具，允許出版商、藝術家或學校自行發布在我們的系統上的材料，並且可以選擇私人或公共分發，並分享收入",
  BZabcKidsApp: "BZabc Kids App",
  BZabcKidsAppDescription: "* 孩子觀看引人入勝的動畫視頻，並參加互動測驗。參與是學習的關鍵！\n* 學生通過課程引導，始終處於正確的頁面上\n* kids have easy access to their UI with pic-click login\n* 目前提供英語和拼寫課程，正在開發數學、西班牙語和法語課程。",
  BZabcParentsApp: "BZabc Parents App",
  BZabcParentsAppDescription: "* 用於私人學習或學校連接\n* 家長可以通過他們的BZabc ID與孩子的學校聯繫\n* 訪問私人或學校課程的實時報告和消息\n* 訂閱允許家長註冊學生到私人課程",
  schoolPricipalAdmin: "學校用戶帳戶：校長和管理員",
  schoolPrincipalAdminDescription: "*註冊您自己以及所有教職員工、教師和學生。使用 CSV 上傳進行快速處理\n* 將學生和教師分組成班級\n* 將整個班級或個人註冊到我們發布的課程中，或者使用我們的內容或您自己的內容創建您自己的課程\n* 查看學生報告以集體形式或個人形式\n* 利用簡訊服務創建消息群組、控制權限並與家長溝通",
  teachers: "教師",
  teachersDescription: "* 管理課程/考試排程\n* 查看學生報告，以組或個人形式\n* 生成手動成績單\n* 向學生和家長發送個人或群組簡訊\n* 發布您的教案、工作表到我們的商店並獲得銷售佣金",
  schoolBoardsBandCouncils: "學校董事會、樂隊理事會",
  schoolBoardBandCouncilsDescription: "* 所有學校控制功能，加上權限層\n* 管理您區域內的所有學校\n* 聯繫我們以獲取更多信息和支持",
  publishingAuthoringArtist: "出版、撰寫、藝術家",
  publishers: "出版商",
  publishersDescription: "* 通過在BZabc學習平台上發布互動、數據驅動學習，補充您的印刷工作簿\n* 在訂閱和課程許可購買上獲得收益\n* 通過您當前的渠道或我們的線上商店持續獲得印刷材料的收益",
  contentCreators: "內容創作者",
  contentCreatorsDescription: "* BZabc現在為那些希望為BZabc系列做出貢獻的人提供利潤分享\n* 將您的教案、工作表發布到我們的商店。獲得80%的銷售額，減去您控制的折扣和銷售佣金\n* 分享您的學習視頻、音頻和圖片。每當它們被使用時，您將獲得版稅",
  SAASCountryStateProvince: "面向國家、州或省份的SAAS",
  SAASCountryStateProvinceDescription: "GravityBrain Inc.為我們的整個平台提供低成本的軟體即服務。除了上述所有功能外，我們還提供伺服器設置協助，實現堅固、高效、安全的系統。請聯繫我們獲取詳細信息。",

  // END 頂部標題 //
  // 應用程式下載中心 //
  AppDownload: "應用程式下載中心",
  AppDownloadDescription:
    "在使用兒童應用程式之前，學習者必須由學校註冊並註冊\n 在課程中。請查看我們的文件或觀看入門視頻以獲取詳細信息",
  // END 應用程式下載中心 //
  // BZabc學習平台 //
  welcomeToWebsite: "BZabc學校網絡工具",
  aboutUsNote:
    "除了提供課程許可證之外，BZabc還有多個Web工具可供學校免費使用。",
  splashBannerPoint1: "學生和教師的大規模註冊",
  splashBannerPoint2: "按班級輕鬆註冊",
  splashBannerPoint3: "具有極細節的強大報告系統",
  splashBannerPoint4: "基於權限的實時消息系統",
  LetsStartButton: "開始",
  // 開始按鈕用於學校註冊。END BZabc學習平台 //
  // BZabc方法-好處 //
  BZabcMethod: "BZabc為學校",
  practicalSkillDevelopment: "一起學習",
  practicalSkillDevelopmentDescription:
    "具有多個班級、學生、家長和助教的班級，......通過BZabc將一切整合在一起。輕鬆設置和管理班級、課程以及強大的消息系統",
  consistency: "增強教學",
  consistencyDescription:
    "有時孩子需要一個引導；其他時候他們需要獨立工作。 BZabc被設計為在以教師為主導的課堂內工作，使您更好地了解每個學生的需求。",
  totalPhysicalResponse: "讓我們動起來！",
  totalPhysicalResponseDescription:
    "孩子們喜歡運動。\nBZabc將這種運動融入到全身反應法中，從而實現更好的學習成果。",
  dailyPractice: "教師的酷工具",
  dailyPracticeDescription:
    "課程管理工具。以學生為中心的計劃和評估模板構建器。",
  // END BZabc方法-好處 //
  // 技術規格 //
  techSpecs: "技術規格",
  forAllPlatforms: "適用於所有平台",
  forAllPlatformsDescription:
    "BZabc Kids App在Android、iOS和Windows 7和10上都可用。我們的家長應用程序也可在所有平台上使用。",
  blendedLearning: "混合學習",
  blendedLearningDescription:
    "軟體、內容和工作簿之間的材料相互關聯，確保學習過程的連貫性和一致性。",
  personalGuidance: "個人指導",
  personalGuidanceDescription:
    "BZabc App利用學生的表現來指導他們完成課程。基於雲的數據跟蹤和處理意味著學生無論何時何地學習，都在正確的頁面上。",
  trackingReporting: "跟蹤/報告",
  trackingReportingDescription:
    "我們的系統跟蹤學生的表現。成績和錯誤報告數據即時提供給家長。這使家長可以看到孩子何時工作以及可能遇到的任何挑戰。",
  // END 技術規格 //
  // 底部鏈接 //
  store: "商店",
  privacy: "隱私政策",
  terms: "服務條款",
  // END 底部鏈接 //
  // 底部標題//
  bottomCourseInfo:
    "提供幼稚園至六年級所有學科。",
  ourPlatformInfo: "BZabc學習平台",
  // END 底部標題 //
  // 附加信息 //
  learningPlatform: "學習平台",
  learnMore: "瞭解更多",
  productsNotFound: "未找到產品...",
  appDownloadCenter: "應用程式下載中心",
  appDownloadCenterDescription:
    "在使用兒童應用程式之前，學習者必須由學校或其家長/監護人註冊並註冊在課程中。",
  more: "更多...",
  parentsApp: "BZabc家長",
  kidsApp: "BZabc兒童",
};
