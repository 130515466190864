import messages from './messages';
import translations from './translations';
import pageTitles from './pageTitles';
import parents from './parents';
import platform from './platform';
import schools from './schools';
import publishers from './publishers';

export default {
    messages, translations, pageTitles, parents, platform, schools, publishers
}