import translations from './translations';

export default {
  ...translations,
  // 顶部标题 //
  giveGiftOfLearning: "BZabc学习平台",
  professionallyDesignedCourses: "为年轻学习者设计的拼写、数学和语言课程",
  generalInfoGB:
    "GravityBrain Inc.致力于为教育界提供具有意义的结果、洞察力和有用工具的电子学习系统，利用数据驱动技术来提升传统材料和方法。",
  // 结束 顶部标题 //
  // 应用下载中心 //
  AppDownload: "应用下载中心",
  AppDownloadDescription:
    "在使用Kids App之前，学习者必须由学校或其父母/监护人注册并报名参加课程。",
  // 结束 应用下载中心 //
  // BZabc学习平台 //
  aboutGravityBrainNote: "关于GravityBrain的段落，1-3句话",
  welcomeToGravityBrain: "电子学习，面向所有人",
  InteractiveTestingAndTraining: "测试和培训：早期学习者",
  aboutBZabcNote:
    "BZabc适用于年轻学习者，旨在大大提升核心科目语言和数学培训的技能获取。高度互动，媒体丰富，实时报告和即时通讯只是其中的一些特点。",
  authoringTools: "发布和撰写工具",
  aboutAuthoringToolsNote:
    "独立的出版商可以通过GravityBrain Pubtool在GravityBrain上发布互动式、视频课程。作家、创作者、视觉和录音艺术家可以合作构建自己的课程，共享收入机会。",
  multimediaDevelopment: "多媒体开发",
  aboutMMDevelopmentNote:
    "GravityBrain Inc.与Inno-Care.ca合作，在我们的系统中制作所有多媒体。Inno-Care是一个非营利性组织，提供竞争性的3D动画、音视频和音频制作费率，同时为特殊需求的人提供有意义的就业机会。",
  trainingSystemSAAS: "电子学习SAAS",
  aboutTrainingSystemsSAASNote:
    "对于希望运营自己服务的政府或较大学校，我们提供功能强大的学习管理系统，具有众多功能：个性化、数据驱动的学习。报告。发布工具，应用程序。消息。还有许多其他功能。",
  contactInfo:
    "电子邮件：office@gravitybrain.com\n电话：1-306-570-6488\nSkype：gravity_brain\nPO Box 568 Hamiota, \nR0M 0T0，加拿大",
  splashBannerPoint1: "快速注册和报名",
  splashBannerPoint2: "有趣的互动课程",
  splashBannerPoint3: "个性化数据驱动学习",
  splashBannerPoint4: "实时报告和消息",
  LetsStartButton: "开始吧",
  // 结束 BZabc学习平台 //
  // BZabc方法 - 益处 //
  BZabcMethod: "BZabc方法",
  practicalSkillDevelopment: "实用技能开发",
  practicalSkillDevelopmentDescription:
    "听力。口语。阅读。写作。\n BZabc帮助学生内化这些基本技能，通过鼓励参与各种活动。",
  consistency: "一致性",
  consistencyDescription:
    "软件、内容和工作簿之间的材料相互连接，为学习过程提供一致性。一个真正融合、连接的学习环境。",
  totalPhysicalResponse: "全身反应法",
  totalPhysicalResponseDescription:
    "在家里或课堂上，令人兴奋的内容鼓励多层次参与。明确的可达成的学习目标非常有价值，保持学生的参与和积极性。",
  dailyPractice: "连续性：每日练习",
  dailyPracticeDescription:
    "BZabc Kids App易于使用，并引导年幼学习者在整个课程中进行所有数字和书面活动。日、周、月。",
  // 结束 BZabc方法 - 益处 //
  // 技术规格 //
  techSpecs: "技术规格",
  forAllPlatforms: "适用于所有平台",
  forAllPlatformsDescription:
    "BZabc Kids App在Android、iOS和Windows 7和10上均可使用。我们的Parents App也适用于所有平台。",
  blendedLearning: "融合学习",
  blendedLearningDescription:
    "软件、内容和工作簿之间的材料相互连接，为学习过程提供连续性和一致性。",
  personalGuidance: "个性指导",
  personalGuidanceDescription:
    "BZabc App利用学生的表现来指导他们的课程。基于云的数据跟踪和处理意味着无论何时何地学习，学生都在正确的页面上。",
  trackingReporting: "跟踪 / 报告",
  trackingReportingDescription:
    "学生的表现在我们的系统中进行跟踪。分数和错误报告数据实时提供给家长。这使得家长可以看到孩子什么时候工作以及他们可能遇到的任何挑战。",
  // 结束 技术规格 //
  // 底部标题//
  bottomCourseInfo: "为年轻学习者设计的拼写、数学和语言",
  ourPlatformInfo: "BZabc学习平台",
  // 结束 底部标题 //
  // 底部链接 //
  store: "商店",
  privacy: "隐私政策",
  terms: "服务条款",
  // 结束 底部链接 //
  //  附加信息 //
  learningPlatform: "学习平台",
  learnMore: "了解更多",
  productsNotFound: "找不到产品...",
  appDownloadCenter: "应用下载中心",
  appDownloadCenterDescription:
    "在使用Kids App之前，学习者必须由学校或其父母/监护人注册并报名参加课程。",
  more: "更多...",
  parentsApp: "BZabc父母",
  kidsApp: "BZabc Kids",
};
