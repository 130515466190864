export default {
    errors: {
        400: 'Bad Request',
        401: 'Unauthorized',
        403: 'You do not have the right permissions',
        404: 'Record is not found',
        405: 'Bad Request. Action is not allowed...',
        422: 'Unprocessable data',
        409: 'Bad Request',
        429: 'Too Many Attempts. Please try later...',
        500: 'Server error. Please try again later',
        canceled: 'Request has been cancelled',
        unknown: 'Unknown error. Please try again later'
    }
}