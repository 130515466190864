import translations from './translations';

export default {
  ...translations,
  // Top Titles//
  giveGiftOfLearning: "The BZabc Learning Platform",
  professionallyDesignedCourses:
    "Spelling, math, and languages\nfor young learners",
  generalInfoGB:
    "GravityBrain Inc. is dedicated to producing Elearning systems with meaningful outcomes, insights, and useful tools for the educational community, utilizing data driven tech to leverage traditional materials and methods.",
  // END Top Titles //
  // App Download Center //
  AppDownload: "App Download Center",
  AppDownloadDescription:
    "Before using the Kids App, Learners must be registered and enrolled \n in courses by either their school or their parent/guardian.",
  // END App Download Center //
  // The BZabc Learning Platform //
  aboutGravityBrainNote: "Paragraph about GravityBrain 1-3 sentences",
  welcomeToGravityBrain: "Elearning for Everyone",
  InteractiveTestingAndTraining: "Testing and Training: Early Learners",
  aboutBZabcNote:
    "BZabc is for young learners, is designed to substantially enhance skills acquisition in core subjects language and math training.  Highly interactive, media rich, real-time reports, and instant messaging are just some of the features.",
  authoringTools: "Publishing and Authoring Tools",
  aboutAuthoringToolsNote:
    "Independent publishers can publish interactive, video based courses on GravityBrain through GravityBrain Pubtool.  Writers, wuthors, visual and recording artists can collaborate  build their own curricula, with revenue-sharing opportunities.",
  multimediaDevelopment: "Multimedia Develoment",
  aboutMMDevelopmentNote:
    "GravityBrain Inc. has partnered with Inno-Care.ca to produced all multimedia on our system.  Inno-Care is a non-profit which offers competitive studio rates on 3D animation, A/V, and sound production, while providing meaning employment to those with special needs.",
  trainingSystemSAAS: "Elearning SAAS",
  aboutTrainingSystemsSAASNote:
    "For government, or larger schools who wish to operate their own service, we offer a robust learning management system with numerous features: personalized, data driven learning. Reporting. Publishing tools, Apps. Messaging. Any many more features.",
  contactInfo:
    "Email: office@gravitybrain.com\nTelephone: 1-306-570-6488\nSkype: gravity_brain\nPO Box 568 Hamiota, MB\nR0M 0T0, CANADA",
    splashBannerPoint1: "Quick registration & enrollment",
  splashBannerPoint2: "Engaging, interactive lessons",
  splashBannerPoint3: "Personalized data-driven learning",
  splashBannerPoint4: "Real-time reporting & messaging",
  LetsStartButton: "Let's Start",
  // END The BZabc Learning Platform //
  // The BZabc Method - Benefits //
  BZabcMethod: "The BZabc Method",
  practicalSkillDevelopment: "Practical Skill Development",
  practicalSkillDevelopmentDescription:
    "Listening. Speaking. Reading. Writing.\n BZabc helps students internalize these essential skills, by\n utilizing encourage in participation in a variety of activities.",
  consistency: "Consistency",
  consistencyDescription:
    "Materials are connected between software, content and workbooks, providing consistency throughout the learning process. A truly blended, connected learning environment.",
  totalPhysicalResponse: "Total Physical Response",
  totalPhysicalResponseDescription:
    "At home or in class, exciting content encourages participation on many levels. Clear attainable learning goals are highly rewarding, keeping students engaged and motivated.",
  dailyPractice: "Continuity: Daily Practice",
  dailyPracticeDescription:
    "The BZabc Kids App is easy to use and guides young learners in all activities, both digital and written, throughout their entire course. Days, weeks, and months.",
  // END The BZabc Method - Benefits //
  // Tech Specs //
  techSpecs: "Tech Specs",
  forAllPlatforms: "For All Platforms",
  forAllPlatformsDescription:
    "The BZabc Kids App is available on both Android, iOS, and Windows 7 & 10.  Our Parents App is also available on all platforms.",
  blendedLearning: "Blended Learning",
  blendedLearningDescription:
    "Materials are connected between software, content and workbooks, providing continuity and consistency throughout the learning process.",
  personalGuidance: "Personal Guidance",
  personalGuidanceDescription:
    "The BZabc App utilizes student performance to guide them throughout their courses. Cloud-based data tracking and processing means wherever and whenever they learn, students are on the right page.",
  trackingReporting: "Tracking / Reporting",
  trackingReportingDescription:
    "Student performance is tracked on our system. Scores and error report data are available in real time to parents. This enables parents to see when kids worked and any challenges they may have enountered.",
  // END Tech Specs //
  // Bottom Titles//
  bottomCourseInfo: "Spelling, math, and languages\n for young learners",
  ourPlatformInfo: "The BZabc Learning Platform",
  // END Bottom Titles //
  // Bottom Links //
  store: "Store",
  privacy: "Privacy Policy",
  terms: "Terms of Service",
  // END Bottom Links //
  //  Additional Info //
  learningPlatform: "Learning Platform",
  learnMore: "Learn more",
  productsNotFound: "Products Not Found...",
  appDownloadCenter: "App Download Center",
  appDownloadCenterDescription:
    "Before using the Kids App, Learners must be registered and enrolled in courses by either their school or their parent/guardian.",
  more: "more...",
  parentsApp: "BZabc Parents",
  kidsApp: "BZabc Kids",
};
