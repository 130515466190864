import translations from './translations';
export default {
  ...translations,
  
    // Títulos Principais //
    giveGiftOfLearning: "A Plataforma de Aprendizagem BZabc",
    professionallyDesignedCourses:
      "Ortografia, matemática e idiomas\npara jovens aprendizes",
    generalInfoGB:
      "A GravityBrain Inc. dedica-se a produzir sistemas de e-learning com resultados significativos, insights e ferramentas úteis para a comunidade educacional, utilizando tecnologia orientada por dados para alavancar materiais e métodos tradicionais.",
    // FIM Títulos Principais //
    // Centro de Download do Aplicativo //
    AppDownload: "Centro de Download do Aplicativo",
    AppDownloadDescription:
      "Antes de usar o aplicativo para crianças, os alunos devem estar registrados e inscritos em cursos pela escola ou pelos pais/tutores.",
    // FIM Centro de Download do Aplicativo //
    // A Plataforma de Aprendizagem BZabc //
    aboutGravityBrainNote: "Parágrafo sobre GravityBrain de 1 a 3 frases",
    welcomeToGravityBrain: "E-learning para Todos",
    InteractiveTestingAndTraining: "Testes e Treinamento: Jovens Aprendizes",
    aboutBZabcNote:
      "O BZabc é para jovens aprendizes, projetado para aprimorar substancialmente a aquisição de habilidades em matérias básicas como linguagem e matemática. Altamente interativo, rico em mídia, relatórios em tempo real e mensagens instantâneas são apenas algumas das características.",
    authoringTools: "Ferramentas de Publicação e Autoria",
    aboutAuthoringToolsNote:
      "Editores independentes podem publicar cursos interativos baseados em vídeo no GravityBrain através do GravityBrain Pubtool. Escritores, autores, artistas visuais e de gravação podem colaborar para criar seus próprios currículos, com oportunidades de compartilhamento de receita.",
    multimediaDevelopment: "Desenvolvimento Multimídia",
    aboutMMDevelopmentNote:
      "A GravityBrain Inc. se associou à Inno-Care.ca para produzir todo o conteúdo multimídia em nosso sistema. A Inno-Care é uma organização sem fins lucrativos que oferece tarifas de estúdio competitivas em animação 3D, A/V e produção sonora, enquanto oferece empregos significativos para pessoas com necessidades especiais.",
    trainingSystemSAAS: "E-learning SAAS",
    aboutTrainingSystemsSAASNote:
      "Para governos ou escolas maiores que desejam operar seu próprio serviço, oferecemos um sistema robusto de gerenciamento de aprendizagem com inúmeras funcionalidades: aprendizado personalizado baseado em dados. Relatórios. Ferramentas de publicação, aplicativos. Mensagens. E muitas outras funcionalidades.",
    contactInfo:
      "Email: office@gravitybrain.com\nTelefone: 1-306-570-6488\nSkype: gravity_brain\nCaixa Postal 568 Hamiota, \nR0M 0T0, CANADÁ",
    splashBannerPoint1: "Registro e inscrição rápidos",
    splashBannerPoint2: "Lições envolventes e interativas",
    splashBannerPoint3: "Aprendizado personalizado baseado em dados",
    splashBannerPoint4: "Relatórios e mensagens em tempo real",
    LetsStartButton: "Vamos Começar",
    // FIM A Plataforma de Aprendizagem BZabc //
    // O Método BZabc - Benefícios //
    BZabcMethod: "O Método BZabc",
    practicalSkillDevelopment: "Desenvolvimento de Habilidades Práticas",
    practicalSkillDevelopmentDescription:
      "Ouvir. Falar. Ler. Escrever.\n O BZabc ajuda os alunos a internalizar essas habilidades essenciais, incentivando a participação em uma variedade de atividades.",
    consistency: "Consistência",
    consistencyDescription:
      "Os materiais estão conectados entre o software, o conteúdo e os livros de trabalho, proporcionando consistência ao longo do processo de aprendizado. Um ambiente de aprendizado verdadeiramente integrado e conectado.",
    totalPhysicalResponse: "Resposta Física Total",
    totalPhysicalResponseDescription:
      "Em casa ou na sala de aula, o conteúdo emocionante incentiva a participação em vários níveis. Objetivos de aprendizado claros e alcançáveis são altamente recompensadores, mantendo os alunos engajados e motivados.",
    dailyPractice: "Continuidade: Prática Diária",
    dailyPracticeDescription:
      "O aplicativo BZabc Kids é fácil de usar e orienta os jovens aprendizes em todas as atividades, digitais e escritas, ao longo de todo o curso. Dias, semanas e meses.",
    // FIM O Método BZabc - Benefícios //
    // Especificações Técnicas //
    techSpecs: "Especificações Técnicas",
    forAllPlatforms: "Para Todas as Plataformas",
    forAllPlatformsDescription:
      "O aplicativo BZabc Kids está disponível no Android, iOS e Windows 7 e 10. Nosso aplicativo para pais também está disponível em todas as plataformas.",
    blendedLearning: "Aprendizado Integrado",
    blendedLearningDescription:
      "Os materiais estão conectados entre o software, o conteúdo e os cahiers de exercícios, proporcionando continuidade e consistência ao longo do processo de aprendizado.",
    personalGuidance: "Orientação Pessoal",
    personalGuidanceDescription:
      "O aplicativo BZabc utiliza o desempenho dos alunos para guiá-los ao longo de seus cursos. O rastreamento e processamento de dados baseados na nuvem significa que, onde e quando eles aprendem, os alunos estão no caminho certo.",
    trackingReporting: "Rastreamento / Relatórios",
    trackingReportingDescription:
      "O desempenho dos alunos é rastreado em nosso sistema. Os dados de pontuação e relatório de erros estão disponíveis em tempo real para os pais. Isso permite que os pais vejam quando as crianças trabalharam e quais desafios podem ter encontrado.",
    // FIM Especificações Técnicas //
    // Títulos Inferiores //
    bottomCourseInfo: "Ortografia, matemática e idiomas\npara jovens aprendizes",
    ourPlatformInfo: "A Plataforma de Aprendizagem BZabc",
    // FIM Títulos Inferiores //
    // Links Inferiores //
    store: "Loja",
    privacy: "Política de Privacidade",
    terms: "Termos de Serviço",
    // FIM Links Inferiores //
    // Informações Adicionais //
    learningPlatform: "Plataforma de Aprendizagem",
    learnMore: "Saiba mais",
    productsNotFound: "Produtos não encontrados...",
    appDownloadCenter: "Centro de Download do Aplicativo",
    appDownloadCenterDescription:
      "Antes de usar o aplicativo para crianças, os alunos devem estar registrados e inscritos em cursos pela escola ou pelos pais/tutores.",
    more: "mais...",
    parentsApp: "BZabc Pais",
    kidsApp: "BZabc Kids",
  
  
// END Additional Info //
};
