import React from "react";
import { Switch, Redirect } from "react-router";

import AppContainer from "../containers/AppContainer";

import Route from "../containers/routing/Route";

import NotFoundPage from "../containers/errors/404";

import SplashContainer from "../containers/static/Splash";
import SchoolTeacherContainer from "../containers/static/SchoolTeachers";
import StudentParentContainer from "../containers/static/StudentParents";
import MultimediaContainer from "../containers/static/Multimedia";

import Content from "../containers/static/Content";

export default () => (
  <AppContainer>
    <Switch>
      <Route
        exact
        path="/privacy-policy"
        title="privacyPolicy"
        render={(props) => <Content {...props} page="privacyPolicyContent" />}
      />
      <Route exact path="/" title="home" component={SplashContainer} />
      <Route
        exact
        path="/school-teachers"
        title="schoolsTeachers"
        component={SchoolTeacherContainer}
      />
      <Route
        exact
        path="/student-parents"
        title="studentParents"
        component={StudentParentContainer}
      />
      <Route
        exact
        path="/multimedia"
        title="multimedia"
        component={MultimediaContainer}
      />
      <Route
        exact
        path="/terms"
        title="termsAndConditions"
        render={(props) => <Content {...props} page="termsAndConditions" />}
      />

      <Route path="/notFound" title="notfound" component={NotFoundPage} />
      <Redirect to="/notFound" />
    </Switch>
  </AppContainer>
);
