import { createSelector } from 'reselect';

/**
 * Select domain
 */
export const selectStoreDomain = (state) => state.store;

/**
 * Get Records Request
 */
export const selectGetRecordsRequest = createSelector(
  selectStoreDomain,
  (subState) => subState.get('getRecordsRequest')
);

/**
 * Get Single Request
 */
export const selectGetSingleRecordRequest = createSelector(
  selectStoreDomain,
  (subState) => subState.get('getSingleRecordRequest')
);

/**
 * Get Single Record
 */
export const selectGetSingleRecord = createSelector(
    selectStoreDomain,
    (subState) => subState.get('singleRecord')
);
/**
 * Records
 */
export const selectRecords = createSelector(
  selectStoreDomain,
  (subState) => subState.get('records')
);

/**
 * Pagiantion
 */
export const selectPagination = createSelector(
  selectStoreDomain,
  (subState) => subState.get('pagination')
);
