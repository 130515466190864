export default {
    home: 'Home',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    kr: '한국어',
    zh_hans: '汉语',
    zh_hant: '漢語',
    pt: 'Portugues',
    freeProduct: 'Free',
    tools: "Tools",
    company: "Company",
    contactUs: "Contact Us",
    about: "About",
    login: "Login",
    signin: 'Sign In',
    signup: "Sign Up",
    sendGift: "Send a Gift",
    subscription: "Subscription",
    platform: "Platform",
    parentsStudents: "Students  & Parents",
    schoolsTeachers: "Schools & Teachers",
    subscriptionStartingFrom: "Starting at <0>$8.99</0>/seat",
    workBooksAndDownloads: "Workbooks &\nDownloads",
    bookstore: "Bookstore",
    ourbookstore: "Our Bookstore",
    overview: "Overview",
    pdf: "PDF",
    publishers: "Publishers",
    copyright: "Copyright © 2020 bzabc",
    splashOverviewButton: "Overview",
    aboutUsNote: "About Us paragraph 1-3 sentences.",
    welcomeToWebsite: "Welcome to BZabc",
    welcomeToGravityBrain: "Welcome to GravityBrain",
    usingOurProduct: "Using BZabc",
    methods: "Methods",
    quickstart: "Quickstart",
    ourMethod: "The BZabc Method",
    platformOverview: "Platform Overview",
    publishersTab: "Publishers",
    store: "Store",
    privacy: "Privacy Policy",
    terms: "Terms of Service",
    learningPlatform: "Learning Platform",
    learnMore: "Learn more",
    productsNotFound: "Products Not Found...",
    appDownloadCenter: "App Download Center",
    appDownloadCenterDescription: "Before using the Kids App, Learners must be registered and enrolled in courses by either their school or their parent/guardian.",
    more: "more...",
    parentsApp: "BZabc Parents",
    kidsApp: "BZabc Kids",
    schoolWebTool: "School Web Tool",
    gettingStarted: "Getting Started",
    termsAndConditions: {
        title: "Terms and Conditions",
        paragraphs: [
            {
                title: "Welcome to GravityBrainInc.",
                content: `These terms and conditions outline the rules and regulations for the use of GravityBrainInc.'s
                          Website. GravityBrainInc. is located at:
                          PO Box 568 Hamiota,
                          Manitoba R0M0T0,
                          CANADA.
                          By accessing this website we assume you accept these terms and conditions in full. Do not continue to use GravityBrainInc.'s website if you do not accept all of the terms and conditions stated on this page.
                          The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: "Client", "You" and "Your" refers to you, the person accessing this website and accepting the Company's terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or any other means,for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services/products, in accordance with and subject to, prevailing law of Canada. Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.`
            },
            {
                title: "Cookies",
                content: `We employ the use of cookies. By using GravityBrainInc.'s website you consent to the use of cookies in accordance with GravityBrainInc.'s privacy policy.Most of the modern day interactive websites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting. Some of our affiliate partners may also use cookies.`
            },
            {
                title: "License",
                content: `Unless otherwise stated, GravityBrainInc. and/or it's licensors own the intellectual property rights for all material on GravityBrainInc.. All intellectual property rights are reserved. You may view and/or printpages from https://gravitybrain.com/ for your own personal use subject to restrictions set in these terms and conditions.
                          You must not:
                          - Republish material from https://www.gravitybrain.com
                          - Sell, rent or sub-license material from https://www.gravitybrain.com
                          - Reproduce, duplicate or copy material from https://www.gravitybrain.com
                          Redistribute content from GravityBrainInc. (unless content is specifically made for redistribution).`
            },
            {
                title: "Hyperlinking to our Content",
                content: `The following organizations may link to our Web site without prior written approval:
                        - Government agencies;
                        - Search engines;
                        - News organizations;
                       Online directory distributors when they list us in the directory may link to our Web site in the same manner as they hyperlink to the Web sites of other listed businesses; and System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Website.
                       These organizations may link to our home page, to publications or to other Web site information so long as the link: (a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party&#39;s site.
                       We may consider and approve in our sole discretion other link requests from the following types of organizations:commonly-known consumer and/or business information sources such as Chambers of Commerce, American Automobile Association, AARP and Consumers Union; dot.com community sites; associations or other groups representing charities, including charity giving sites, online directory distributors; internet portals; accounting, law and consulting firms whose primary clients are businesses; and educational institutions and trade associations.
                       We will approve link requests from these organizations if we determine that: (a) the link would not reflect unfavorably on us or our accredited businesses (for example, trade associations or other organizations representing inherently suspect types of business, such as work-at-home opportunities, shall not be allowed to link); (b)the organization does not have an unsatisfactory record with us; (c) the benefit to us from the visibility associated with the hyperlink outweighs the absence of ; and (d) where the link is in the context of general resource information or is otherwise consistent with editorial content in a newsletter or similar product furthering the mission of the organization. These organizations may link to our home page, to publications or to other Web site information so long as the link:(a) is not in any way misleading; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and it products or services; and (c) fits within the context of the linking party's site.
                       If you are among the organizations listed in paragraph 2 above and are interested in linking to our website, you must notify us by sending an e-mail to admin@gravitybrain.com. Please include your name, your organization name, contact information (such as a phone number and/or e-mail address) as well as the URL of your site, a list of any URLs from which you intend to link to our Web site, and a list of the URL(s) on our site to which you would like to link. Allow 2-3 weeks for a response.`
            },
            {
                title:
                        "Approved organizations may hyperlink to our Web site as follows:",
                content: ` - By use of our corporate name; or
                        - By use of the uniform resource locator (Web address) being linked to; or
                        - By use of any other description of our Web site or material being linked to that makes sense within the context and format of content on the linking party's site.
                       No use of GravityBrainInc.'s logo or other artwork will be allowed for linking absent a trademark license agreement.`
            },
            {
                title: "Iframes",
                content: `Without prior approval and express written permission, you may not create frames around our Web pages or use other techniques that alter in any way the visual presentation or appearance of our Web site.`
            },
            {
                title: "Reservation of Rights",
                content: `We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our Web site. You agree to immediately remove all links to our Web site upon such request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuing to link to our Web site, you agree to be bound to and abide by these linking terms and conditions.`
            },
            {
                title: "Removal of links from our website",
                content: `If you find any link on our Web site or any linked web site objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.
                        Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.`
            },
            {
                title: "Content Liability",
                content: `We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any page on your Web site or within any context containing content or materials that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.`
            }
        ]
    },
    privacyPolicyContent: {
        title: "GravityBrainPrivacy Policy",
        subTitle: "Privacy Policy",
        paragraphs: [
            {
                title: "What information do we collect?",
                content: `We collect information from you when you place an order or register on our software.
                          When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail address or other personal information. For your security, we do not process, collect, or store credit card or financial information directly on our site, but rather utilize the services of third-party providers such as Paypal or Alipay.  You may also choose to visit our site anonymously.`
            },
            {
                title: "What do we use your information for?",
                content: `Any of the information we collect from you may be used in one of the following ways:
                          To personalize your experience (your information helps us to better respond to your individual needs)
                          To improve customer service (your information helps us to more effectively respond to your customer service requests and support needs)
                          To process transactions. (your information, whether public or private, will not be sold, exchanged, transferred, or given to any other company for any reason whatsoever, without your consent, other than for the express purpose of delivering the purchased product or service requested)
                          To send periodic emails. The email address you provide for order processing, may be used to send you information and updates pertaining to your order, in addition to receiving occasional company news, updates, related product or service information, etc.`
            },
            {
                title: "How do we protect your information?",
                content: `We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.
                          We offer the use of a secure server. All supplied sensitive/credit information is transmitted via Secure Socket Layer (SSL) technology and then encrypted into our Payment gateway providers database only to be accessible by those authorized with special access rights to such systems, and are required to keep the information confidential.
                          Transactions, collection and storage of your private financial information (credit cards, social security numbers, financials, etc.) will not be processed or stored on our servers. Such transactions are done directly through third-party providers for your protection and safety.`
            },
            {
                title: "Do we use cookies?",
                content: `Cookies on our site are used for tracking and review automation for students.  We also use this information to process reports which are available only to those authroized by parents or schools.  Furthermore, we will also use the info to track which subscriptions are active and those which are not.`
            },
            {
                title: "Do we disclose any information to outside parties?",
                content: `We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.`
            },
            {
                title: "California Online Privacy Protection Act Compliance",
                content: `Because we value your privacy we have taken the necessary precautions to be in compliance with the California Online Privacy Protection Act. We therefore will not distribute your personal information to outside parties without your consent.`
            },
            {
                title: "Childrens Online Privacy Protection Act Compliance",
                content: `We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act), we do not collect any information from anyone under 13 years of age. Our website, products and services are all directed to people who are at least 13 years old or older. Parents may choose to enter a sudonym for their child or to use their actual name.`
            },
            {
                title: "Private Information on our training system",
                content: `You have the option to enter personal information such as full name and birth date on our software. The purpose of this information is for your benefit only, and you are not required to do so. The birthdate information is used only to suggest courses on our system which may be of interest to you.`
            },
            {
                title: "Terms and Conditions",
                content: `Please also visit our Terms and Conditions section establishing the use, disclaimers, and limitations of liability governing the use of our website at`
            },
            {
                title: "Your Consent",
                content: `By using our site, you consent to this privacy policy.`
            },
            {
                title: "Changes to our Privacy Policy",
                content: `If we decide to change our privacy policy, we will post those changes on this page.`
            },
            {
                title: "Contacting Us",
                content: `If there are any questions regarding this privacy policy you may contact us using the information below.
                          Box 568
                          Hamiota, Manitoba R0M 0T0
                          CANADA`
            }
        ]
    }
}
