export default {
    mainTitle: 'BZabc',
    notFound: '404',
    about: 'À propos de nous',
    parentsStudents: 'Parents et étudiants',
    schoolsTeachers: 'Écoles et enseignants',
    termsAndConditions: 'Conditions générales',
    methodology: 'Méthodologie',
    techSpecs: 'Spécifications techniques',
    privacyPolicy: 'Politique de confidentialité',
    parents: 'Parents',
    home: 'Home',
}