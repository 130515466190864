
import translations from './translations';

export default {
  ...translations,
// Top Titles//
  welcomeToGravityBrain: "Elearning for Everyone",
  giveGiftOfLearning: "The BZabc Learning Platform",
  professionallyDesignedCourses:
    "Spelling, math, and languages\nfor your little ones",
// END Top Titles //
// App Download Center //
  AppDownload: "App Download Center",
  AppDownloadDescription: "Before using the Kids App, Learners must be registered and enrolled \n in courses by either their school or their parent/guardian.",
// END App Download Center //
// The BZabc Learning Platform //
  welcomeToWebsite: "BZabc Private Study or School Link",
  aboutBZabcNote:
    "Parents can register and enroll kids in courses for home study, or the schools may do so and then the parent can link to the child's account. The link to your child's account, the best way is to login to BZabc.tv online with your child's credentials and add yourself to their account. This will give you access to their reports as well as messaging from the school.",
  splashBannerPoint1: "Use for private study or school link",
  splashBannerPoint2: "Subscription allows parents to enrol students in courses",
  splashBannerPoint3: "Real time report for private or school courses",
  splashBannerPoint4: "Real-time reporting & messaging",
  LetsStartButton: "Let's Start",
// END The BZabc Learning Platform //
// BZabc Kids App //
  BZabcMethod: "The BZabc Kids App",
  practicalSkillDevelopment:
    "iOS. Android. Anywhere. Anytime.",
  practicalSkillDevelopmentDescription:
    "Once enrolled in courses by parents or school, the BZabc Kids App can be installed on any mobile device without restriction and used anywhere with an internet connection. ",
  consistency: "Eye Candy",
  consistencyDescription:
    "BZabc offers animated tutorial movies followed by a brief quiz for each lesson. Engaged kids have the best change of learning!",
  totalPhysicalResponse: "Dancing Shoes Optional",
  totalPhysicalResponseDescription:
    "Action songs are included with every subscription, as have adopted the Total Physical Response method by Professor James Asher ",
  dailyPractice: "Continuity: Daily Practice",
  dailyPracticeDescription:
    "Spend a few minutes a day on each of your BZabc courses. Consistency is vital in your learning path. Once set up, kids can easily work alone with our automatic guidance and one-click login.",
// END BZabc Kids App //
// Tech Specs //
  techSpecs: "Tech Specs",
  forAllPlatforms: "For All Platforms",
  forAllPlatformsDescription:
    "The BZabc Kids App is available on both Android, iOS, and Windows 7 & 10.  Our Parents App is also available on all platforms.",
  blendedLearning: "Blended Learning",
  blendedLearningDescription:
    "Materials are connected between software, content and workbooks, providing continuity and consistency throughout the learning process.",
  personalGuidance: "Personal Guidance",
  personalGuidanceDescription:
    "The BZabc App utilizes student performance to guide them throughout their courses. Cloud-based data tracking and processing means wherever and whenever they learn, students are on the right page.",
  trackingReporting: "Tracking / Reporting",
  trackingReportingDescription:
    "Student performance is tracked on our system. Scores and error report data are available in real time to parents. This enables parents to see when kids worked and any challenges they may have enountered.",
// END Tech Specs //
// Bottom Titles//
  bottomCourseInfo:
    "Spelling, math, and languages for your little ones",
  ourPlatformInfo: "The BZabc Learning Platform",
// END Bottom Titles //
// Bottom Links //
  store: "Store",
  privacy: "Privacy Policy",
  terms: "Terms of Service",
 // END Bottom Links //
//Additional Info //
  learningPlatform: "Learning Platform",
  learnMore: "Learn more",
  productsNotFound: "Products Not Found...",
  appDownloadCenter: "App Download Center",
  appDownloadCenterDescription: "Before using the Kids App, Learners must be registered and enrolled in courses by either their school or their parent/guardian.",
  more: "more...",
  parentsApp: "BZabc Parents",
  kidsApp: "BZabc Kids"
// END Additional Info //
};
