export default {
// Header and main links //
  about: "Acerca de",
  login: "Iniciar sesión",
  signin: "Iniciar sesión",
  signup: "Registrarse",
  sendGift: "Enviar un regalo",
  subscription: "Suscripción",
  publishers: "Editoriales",
  platform: "Plataforma",
  parentsStudents: "Estudiantes y Padres",
  schoolsTeachers: "Escuelas y Profesores",
  subscriptionStartingFrom: "Desde <0>$8.99</0>/mes",
  workBooksAndDownloads: "Libros de texto\ny Descargas",
  bookstore: "Librería",
  ourbookstore: "Nuestra librería",
  overview: "Resumen",
  pdf: "PDF",
// End - Header and main links //
// Top Titles//
  giveGiftOfLearning: "La Plataforma de Aprendizaje BZabc",
  professionallyDesignedCourses:
    "Mejora tus libros de texto\ncon aprendizaje interactivo\ny basado en datos",
// END Top Titles //
// App Download Center //
  AppDownload: "Pubtool: Herramienta de publicación para editoriales y creadores",
  AppDownloadDescription: "Te ayudaremos a comenzar. La Herramienta de Publicación es tu camino hacia la creación e impartición de cursos en línea para estudiantes jóvenes.",
// END App Download Center //
// The BZabc Learning Platform //
  welcomeToWebsite: "Pubtool",
  aboutUsNote:
    "Pubtool, la Herramienta de publicación fue creada por GravityBrain, Inc. para permitir a las editoriales y creadores tradicionales publicar su contenido en un sistema interactivo. No se requiere programación.",
  splashBannerPoint1: "Rápido desarrollo de cursos",
  splashBannerPoint2: "Cargador de archivos multimedia rápido y eficiente",
  splashBannerPoint3: "Personaliza o duplica elementos del curso",
  splashBannerPoint4: "Reportes y mensajería en tiempo real",
  LetsStartButton: "Let's Start",
// END The BZabc Learning Platform //
// The BZabc Method - Benefits //
  BZabcMethod: "Vuélvete interactivo",
  practicalSkillDevelopment:
    "Conserva tus libros de texto",
  practicalSkillDevelopmentDescription:
    "Pubtool es una herramienta extremadamente rápida y eficiente para convertir tus libros de texto tradicionales en cursos interactivos.",
  consistency: "Enlace a las lecciones",
  consistencyDescription:
    "Utilizando códigos QR, los estudiantes fácilmente pueden buscar lecciones del libro de texto para acceso instantáneo en la App.",
  totalPhysicalResponse: "Constructor de cursos modulares",
  totalPhysicalResponseDescription:
    "Los artistas digitales y desarrolladores de planes de estudios trabajan de manera independiente, cargando su contenido dondequiera que estén en el mundo.",
  dailyPractice: "Personalizar cursos",
  dailyPracticeDescription:
    "Permite a las escuelas o regiones elegir sus lecciones y el orden de la presentación, para después aplicarlas de manera privada.",
// END The BZabc Method - Benefits //
// Tech Specs //
  techSpecs: "Especificaciones técnicas",
  forAllPlatforms: "Para todas las plataformas",
  forAllPlatformsDescription:
    "La App BZabc Kids está disponible en Android, iOS y Windows 7 y 10. Nuestra App para Padres, BZabc Parents, también está disponible en todas las plataformas.",
  blendedLearning: "Aprendizaje integral",
  blendedLearningDescription:
    "Los materiales están conectados entre software, contenido y libros de texto, ofreciendo continuidad y consistencia en todo el proceso educativo.",
  personalGuidance: "Orientación personal",
  personalGuidanceDescription:
    "La App BZabc utiliza el desempeño de los estudiantes para guiarlos a través de sus cursos. El monitoreo y procesamiento de datos en la nube garantiza que donde sea y dondequiera que aprendan, los estudiantes estarán en la página correcta.",
  trackingReporting: "Monitoreo / Reporte",
  trackingReportingDescription:
    "El desempeño estudiantil es monitoreado en nuestro sistema. Los datos sobre puntajes y errores están disponibles en tiempo real para los padres. Esto les permite saber cuándo trabajaron sus hijos y los retos con los que se enfrentaron.",
// END Tech Specs //
// Bottom Titles//
  bottomCourseInfo:
    "Mejora tus libros de texto con aprendizaje interactivo y basado en datos.",
  ourPlatformInfo: "La Plataforma de Aprendizaje BZabc",
// END Bottom Titles //
// Bottom Links //
  store: "Tienda",
  privacy: "Política de Privacidad",
  terms: "Términos del Servicio",
 // END Bottom Links //
//Additional Info //
  learningPlatform: "Plataforma de Aprendizaje",
  learnMore: "Conoce más",
  productsNotFound: "Productos no encontrados...",
  appDownloadCenter: "Centro de descargas",
  appDownloadCenterDescription: "Antes de usar la App BZabc Kids, los estudiantes deberán haber sido registrados e inscritos en cursos por sus padres o su escuela.",
  more: "más...",
  parentsApp: "BZabc Parents",
  kidsApp: "BZabc Kids",
// END Additional Info //
};
