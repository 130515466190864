import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import SplashMethodItem from "./sections/SplashMethodItem";
import SplashTechSpecItem from "./sections/SplashTechSpecItem";
import SplashSlider from "./sections/SplashSlider";
import CldImage from "../../components/ui/CldImage";
import PublishersWrapper from "./sections/PublishersWrapper";

class PublisherContainer extends PureComponent {
  _renderPlatFormExp() {
    const { t } = this.props;
    const exps = [
      t("splashBannerPoint1"),
      t("splashBannerPoint2"),
      t("splashBannerPoint3"),
      t("splashBannerPoint4"),
    ];
    return exps.map((exp, key) => (
      <p index={key} key={key} className="platform-exp-label avenir-normal">
        <i className="fa fa-check-circle mr-2"></i>
        {exp}
      </p>
    ));
  }

  _renderBzabcMethod() {
    const { t } = this.props;
    const methods = [
      {
        url: "Benefits_Teachers_01.jpg",
        title: t("practicalSkillDevelopment"),
        content: t("practicalSkillDevelopmentDescription"),
      },
      {
        url: "Benefits_Teachers_02.jpg",
        title: t("consistency"),
        content: t("consistencyDescription"),
      },
      {
        url: "Benefits_Teachers_03.jpg",
        title: t("totalPhysicalResponse"),
        content: t("totalPhysicalResponseDescription"),
      },
      {
        url: "Benefits_Teachers_04.jpg",
        title: t("dailyPractice"),
        content: t("dailyPracticeDescription"),
      },
    ];
    return methods.map((method, key) => (
      <SplashMethodItem
        index={key}
        key={key}
        url={method.url}
        title={method.title}
        content={method.content}
      ></SplashMethodItem>
    ));
  }

  _renderBzabcTechSpec() {
    const { t } = this.props;
    const specs = [
      {
        url: "techspecs_for_all_platforms.png",
        title: t("forAllPlatforms"),
        content: t("forAllPlatformsDescription"),
      },
      {
        url: "techspecs_blended_learning.png",
        title: t("blendedLearning"),
        content: t("blendedLearningDescription"),
      },
      {
        url: "techspecs_personal_guidance.png",
        title: t("personalGuidance"),
        content: t("personalGuidanceDescription"),
      },
      {
        url: "techspecs_tracking_reporting.png",
        title: t("trackingReporting"),
        content: t("trackingReportingDescription"),
      },
    ];
    return specs.map((spec, key) => (
      <SplashTechSpecItem
        index={key}
        key={key}
        url={spec.url}
        title={spec.title}
        content={spec.content}
      ></SplashTechSpecItem>
    ));
  }

  render() {
    const { t } = this.props;
    return (
      <PublishersWrapper showJumbotron type="publishers">
        <div className="bzabc-application">
          <div className="app-download container">
            <div className="row">
              <div className="col-lg-7 col-md-8 col-sm-12 py-sm-4 px-sm-5">
                <h2>{t("AppDownload")}</h2>
                <p className="description avenir-normal mx-2">
                  {t("AppDownloadDescription")}
                </p>
              </div>
              <div className="col-lg-5 col-md-4 col-sm-12 app-publisher-icons text-center">
                <a
                  href="//pubtool.gravitybrain.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CldImage src="publisher_icon_1024.png " />
                </a>
              </div>
            </div>
          </div>
          <div className="learning-platform">
            <h1 className="mt-4 text-center">{t("welcomeToWebsite")}</h1>
            <div className="publishers-pl-bg learning-platform-background mt-4">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-10 mx-auto about-us-note avenir-normal">
                    {t("aboutUsNote")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 platform-video-area">
                    <CldImage
                      className="platform-video"
                      src="publishers_vidstill.png"
                    />
                    <CldImage
                      className="play-btn"
                      src="publishers_play_button.png"
                    />
                  </div>
                  <div className="col-md-6 platform-start pt-4">
                    {this._renderPlatFormExp()}
                    <NavLink to="/signup">
                      <button className="start-btn mt-2">
                        {t("LetsStartButton")}
                      </button>{" "}
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bzabc-tech-container container">
            <h1 className="gotham-bold text-center">{t("BZabcMethod")}</h1>
            <div className="row bzabc-method-list">
              {this._renderBzabcMethod()}
            </div>
            <h1 className="techspec-title text-center">{t("techSpecs")}</h1>
            <div className="row bzabc-spec-list">
              {this._renderBzabcTechSpec()}
            </div>
          </div>
          <div className="training-section py-5 text-center">
            <h2 className="gotham-bold training-title">
              {t("professionallyDesignedCourses")}
            </h2>
            <p className="mt-4 mb-3 training-description">
              {t("giveGiftOfLearning")}
            </p>
            <a href="//d2cnhr6egzpvdl.cloudfront.net/PDFs/BZabc_Learning_Platform_Overview.pdf">
              <button className="start-btn mx-auto">
                {t("LetsStartButton")}
              </button>
            </a>
          </div>
          <div className="bookstore-slider">
            <SplashSlider {...this.props} />
          </div>
        </div>
      </PublishersWrapper>
    );
  }
}

export default withTranslation("publishers")(PublisherContainer);
