export default {
// Header and main links //
  about: "À propos",
  login: "Connexion",  
  signup: "S'inscrire",
  sendGift: "Envoyer un cadeau",
  subscription: "Abonnement",
  publishers: "Éditeurs",
  platform: "Plateforme",
  parentsStudents: "Élèves et parents", 
  schoolsTeachers: "Écoles et professeurs",
  subscriptionStartingFrom: "Utilisation gratuite",
  workBooksAndDownloads: "Cahier d'exercices et\nTéléchargements",
  bookstore: "Librairie",
  ourbookstore: "Notre librairie",
  overview: "Vue d'ensemble",
  pdf: "PDF",
// End - Header and main links //
// Top Titles//
  giveGiftOfLearning: "La plateforme éducative BZabc",
  professionallyDesignedCourses:
    "Améliorez vos cahiers d'exercices\ngrâce à un apprentissage interactif\norienté sur les données",
// END Top Titles //
// App Download Center //
  AppDownload: "Pubtool pour les éditeurs",
  AppDownloadDescription: "Nous vous aiderons à démarrer. Pubtool est la solution pour vous permettre de créer et d'offrir des leçons en ligne à des élèves",
// END App Download Center //
// The BZabc Learning Platform //
  welcomeToWebsite: "Pubtool",
  aboutUsNote:
    "Pubtool est créé par GravityBrain Inc. pour permettre aux éditeurs traditionnels de mettre leur contenu dans un système interactif. Aucune programmation n'est nécessaire.",
  splashBannerPoint1: "Développement rapide des cours",
  splashBannerPoint2: "Mise en ligne de médias rapide et efficace",
  splashBannerPoint3: "Personnaliser ou dupliquer les éléments des leçons",
  splashBannerPoint4: "Résultats et messagerie en temps réel",
  LetsStartButton: "Commencer",
// END The BZabc Learning Platform //
// The BZabc Method - Benefits //
  BZabcMethod: "Passer en mode interactif",
  practicalSkillDevelopment:
    "Gardez vos cahiers d'exercices",
  practicalSkillDevelopmentDescription:
    "Pubtool est un moyen extrêmement rapide et efficace de transformer vos cahiers d'exercices traditionnels en leçons interactives.",
  consistency: "Lien vers les leçons",
  consistencyDescription:
    "En utilisant le code QR, les élèves peuvent facilement consulter les leçons du cahier d'exercices pour un accès instantané à l'application.",
  totalPhysicalResponse: "Créateur de modules de cours",
  totalPhysicalResponseDescription:
    "Les artistes numériques et les auteurs de programmes scolaires travaillent de manière indépendante, en mettant en ligne leur contenu où qu'ils se trouvent dans le monde.",
  dailyPractice: "Personnaliser les leçons",
  dailyPracticeDescription:
    "Permet aux écoles ou aux régions de choisir leurs leçons et l'ordre de présentation. Livrer ensuite en privé.",
// END The BZabc Method - Benefits //
// Tech Specs //
  techSpecs: "Caractéristiques techniques",
  forAllPlatforms: "Pour toutes les plateformes",
  forAllPlatformsDescription:
    "L'application BZabc Kids est disponible sur Android, iOS et Windows 7 et 10. Notre application pour les parents est également disponible sur toutes les plateformes.",
  blendedLearning: "Apprentissage mixte",
  blendedLearningDescription:
    "Les contenus sont reliés entre les logiciels et les cahiers d'exercices, afin d’assurer une continuité et une cohérence tout au long du processus d'apprentissage.",
  personalGuidance: "Accompagnement personnel",
  personalGuidanceDescription:
    "L'application BZabc utilise les performances des élèves pour les guider tout au long de leurs cours. Le suivi et le traitement des données dans le nuage signifie que quels que soient le lieu et le moment où ils apprennent, les élèves sont sur la bonne page.",
  trackingReporting: "Suivi / Rapports",
  trackingReportingDescription:
    "Les performances des élèves sont suivies sur notre système. Les résultats et les données des rapports d'erreurs sont disponibles en temps réel pour les parents. Cela leur permet de voir quand leurs enfants ont travaillé et de connaître les difficultés qu'ils ont pu rencontrer.",
// END Tech Specs //
// Bottom Titles//
  bottomCourseInfo:
    "Améliorez vos cahiers d'exercices grâce à un apprentissage interactif orienté sur les données",
  ourPlatformInfo: "La plateforme éducative BZabc",
// END Bottom Titles //
// Bottom Links //  
  store: "Boutique",
  privacy: "Politique de confidentialité",
  terms: "Conditions d'utilisation",
 // END Bottom Links //
//  Additional Info //
  learningPlatform: "Plateforme éducative",
  learnMore: "En savoir plus",
  productsNotFound: "Produits introuvables...",
  appDownloadCenter: "Centre de téléchargement des applications",
  appDownloadCenterDescription: "Avant d'utiliser l’application pour les enfants, les élèves doivent être inscrits aux cours soit par leur école, soit par leurs parents/tuteurs.",
  more: "plus...",
  parentsApp: "BZabc Parents",
  kidsApp: "BZabc Kids (enfants)",
// END Additional Info //
};
