export default {
// Header and main links //
  about: "Sobre",
  login: "Login",
  signin: 'Entrar',
  signup: "Criar uma Conta",
  sendGift: "Mande um Presente",
  subscription: "Inscrição",
  publishers: "Editores",
  platform: "Plataforma",
  parentsStudents: "Estudantes  & Pais", 
  schoolsTeachers: "Escolas & Professores",
  subscriptionStartingFrom: "Grátis para uso.",
  workBooksAndDownloads: "Livros de Exercícios &\nDownloads",
  bookstore: "Livraria",
  ourbookstore: "Nossa Livraria",
  overview: "Visão Geral",
  pdf: "PDF",
// End - Header and main links //
// Top Titles//
  giveGiftOfLearning: "A Plataforma de Aprendizado BZabc",
  professionallyDesignedCourses:
    "Melhore seus livros de exercícios\ncom aprendizado interativo e baseado em dados",
// END Top Titles //
// App Download Center //
  AppDownload: "Pubtool para Editores",
  AppDownloadDescription: "Nós te ajudaremos a começar.  Pubtool é o seu caminho para criar e entregar cursos online para jovens aprendizes",
// END App Download Center //
// The BZabc Learning Platform //
  welcomeToWebsite: "Pubtool",
  aboutUsNote:
    "Pubtool é criada por GravityBrain Inc. para permitir aos editores tradicionais que coloquem seus conteúdos em um sistema interativo. Nenhuma programação é necessária.",
  splashBannerPoint1: "Desenvolvimento rápido do curso",
  splashBannerPoint2: "Envio de mídia rápido e eficiente",
  splashBannerPoint3: "Customize ou duplique elementos do curso",
  splashBannerPoint4: "Relatórios e mensagens em tempo real",
  LetsStartButton: "Vamos Começar",
// END The BZabc Learning Platform //
// The BZabc Method - Benefits //
  BZabcMethod: "Fique Interativo",
  practicalSkillDevelopment:
    "Mantenha seus Livros de Exercícios",
  practicalSkillDevelopmentDescription:
    "Pubtool é um modo extremamente fácil e eficaz de converter seus livros de exercício tradicioanis em cursos interativos.",
  consistency: "Linking de Aula",
  consistencyDescription:
    "utilizando código QR, os estudantes podem facilmente olhar lições do livro e exercícios para um acesso instantâneo no aplicativo.",
  totalPhysicalResponse: "Construtor de Curso Modular",
  totalPhysicalResponseDescription:
    "Artistas digitais e escritores de grades trabalham independentemente, enviando seus conteúdos de onde quer que estejam no mundo.",
  dailyPractice: "Customize Cursos",
  dailyPracticeDescription:
    "Permite que escolas ou regiões escolham a ordem de aulas e apresentações. Então entregam privadamente.",
// END The BZabc Method - Benefits //
// Tech Specs //
  techSpecs: "Especificações Técnicas",
  forAllPlatforms: "Para Todas As Plataformas",
  forAllPlatformsDescription:
    "O BZabc Kids App está disponível para Android, iOS, e Windows 7 & 10.  Nosso Parents App também está disponível em todas as plataformas.",
  blendedLearning: "Aprendizado Mesclado",
  blendedLearningDescription:
    "Materiais são conectados entre software, conteúdo e livros de exercícios, fornecendo uma continuidade e consistência por todo o processo de aprendizagem.",
  personalGuidance: "Guia Pessoal",
  personalGuidanceDescription:
    "O BZabc App utiliza a performance dos estudantes para guiá-los por seus cursos. Acompanhamento e processamento de dados baseados na nuvem significa que onde e quando eles aprendam, os estudantes estarão sempre na página certa.",
  trackingReporting: "Acompanhamento / Relatórios",
  trackingReportingDescription:
    "A performance dos estudantes é rastreada no nosso sistema. Pontuações e dados de relatórios de erros estão disponíveis em tempo real aos pais. Isto permite aos pais verem quando as crianças trabalharam e quais desafios eles podem ter encontrado.",
// END Tech Specs //
// Bottom Titles//
  bottomCourseInfo:
    "Melhore seus livros de exercícios com um aprendizado interativo e baseado em dados",
  ourPlatformInfo: "A Plataforma de Aprendizado BZabc",
// END Bottom Titles //
// Bottom Links //  
  store: "Loja",
  privacy: "Política de Privacidade",
  terms: "Termos de Serviço",
 // END Bottom Links //
//  Additional Info //
  learningPlatform: "Plataforma de Aprendizado",
  learnMore: "Saiba mais",
  productsNotFound: "Produtos Não Encontrados...",
  appDownloadCenter: "Centro de Download do App",
  appDownloadCenterDescription: "Antes de usar o Kids App, Aprendizes devem estar inscritos e matriculados em cursos ou por suas escolas ou por seus pais/responsável.",
  more: "mais...",
  parentsApp: "BZabc Parents",
  kidsApp: "BZabc Kids",
// END Additional Info //
};
