export default {
    errors: {
        400: 'Mauvaise requête',
        401: 'Non autorisé',
        403: 'Vous n\'avez pas les permissions nécessaires',
        404: 'L\'enregistrement n\'est pas trouvé',
        405: 'Mauvaise requête. L\'action n\'est pas autorisée …',
        422: 'Données non traitables',
        409: 'Mauvaise requête',
        429: 'Trop de tentatives. Veuillez essayer plus tard…',
        500: 'Erreur de serveur. Veuillez réessayer plus tard',
        canceled: 'La demande a été annulée',
        unknown: 'Erreur inconnue. Veuillez réessayer plus tard'
    }
}