export default {
  // Header and main links //
  about: "대하여",
  login: "로그인",
  signin: "로그인",
  signup: "가입하기",
  sendGift: "선물하기",
  subscription: "구독",
  publishers: "출판사",
  platform: "플랫폼",
  parentsStudents: "학생과 학부모",
  schoolsTeachers: "학교와 선생님",
  subscriptionStartingFrom: "<0>$8.99</0>/매월",
  workBooksAndDownloads: "Workbooks &\nDownloads",
  bookstore: "서점",
  ourbookstore: "저희 서점",
  overview: "개요",
  pdf: "PDF",
  // End - Header and main links //
  // Top Titles//
  welcomeToGravityBrain: "Elearning for Everyone",  
  giveGiftOfLearning: "학습 플랫폼",
  professionallyDesignedCourses:
    "철자법, 산수 및 언어/ 아이들에게 가르치세요",
  // END Top Titles //
  // App Download Center //
  AppDownload: "앱 다운로드 센터",
  AppDownloadDescription:
    "학생 앱을 사용하기 전에 학생을 학교나 학부오가 \n 코스를 등록해야 합니다",
  // END App Download Center //
  // The BZabc Learning Platform //
  welcomeToWebsite: "BZabc 개별 학습 또는 학교 링크",
  aboutUsNote:
    "부모님이 직접 학생을 등록 하실 수 있습니다. 학생이 학교를 통해 등록 했으면 부모님이 학생의 계정과 연결할 수 있습니다. 학생의 자격 증명서를 사용하여 BZabc.tv에서 로그인 한 다음 학부모 계정을 만드는 거를 추천합니다. 여기서 학교 보고서와 메시지를 열람하실 수 있습니다.",
  splashBannerPoint1: "개별 학습 또는 학교 링크를 사용하기",
  splashBannerPoint2:
    "구독하면 학부모께서 학생을 코스에 등록 할 수 있습니다",
  splashBannerPoint3: "학생에 대한 보고서를 실시간으로 볼 수 있습니다",
  splashBannerPoint4: "학생 성적과 학생에게 메시지를 보낼 수 있습니다",
  LetsStartButton: "시작하기",
  // END The BZabc Learning Platform //
  // BZabc Kids App //
  BZabcMethod: "BZabc 학생 앱",
  practicalSkillDevelopment: "iOS. Android. 어디서 언제든지.",
  practicalSkillDevelopmentDescription:
    "학교에서 또는 학부모께서 등록하시면 다양한 디바이스에 BZabc를 설치하실 수 있습니다. 인터넷만 있으면 어디서나 공부하실 수 있습니다.",
  consistency: "재미있게 볼 수 있습니다",
  consistencyDescription:
    "BZabc는 애니메이션으로 튜토리얼 영상을 보면서 매 단원를 공부를 하면서 퀴즈를 풀어 볼 수 있습니다. 학생들은 최고의 학습을 경험할 수 있습니다.",
  totalPhysicalResponse: "춤을 추면서 배우기",
  totalPhysicalResponseDescription:
    "따라부를 수 있는 노래는 구독에 포함되어 있으며, James Asher 교수님의 몸으로 학습법을 채험할 수 있습니다.",
  dailyPractice: "매일 연습하기",
  dailyPracticeDescription:
    "BZabc 코스를 매일 공부하세요. 꾸준히 공부하는 것이 매우 중요합니다. 설정을 저장하시면 원 클릭으로 로그인해서 아이들이 혼자서 쉽게 공부를 할 수 있습니다.",
  // END BZabc Kids App //
  // Tech Specs //
  techSpecs: "기술 정보",
  forAllPlatforms: "모든 플랫폼",
  forAllPlatformsDescription:
    "BZabc 학생 앱은 Anriod, iOS, Windows 7 & 10 에서 사용할 수 있습니다. 부모님 앱도 다양한 플랫폼에서 사용할 수 있습니다.",
  blendedLearning: "복합적인 학습법",
  blendedLearningDescription:
    "자료들은 소프트웨어, 콘텐츠 및 다양한 자료들과 연결되어 있어서 지속적이며 일관적인 학습을 할 수 있습니다.",
  personalGuidance: "개인 지도",
  personalGuidanceDescription:
    "BZabc 앱은 학생의 개인 성과에 따라 코스를 안내합니다. 클라우드를 사용하여 학생들이 지속적으로 학습을 이어 나갈 수 있습니다.",
  trackingReporting: "보고서",
  trackingReportingDescription:
    "S학생의 수행 평과는 기록으로 남습니다. 점수와 오답은 보고서에 기록되고 부모님에게 실시간으로 제공됩니다. 부모님은 자녀가 언제 공부하며 어떤 어려움을 겪었는지 알 수 있습니다.",
  // END Tech Specs //
  // Bottom Titles//
  bottomCourseInfo: "자녀들을 위한 철자, 수학 및 언어 교육",
  ourPlatformInfo: "BZabc 학습 플랫폼",
  // END Bottom Titles //
  // Bottom Links //
  store: "스토어",
  privacy: "개인 정보 정책",
  terms: "서비스 약관",
  // END Bottom Links //
  //Additional Info //
  learningPlatform: "학습 플랫폼",
  learnMore: "더 알아보기",
  productsNotFound: "상품을 찾을 수 없습니다.",
  appDownloadCenter: "앱 다운로드 센터",
  appDownloadCenterDescription:
    "학생 앱을 사용하기 전에 부모님 또는 학교가 등록해야 합니다.",
  more: "더 알아보기...",
  parentsApp: "BZabc 학부모",
  kidsApp: "BZabc 학생",
  // END Additional Info //
};
