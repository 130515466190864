import translations from './translations';

export default {
  ...translations,
  // 頂部標題 //
  giveGiftOfLearning: "BZabc學習平台",
  professionallyDesignedCourses: "為年輕學習者設計的拼寫、數學和語言課程",
  generalInfoGB:
    "GravityBrain Inc.致力於為教育界提供具有意義的結果、洞察力和有用工具的電子學習系統，利用數據驅動技術來提升傳統材料和方法。",
  // 結束 頂部標題 //
  // 應用下載中心 //
  AppDownload: "應用下載中心",
  AppDownloadDescription:
    "在使用Kids App之前，學習者必須由學校或其父母/監護人註冊並報名參加課程。",
  // 結束 應用下載中心 //
  // BZabc學習平台 //
  aboutGravityBrainNote: "關於GravityBrain的段落，1-3句話",
  welcomeToGravityBrain: "電子學習，面向所有人",
  InteractiveTestingAndTraining: "測試和培訓：早期學習者",
  aboutBZabcNote:
    "BZabc適用於年輕學習者，旨在大大提升核心科目語言和數學培訓的技能獲取。高度互動，媒體豐富，實時報告和即時通訊只是其中的一些特點。",
  authoringTools: "發布和撰寫工具",
  aboutAuthoringToolsNote:
    "獨立的出版商可以通過GravityBrain Pubtool在GravityBrain上發布互動式、視頻課程。作家、創作者、視覺和錄音藝術家可以合作構建自己的課程，共享收入機會。",
  multimediaDevelopment: "多媒體開發",
  aboutMMDevelopmentNote:
    "GravityBrain Inc.與Inno-Care.ca合作，在我們的系統中製作所有多媒體。Inno-Care是一個非營利性組織，提供競爭性的3D動畫、音視頻和音頻制作費率，同時為特殊需求的人提供有意義的就業機會。",
  trainingSystemSAAS: "電子學習SAAS",
  aboutTrainingSystemsSAASNote:
    "對於希望運營自己服務的政府或較大學校，我們提供功能強大的學習管理系統，具有眾多功能：個性化、數據驅動的學習。報告。發布工具，應用程序。消息。還有許多其他功能。",
  contactInfo:
    "電子郵件：office@gravitybrain.com\n電話：1-306-570-6488\nSkype：gravity_brain\nPO Box 568 Hamiota, \nR0M 0T0，加拿大",
  splashBannerPoint1: "快速註冊和報名",
  splashBannerPoint2: "有趣的互動課程",
  splashBannerPoint3: "個性化數據驅動學習",
  splashBannerPoint4: "實時報告和消息",
  LetsStartButton: "開始吧",
  // 結束 BZabc學習平台 //
  // BZabc方法 - 益處 //
  BZabcMethod: "BZabc方法",
  practicalSkillDevelopment: "實用技能開發",
  practicalSkillDevelopmentDescription:
    "聆聽。口語。閱讀。寫作。\n BZabc幫助學生內化這些基本技能，通過鼓勵參與各種活動。",
  consistency: "一致性",
  consistencyDescription:
    "軟件、內容和工作簿之間的材料相互連接，為學習過程提供一致性。一個真正融合、連接的學習環境。",
  totalPhysicalResponse: "全身反應法",
  totalPhysicalResponseDescription:
    "在家裡或課堂上，令人興奮的內容鼓勵多層次參與。明確的可達成的學習目標非常有價值，保持學生的參與和積極性。",
  dailyPractice: "連續性：每日練習",
  dailyPracticeDescription:
    "BZabc Kids App易於使用，並引導年幼學習者在整個課程中進行所有數字和書面活動。日、週、月。",
  // 結束 BZabc方法 - 益處 //
  // 技術規格 //
  techSpecs: "技術規格",
  forAllPlatforms: "適用於所有平台",
  forAllPlatformsDescription:
    "BZabc Kids App在Android、iOS和Windows 7和10上均可使用。我們的Parents App也適用於所有平台。",
  blendedLearning: "融合學習",
  blendedLearningDescription:
    "軟件、內容和工作簿之間的材料相互連接，為學習過程提供連續性和一致性。",
  personalGuidance: "個性指導",
  personalGuidanceDescription:
    "BZabc App利用學生的表現來指導他們的課程。基於雲的數據跟踪和處理意味著無論何時何地學習，學生都在正確的頁面上。",
  trackingReporting: "跟踪 / 報告",
  trackingReportingDescription:
    "學生的表現在我們的系統中進行跟踪。分數和錯誤報告數據實時提供給父母。這使得父母可以看到孩子什麼時候工作以及他們可能遇到的任何挑戰。",
  // 結束 技術規格 //
  // 底部標題//
  bottomCourseInfo: "為年輕學習者設計的拼寫、數學和語言",
  ourPlatformInfo: "BZabc學習平台",
  // 結束 底部標題 //
  // 底部鏈接 //
  store: "商店",
  privacy: "隱私政策",
  terms: "服務條款",
  // 結束 底部鏈接 //
  //  附加信息 //
  learningPlatform: "學習平台",
  learnMore: "了解更多",
  productsNotFound: "找不到產品...",
  appDownloadCenter: "應用下載中心",
  appDownloadCenterDescription:
    "在使用Kids App之前，學習者必須由學校或其父母/監護人註冊並報名參加課程。",
  more: "更多...",
  parentsApp: "BZabc父母",
  kidsApp: "BZabc Kids",
};
