export default {
    home: 'Home',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    kr: '한국어',
    zh_hans: '汉语',
    zh_hant: '漢語',
    pt: 'Portugues',
    freeProduct: '免費',
tools: "工具",
company: "公司",
contactUs: "聯繫我們",
about: "關於",
login: "登錄",
signin: "登錄",
signup: "註冊",
sendGift: "送禮",
subscription: "訂閱",
platform: "平台",
parentsStudents: "學生與家長",
schoolsTeachers: "學校與教師",
publishers: "出版商",
copyright: "版權 © 2020 bzabc",
splashOverviewButton: "概覽",
aboutUsNote:
        "BZabc由加拿大私人公司GravityBraininc製作，致力於通過現代技術改善兒童在語言和數學訓練中的學習成果，利用現代技術來增強而不是替代傳統材料和方法。",
welcomeToWebsite: "歡迎來到BZabc",
overview: "概覽",
pdf: 'PDF',
usingOurProduct: "使用BZabc",
methods: "方法",
quickstart: "快速開始",
ourMethod: "BZabc方法",
platformOverview: "平台概覽",
publishersTab: "出版商",
store: "商店",
privacy: "隱私政策",
terms: "服務條款",
learningPlatform: "學習平台",
learnMore: "了解更多",
productsNotFound: "未找到產品...",
appDownloadCenter: "應用下載中心",
appDownloadCenterDescription: "在使用兒童應用程序之前，學習者必須通過學校或其父母/監護人註冊並報名參加課程。",
more: "更多...",
parentsApp: "BZabc家長",
kidsApp: "BZabc兒童",
schoolWebTool: "學校網路工具",
gettingStarted: "入門指南",

// Terms and Conditions //
    termsAndConditions: {
        title: "條款及細則",
        paragraphs: [
            {
                title: "歡迎來到 GravityBrainInc.",
                content: `這些條款和細則概述了使用GravityBrainInc.的規則和規定
                          網站 GravityBrainInc.位於：
                          PO Box 568 Hamiota,
                          Manitoba R0M0T0,
                          加拿大
                          通過訪問該網站，我們假設您已完全接受這些條款和條細則。如果您不接受本頁上所述的所有條款和條件，請不要繼續使用GravityBrainInc.的網站。
                          以下術語適用於這些條款和條件，隱私聲明和免責聲明以及任何或所有協議：“客戶”，“您”和“您的”是指您，即訪問本網站並接受公司條款和條件的人。 “公司”，“我們自己”，“我們”和“我們的”是指我們的公司。 “當事人”，“當事人們”或“我們”是指客戶和我們自己，或者客戶或我們自己。所有條款均指以最適當的方式（無論是通過固定期限的正式會議，還是通過其他任何方式）以明確方式滿足客戶要求的方式，以最適當的方式向客戶提供協助的過程，要約，接受和對付款的考慮。根據並遵守加拿大現行法律，客戶在提供公司規定的服務/產品方面的需求。以單數，複數，大寫字母和/或他（或她）的形式使用以上術語或其他措詞，應視為可互換使用，且是相同的。`
            },
            {
                title: "Cookies",
                content: `我們使用cookie。 通過使用GravityBrainInc.的網站，您同意根據GravityBrainInc.的隱私政策使用cookie。大多數現代互動式網站使用cookie來使我們能夠為每次訪問檢索用戶詳細信息。 Cookies在我們網站的某些區域中使用，以啟用該區域的功能並易於訪問的人使用。 我們的某些會員合作夥伴也可能使用cookie。`
            },
            {
                title: "執照",
                content: `除非另有說明，否則GravityBrainInc.和/或其許可人擁有GravityBrainInc.上所有資訊的知識產權。保留所有知識產權。 在這些條款和條件中設置的限制下，您可以出於個人目的從https://gravitybrain.com/查看和/或列印頁面。
                          你不能：
                          - 從https://www.gravitybrain.com重新發布資訊
                          - 從https://www.gravitybrain.com出售，出租或再授權資訊
                          - 從https://www.gravitybrain.com複製，複本或拷貝資訊
                          從GravityBrainInc.重新分發內容（除非內容專門用於重新分發）`
            },
            {
                title: "超連結到我們的內容",
                content: `以下組織可能未經您的許可而連結到的網站：
                        - 政府機構;
                        - 搜索引擎;
                        - 新聞機構;
                       線上目錄分發者在目錄中列出我們時，可以超連結到其他列出的企業網站且以相同的方式也連結到我們的網站； 以及系統範圍內的認可企業，但不能建立非營利組織超連結到我們的網站，慈善購物中心和慈善籌款團體除外。
                       這些組織可以連結到我們的主頁，出版物或其他網站信息，只要該連結為：（a）絕對不會引起誤解； （b）不虛假暗示連結及其產品或服務的贊助，認可或批准； （c）符合連結方網站的上下文。
                       我們可以全權酌情考慮並批准以下類型組織的其他連結請求：眾所周知的消費者和/或商業信息來源，例如商會，美國汽車協會，AARP和消費者聯盟； dot.com社區網站； 代表慈善機構的協會或其他團體，包括慈善捐贈網站，線上目錄分發者； 互聯網門戶； 主要客戶為企業的會計，法律和諮詢公司； 以及教育機構和行業協會。
                       如果我們確定以下條件，我們將批准來自這些組織的連結請求：（a）連結不會對我們或我們認可的企業產生不利影響（例如，行業協會或代表固有可疑業務類型的其他組織，例如從事工作的公司）在家工作，不得連結）； （b）該組織沒有我們的不良記錄； （c）與超連結相關聯的可見性給我們帶來的好處大於對超連結的了解； （d）連結位於一般資源信息的上下文中，或者與通訊或類似產品的社論內容相符，從而進一步推動了該組織的使命。
                       如果您屬於上面第二段中列出的組織，並且有興趣連結到我們的網站，則必須通過發送電子郵件至admin@gravitybrain.com來通知我們。 請包括您的姓名，您的組織名稱，聯繫方式（例如電話號碼和/或電子郵件地址）以及您的網站的網址，您打算連結到我們網站的所有網址列表， 以及您要連結到的我們網站上的URL列表。 等待二至三週以作出回應。`
            },
            {
                title:
                        "認可的組織可以超連結到我們的網站，如下所示：",
                content: ` -使用我們的公司名稱； 或是
                        - 通過使用連結到的統一資源定位器（網址）； 或是
                        - 通過使用我們網站的其他描述或連結到的資訊，在連結方網站內容的上下文和格式中是有意義的。
                       在沒有商標許可協議的情況下，不得使用GravityBrainInc.的徽標或其他藝術品進行連結。`
            },
            {
                title: "構思",
                content: `未經事先認可和明確的書面許可，您不得在我們的網頁周圍創建框架或使用其他以任何方式改變網站外觀或外觀的技術。`
            },
            {
                title: "保留權利",
                content: `我們保留隨時自行決定要求您刪除所有連結或指向我們網站的任何特定連結的權利。 您同意在收到此類請求後立即刪除所有指向我們網站的連結。 我們也保留隨時修改這些條款和細則及其連結政策的權利。 繼續連結到我們的網站，即表示您同意遵守這些連結條款和細則。`
            },
            {
                title: "從我們的網站上刪除連結'",
                content: `如果您發現我們網站上的任何連結或出於任何原因令人反感的連結網站，都可以就此與我們聯繫。 我們將考慮刪除連結的請求，但沒有義務這樣做或直接回复您。
                        我們盡力確保本網站上的信息正確無誤，但我們不保證其完整性或準確性； 我們也不保證網站保持可用狀態或網站上的資料保持最新。`
            },
            {
                title: "內容承擔責任",
                content: `對於您網站上出現的任何內容，我們概不負責。 您同意因您的網站或基於您的網站而引起的所有索賠進行賠償並對我們辯護。 任何連結都不會出現在您網站的任何頁面上，或在任何上下文中出現，其內容或資訊可能被解釋為誹謗，淫穢或犯罪，或侵犯，以其他方式侵犯或主張侵犯或以其他方式侵犯任何內容 第三方權利。`
            }
        ]
    },
    privacyPolicyContent: {
        title: "GravityBrain隱私政策",
        subTitle: "隱私政策",
        paragraphs: [
            {
                title: "我們收集哪些信息？",
                content: `當您下訂單或在我們的軟體上註冊時，我們會從您那裡收集信息。
                          在我們的網站上訂購或註冊時，可能會要求您輸入您的姓名，電子郵件地址或其他個人信息。 為了您的安全，我們不會直接在我們的網站上處理，收集或存儲信用卡或財務信息，而是利用第三方提供商的服務，例如Paypal或Alipay。 您也可以選擇匿名訪問我們的網站。`
            },
            {
                title: "我們如何使用您的信息？",
                content: `我們從您那裡收集的任何信息都可以通過以下方式之一使用：
                          個人化您的體驗（您的信息可幫助我們更好地響應您的個人需求）
                          改善客戶服務（您的信息可幫助我們更有效地響應您的客戶服務要求和支持需求）
                          處理交易（您的信息無論是公開的還是私人的，未經您的同意，不會出於任何原因出售，交換，轉讓或提供給任何其他公司，除非是出於交付所要求的購買產品或服務的明確目的）
                          發送定期電子郵件。您提供的用於訂單處理的電子郵件地址，除了偶爾接收公司新聞，更新，相關產品或服務信息等之外，還可以用於向您發送與訂單有關的信息和更新。`
            },
            {
                title: "我們如何保護您的信息？",
                content: `當您下訂單或輸入、提交或訪問您的個人信息時，我們會採取各種安全措施來維護您的個人信息的安全。
                          我們提供使用安全的服務器。 所有提供的敏感/信用信息都是通過安全通訊端層（SSL）技術傳輸的，然後加密到我們的付款網關提供商數據庫中，只有擁有此類系統特殊訪問權限的授權人員才能訪問，並且需要對該信息保密。
                          您的私人財務信息（信用卡，身分證，財務信息等）的交易，收集和存儲將不會在我們的服務器上處理或存儲。 為了保護和安全，此類交易直接通過第三方提供商進行。`
            },
            {
                title: "我們使用cookie嗎？",
                content: `我們網站上的Cookies用於自動化追踪和審查學生的情況。 我們還將使用此信息來處理僅由家長或學校授權的可用報告。 此外，我們還將使用該信息來跟踪哪些訂閱處於活動狀態，哪些沒有激活。`
            },
            {
                title: "我們是否透露任何信息給其他方？",
                content: `我們不會將您的個人身份信息出售，交易或以其他方式轉讓給外部各方。 這不包括協助我們運營我們的網站，開展我們的業務或為您提供服務的受信任的第三方，只要這些第三方同意對這些信息保密。 當我們認為發布適合遵守法律，執行我們的網站政策或保護我們或他人的權利，財產或安全時，我們也可能會發布您的信息。 但是，可以將非個人可識別的訪客信息提供給其他方以進行營銷，廣告或其他用途。`
            },
            {
                title: "加州線上隱私保護法合規",
                content: `因為我們重視您的隱私，所以我們已採取必要的預防措施，以符合《加利福尼亞線上隱私保護法》。 因此，未經您的同意，我們不會將您的個人信息分發給外部各方。`
            },
            {
                title: "符合《兒童線上隱私保護法》",
                content: `我們符合COPPA（兒童線上隱私保護法）的要求，我們不會從13歲以下的任何人那裡收集任何信息。 我們的網站、產品和服務均針對至少13歲或以上的人群。 父母可以選擇為孩子輸入一個名字縮寫或使用其真實姓名。`
            },
            {
                title: "有關我們培訓系統的私人信息",
                content: `您可以選擇在我們的軟體上輸入個人信息，例如全名和生日。 此信息的目的僅是為了您的利益，而您也不做也行。 生日信息僅用於建議您可能會對您的系統感興趣的課程。`
            },
            {
                title: "條款和細則",
                content: `另請訪問我們的條款和細則部分，以了解使用，免責聲明和限制我們網站使用的責任限制，網址為：`
            },
            {
                title: "您的同意",
                content: `使用我們的網站，即表示您同意本隱私政策。`
            },
            {
                title: "更改我們的隱私政策",
                content: `如果我們決定更改我們的隱私政策，我們將在頁面上發布這些更改。`
            },
            {
                title: "聯絡我們",
                content: `如果對此隱私政策有任何疑問，您可以使用以下信息與我們聯繫。
                          Box 568
                          Hamiota, Manitoba R0M 0T0
                          加拿大`
            }
        ]
    }
}
