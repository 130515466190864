import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import SplashWrapper from "./sections/SplashWrapper";

class SplashContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      videoContent: "",
    };
  }

  render() {
    const { t } = this.props;
    
    return (
      <SplashWrapper showJumbotron type="platform">
        <div className="container">
          <div className="col">
            <div className="productRow fadeInLeft animated row">
              {/* Interactive Testing and Training */}
              <a href="https://www.bzabc.tv" className="mx-7">
                <div className="product-card">
                  <div
                    className="product-image"
                    style={{
                      backgroundImage: `url(https://d2cnhr6egzpvdl.cloudfront.net/image/gravitybrain/splashHue_0000s_0005_test4.jpg)`,
                    }}
                  ></div>
                  <div className="principal d-flex align-items-center">
                    <h3 className="display-6 w-100 m-0 p-1">
                      {t("InteractiveTestingAndTraining")}
                    </h3>
                  </div>
                  <div className="text-center">
                    <p className="text-dark p-2 m-auto">
                      {t("aboutBZabcNote")}
                    </p>
                  </div>
                </div>
              </a>

              {/* Authoring Tools */}
              <a href="https://pubtool.gravitybrain.com" className="mx-7">
                <div className="product-card">
                  <div
                    className="product-image"
                    style={{
                      backgroundImage: `url(https://d2cnhr6egzpvdl.cloudfront.net/image/gravitybrain/splashHue_0000s_0000_publisher.jpg)`,
                    }}
                  ></div>
                  <div className="principal d-flex align-items-center">
                    <h3 className="w-100 display-6 m-0 p-1">{t("authoringTools")}</h3>
                  </div>
                  <div className="text-center">
                    <p className="text-dark p-2 m-auto">
                      {t("aboutAuthoringToolsNote")}
                    </p>
                  </div>
                </div>
              </a>

              {/* Multimedia Development */}
              <a href="https://www.inno-care.ca" className="mx-7">
                <div className="mx-7 product-card">
                  <div
                    className="product-image"
                    style={{
                      backgroundImage: `url(https://d2cnhr6egzpvdl.cloudfront.net/image/gravitybrain/splashHue_0000s_0001_multimedia.jpg)`,
                    }}
                  ></div>
                  <div className="principal d-flex align-items-center">
                    <h3 className="w-100 display-6 m-0 p-1">
                      {t("multimediaDevelopment")}
                    </h3>
                  </div>
                  <div className="text-center">
                    <p className="text-dark p-2 m-auto">
                      {t("aboutMMDevelopmentNote")}
                    </p>
                  </div>
                </div>
              </a>

              {/* Training Systems SAAS */}
              <a href="/school-teachers" className="mx-7">
                <div className="mx-7 product-card">
                  <div
                    className="product-image"
                    style={{
                      backgroundImage: `url(https://d2cnhr6egzpvdl.cloudfront.net/image/gravitybrain/splashHue_0000s_0006_medical.jpg)`,
                    }}
                  ></div>
                  <div className="principal d-flex align-items-center">
                    <h3 className="w-100 display-6 m-0 p-1">
                      {t("trainingSystemSAAS")}
                    </h3>
                  </div>
                  <div className="text-center">
                    <p className="text-dark p-2 m-auto">
                      {t("aboutTrainingSystemsSAASNote")}
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className="row info-block">
              <h4 className="text-blue">{t("generalInfoGB")}</h4>
              <div className="col contact-box">
                  <div className="contact-text mb-5">                  
                    <a href="/contact-us" id="contact-us" /><h5>{t("contactUs")} </h5>
                    <h6>{t("contactInfo")}</h6>
                  </div>
                </div>
            </div>
            
          </div>
        </div>
      </SplashWrapper>
    );
  }
}

export default withTranslation("platform")(SplashContainer);
