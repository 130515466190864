export default {
// Header and main links //
  about: "À propos",
  login: "Connexion",
  signin: "Connexion",
  signup: "S'inscrire",
  sendGift: "Envoyer un cadeau",
  subscription: "Abonnement",
  publishers: "Éditeurs",
  platform: "Plateforme",
  parentsStudents: "Élèves et parents", 
  schoolsTeachers: "Écoles et professeurs",
  subscriptionStartingFrom: "À partir de <0>$8.99</0>/mois",
  workBooksAndDownloads: "Cahier d'exercices et\nTéléchargements",
  bookstore: "Librairie",
  ourbookstore: "Notre librairie",
  overview: "Vue d'ensemble",
  pdf: "PDF",
// End - Header and main links //
// Top Titles//
  welcomeToGravityBrain: "Elearning for Everyone",
  giveGiftOfLearning: "La plateforme éducative BZabc",
  professionallyDesignedCourses:
    "Langues, maths, et orthographe\npour les jeunes élèves",
// END Top Titles //	
// App Download Center //
  AppDownload: "Centre de téléchargement d'applications",
  AppDownloadDescription: "Avant d'utiliser l'application pour les enfants, les élèves doivent être inscrits \n aux cours soit par leur école ou leurs parents/tuteurs.",
// END App Download Center //
// The BZabc Learning Platform //
  welcomeToWebsite: "Cours privés ou lien avec l'école BZabc",
  aboutUsNote:
    "Les parents peuvent faire la demande et inscrire leurs enfants pour les cours à la maison, ou alors les écoles peuvent le faire et le parent peut alors se connecter via le compte de l'enfant. Le lien vers le compte de votre enfant est le meilleur moyen de vous connecter à BZabc.tv en ligne avec les informations d'identification de votre enfant, et de vous ajouter à son compte. Vous aurez donc accès à ses rapports de résultats ainsi qu’à la messagerie de l’école.",
  splashBannerPoint1: "Utilisation pour les cours privés ou en lien avec l'école",
  splashBannerPoint2: "Abonnement permet aux parents d'inscrire leurs enfants aux cours",
  splashBannerPoint3: "Résultat en temps réel pour les cours privés ou les cours avec l'école",
  splashBannerPoint4: "Résultat et messagerie en temps réel",
  LetsStartButton: "Commencer",
// END The BZabc Learning Platform //
// BZabc Kids App //
  BZabcMethod: "Application BZabc Kids (enfants)",
  practicalSkillDevelopment:
    "iOS. Android. n'importe où. n'importe quand.",
  practicalSkillDevelopmentDescription:
    "Une fois inscrit aux cours par les parents ou l'école, l'application BZabc Kids peut être installée sur n'importe quel appareil mobile sans restriction et utilisée partout où il y a une connexion internet. ",
  consistency: "Le plaisir des yeux",
  consistencyDescription:
    "BZabc propose des clips tutoriels animés suivis d'un quiz rapide pour chaque leçon. Les enfants qui sont impliqués obtiennent le meilleur changement d'apprentissage !",
  totalPhysicalResponse: "Les chaussures de danse sont en option",
  totalPhysicalResponseDescription:
    "Des chansons d'action sont incluses avec chaque abonnement, comme l'a adopté la méthode TRP (Réponse physique totale) du professeur James Asher ",
  dailyPractice: "La régularé : pratique quotidienne",
  dailyPracticeDescription:
    "Passez quelques minutes par jour sur chacun de vos cours BZabc. La régularité est essentielle dans votre apprentissage. Une fois configuré, les enfants peuvent facilement travailler tout seuls grâce à notre guidage automatique et à notre connexion en un clic.",
// END BZabc Kids App //
// Tech Specs //
  techSpecs: "Caractéristiques techniques",
  forAllPlatforms: "Pour toutes les plateformes",
  forAllPlatformsDescription:
    "L'application BZabc Kids est disponible sur Android, iOS et Windows 7 et 10. Notre application pour les parents est également disponible sur toutes les plateformes.",
  blendedLearning: "Apprentissage mixte",
  blendedLearningDescription:
    "Les contenus entre les logiciels et les cahiers d'exercices sont reliés entre eux afin d'assurer une continuité et une cohérence tout au long du processus d'apprentissage.",
  personalGuidance: "Accompagnement personnel",
  personalGuidanceDescription:
    "L'application BZabc utilise les performances des élèves pour les guider tout au long de leurs leçons. Le suivi et le traitement des données dans le nuage signifie que quels que soient le lieu et le moment où ils apprennent, les élèves sont sur la bonne page.",
  trackingReporting: "Suivi / Rapports",
  trackingReportingDescription:
    "Les performances des élèves sont suivies sur notre système. Les résultats et les données des rapports d'erreurs sont disponibles en temps réel pour les parents. Cela leur permet de voir quand leurs enfants ont travaillé et de connaître les difficultés qu'ils ont pu rencontrer.",
// END Tech Specs //
// Bottom Titles//
  bottomCourseInfo:
    "Orthographe, maths et langues pour les jeunes élèves",
  ourPlatformInfo: "La plateforme éducative BZabc",
// END Bottom Titles //
// Bottom Links //  
  store: "Boutique",
  privacy: "Politique de confidentialité",
  terms: "Conditions d'utilisation",
 // END Bottom Links //
//  Additional Info //
  learningPlatform: "Plateforme éducative",
  learnMore: "En savoir plus",
  productsNotFound: "Produits introuvables...",
  appDownloadCenter: "Centre de téléchargement des applications",
  appDownloadCenterDescription: "Avant d'utiliser l’application pour les enfants, les élèves doivent être inscrits aux cours soit par leur école, soit par leurs parents/tuteurs.",
  more: "plus...",
  parentsApp: "BZabc Parents",
  kidsApp: "BZabc Kids (enfants)",
// END Additional Info //
};
