import React, { PureComponent } from "react";

class SplashJumbotron extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
    
      overview: false,
      overviewContent: "",
      mainBannerImage: "",
    };
  }

  componentDidMount() {  
    this.setMainBannerImage(0);
  }

  setMainBannerImage(index) {
    const { type } = this.props;
    const prefix = "//d2cnhr6egzpvdl.cloudfront.net/image/bzabc/";
    const backgroundImages = {
      platform: ["overview_01.jpg"],
    };
    if (backgroundImages[type])
      this.setState({
        mainBannerImage: prefix + backgroundImages[type][index],
      });
  }

  render() {
    const { t } = this.props;

    return <div className="main-banner">
        <div className="main-banner-background">
          <div className="main-banner-welcome text-center">
            <h1 className="px-3 py-1 mx-auto text-white">{t("welcomeToGravityBrain")}</h1>
            <div className="col-lg-8 col-md-10 mx-auto about-us-note avenir-normal">
              {/*t("aboutGravityBrainNote")*/}
            </div>
          </div>
        </div>
      </div>;    
  }
}

export default SplashJumbotron;
