import translations from './translations';

export default {
  ...translations,
  // Top Titles//
  giveGiftOfLearning: "Learning Platform",
  professionallyDesignedCourses:
    "Delivers all academic subjects\nfor kindergarten through grade six.",
  pageDescription: "School User Accounts",
  pricipalsAndAdmins: "Principals and Administrators",
  principalAndAdminsDescription:
    "* register yourself and all staff, teachers, and students. Use CSV upload for easy, speedy processing\n* group students and teachers into homerooms\n* enroll entire homerooms or individuals in our published courses, or create your own using our content or your own\n* view reports student reports in aggregate, or individual form\n* utilize SMS service to create message groups, control permissions, and communicate with parents.",
  SAAS: "SAAS for Countries, States, or Provinces",
  SAASDescription:
    "* parent company GravityBrain Inc. offers low-cost Software as a Service for our entire platform please contact us for details",
  schoolSignup: "Schools\nSign-Up",
  contactInfo:
    "email: office@gravitybrain.com\nTelephone: 1-306-570-6488\nSkype: gravity_brain\nPO Box 568 Hamiota, MB\nR0M 0T0, CANADA",

    gravityBrainInc: "GravityBrain Inc.",
    purpose: "Purpose",
    purposeDescription: "GravityBrain has been developing Elearning Software since its formation in 2007.  Our mission has always been to provide a service that encompasses the needs of the entire educational community: students, parents, schools, publishers and government agencies.  That's a tall order.",
    services: "Services",
    servicesDescription: "In terms of our services, we provide a single solution which offers skills training in all core subjects: languages, math, social studies, science\n* Personalized data driven learning\n* Reporting tools for schools and parents featuring deep insights on problematic areas\n* A permissions-based private, light-weight centralized messaging system. Meaning parents are connected to their children's teachers in one place\n* Publishing tools that allow publishers, artists, or school themselves to publish material on our system, with ability to choose private or public distribution, and to share in revenue",
    BZabcKidsApp: "BZabc Kids App",
    BZabcKidsAppDescription: "* Children watch engaging animated videos, and take interactive quizzes.  Engagement is Key to learning!\n* Students are guided through lessons, always on the right page\n* kids have easy access to their UI with pic-click login\n* Courses in English and Spelling available at time of writing, with new course being developed in Math, Spanish and French at the time of writing.",
    BZabcParentsApp: "BZabc Parents App",
    BZabcParentsAppDescription: "* Use for private study or school link\n* Parents may link with their child's school through their BZabc ID\n* Access Real time report & messaging for private or school courses\n* Subscription allows parents to enrol students in private courses",
    schoolPricipalAdmin: "School User Accounts: Principals and Administrators",
    schoolPrincipalAdminDescription: "*Register yourself and all staff, teachers, and students. Use CSV upload for easy, speedy processing\n* Group students and teachers into homerooms\n* Enroll entire homerooms or individuals in our published courses, or create your own using our content or your own\n* View reports student reports in aggregate, or individual form\n* Utilize SMS service to create message groups, control permissions, and communicate with parents",
    teachers: "Teachers",
    teachersDescription: "* Manage course/exam scheduler\n* View automated reports on students in as groups or individuals\n* Generate manual report cards\n* Message student and parents as individuals or groups within our SMS\n* Publish your lesson plans, worksheets, to our store and receive commissions on sales",
    schoolBoardsBandCouncils: "School Boards, Band Councils",
    schoolBoardBandCouncilsDescription: "* All of the school controls with added layers of permissions\n* Management of all schools in your region\n* Contact us for more information and support",
    publishingAuthoringArtist: "Publishing, Authoring, Artist",
    publishers: "Publishers",
    publishersDescription: "* Supplement your printed workbooks with interactive, data-driven learning by publishing on the BZabc Learning Platform\n* Earn revenue on both subscription and course license purchases\n* Continue earning revenue on printed materials through your current channels and/or through our online store",
    contentCreators: "Content Creators",
    contentCreatorsDescription: "* BZabc now offers profit sharing for those who wish to contribute to the BZabc series\n* Publish your lesson plans, worksheets, to our store. Receive 80% of sales, less discounts and sales commissions which you control\n* Share your learning videos, sounds, and images. Whenever they are used, you will receive a royalty",
    SAASCountryStateProvince: "SAAS for Countries, States, or Provinces",
    SAASCountryStateProvinceDescription: "GravityBrain Inc. offers low-cost Software as a Service for our entire platform. In addition to all of the features above, we offer server set-up assistance for a robust, cost-efficient, secure system. Please contact us for details.",

  // END Top Titles //
  // App Download Center //
  AppDownload: "App Download Center",
  AppDownloadDescription:
    "Before using the Kids App, Learners must be registered and enrolled \n in courses by either their school. Please view our documentation or watch the Getting Started movie for details",
  // END App Download Center //
  // The BZabc Learning Platform //
  welcomeToWebsite: "BZabc School Webtools",
  aboutUsNote:
    "In addition to offering course licenses, BZabc has multiple Web Tools available at no charge to schools.",
  splashBannerPoint1: "Mass registration for students & teachers",
  splashBannerPoint2: "Easy class enrolments by homeroom",
  splashBannerPoint3: "Robust reporting systems with minute detail",
  splashBannerPoint4: "Permissions-based real-time messaging system",
  LetsStartButton: "Get Started",
  // Lets start button to school registration.  END The BZabc Learning Platform //
  // The BZabc Method - Benefits //
  BZabcMethod: "BZabc for Schools",
  practicalSkillDevelopment: "Learning Together",
  practicalSkillDevelopmentDescription:
    "Homerooms with numerous classes, students, parents, and TAs, .... Keep it all together with BZabc. Easy set-up and management of homerooms, courses, and a robust messaging system",
  consistency: "Enhanced Teaching",
  consistencyDescription:
    "Sometimes kids need a guide; other times they need to work alone. BZabc is structured to work within a teacher-lead classroom, giving you better insights into the needs of each student.",
  totalPhysicalResponse: "Let's Move!",
  totalPhysicalResponseDescription:
    "Kids love movement.\nBZabc incorporates such into Total Physical Response method which leads to better learning outcomes.",
  dailyPractice: "Cool Tools for Teachers",
  dailyPracticeDescription:
    "Course management tools. Student Centered Planned and assessment template builders.",
  // END The BZabc Method - Benefits //
  // Tech Specs //
  techSpecs: "Tech Specs",
  forAllPlatforms: "For All Platforms",
  forAllPlatformsDescription:
    "The BZabc Kids App is available on both Android, iOS, and Windows 7 & 10.  Our Parents App is also available on all platforms.",
  blendedLearning: "Blended Learning",
  blendedLearningDescription:
    "Materials are connected between software, content and workbooks, providing continuity and consistency throughout the learning process.",
  personalGuidance: "Personal Guidance",
  personalGuidanceDescription:
    "The BZabc App utilizes student performance to guide them throughout their courses. Cloud-based data tracking and processing means wherever and whenever they learn, students are on the right page.",
  trackingReporting: "Tracking / Reporting",
  trackingReportingDescription:
    "Student performance is tracked on our system. Scores and error report data are available in real time to parents. This enables parents to see when kids worked and any challenges they may have enountered.",
  // END Tech Specs //
  // Bottom Links //
  store: "Store",
  privacy: "Privacy Policy",
  terms: "Terms of Service",
  // END Bottom Links //
  // Bottom Titles//
  bottomCourseInfo:
    "Delivers all academic subjects for kindergarten through grade six.",
  ourPlatformInfo: "The BZabc Learning Platform",
  // END Bottom Titles //
  //  Additional Info //
  learningPlatform: "Learning Platform",
  learnMore: "Learn more",
  productsNotFound: "Products Not Found...",
  appDownloadCenter: "App Download Center",
  appDownloadCenterDescription:
    "Before using the Kids App, Learners must be registered and enrolled in courses by either their school or their parent/guardian.",
  more: "more...",
  parentsApp: "BZabc Parents",
  kidsApp: "BZabc Kids",
};
