export default {
    errors: {
        400: '错误请求',        
        401: '未受权',
        403: '您没有正确的权限',
        404: '找不到记录',
        405: '错误的请求。不允许采取行动......',
        422: '无法处理的数据',
        409: '错误请求',
        429: '尝试太多次。请稍后再试......',
        500: '似服器发生错误。请稍后再试',
        canceled: '请求已被取消',
        unknown: '未知错误。请稍后再试'
    }
}