export default {
// Header and main links //
  about: "關於",
  login: "註冊",
  signin: "登入",
  signup: "報名",
  sendGift: "送禮物",
  subscription: "訂閱",
  publishers: "出版商",
  platform: "平台",
  parentsStudents: "學生和家長",
  schoolsTeachers: "學校和教師",
  subscriptionStartingFrom: "免費使用。",
  workBooksAndDownloads: "作業本\n&下載",
  bookstore: "書店",
  ourbookstore: "我們的書店",
  overview: "概述",
  pdf: "PDF",
// End - Header and main links //
// Top Titles//
  giveGiftOfLearning: "BZabc 學習平台",
  professionallyDesignedCourses:
    "通過互動式數據驅動的學習來增強您的功課",
// END Top Titles //
// App Download Center //
  AppDownload: "發布者的PubTool",
  AppDownloadDescription: "我們將幫助您入門。 Pubtool是您建立在早期學習者提供線上課程的途徑",
// END App Download Center //
// The BZabc Learning Platform //
  welcomeToWebsite: "Pubtool",
  aboutUsNote:
    "Pubtool由GravityBrain Inc.創建，使傳統出版商將他們的內容轉換成一個交互系統。無需編程。",
  splashBannerPoint1: "快速課程開發",
  splashBannerPoint2: "快速且高效的媒體上傳器",
  splashBannerPoint3: "客製化或複製課程元素",
  splashBannerPoint4: "即時報告和訊息傳遞",
  LetsStartButton: "開始吧",
// END The BZabc Learning Platform //
// The BZabc Method - Benefits //
  BZabcMethod: "進入互動",
  practicalSkillDevelopment:
    "保留您的作業簿",
  practicalSkillDevelopmentDescription:
    "Pubtool是一種非常快速且高效的方法將您的傳統作業簿轉變為互動式課程。",
  consistency: "課程連結",
  consistencyDescription:
    "利用QR碼，學生可以輕鬆找到作業簿課程，以實現即時應用內存取。",
  totalPhysicalResponse: "模塊化課程生成器",
  totalPhysicalResponseDescription:
    "數位美術者和課程編寫者可以獨立作業，無論身在何處都可以上傳他們的內容。",
  dailyPractice: "訂製課程",
  dailyPracticeDescription:
    "允許學校或區域選擇他們要的課程和展示順序，然後私下提供。",
// END The BZabc Method - Benefits //
// Tech Specs //
  techSpecs: "技術規格",
  forAllPlatforms: "對於所有平台",
  forAllPlatformsDescription:
    "BZabc兒童APP 可在Android，iOS以及Windows 7和10上使用。我們的家長APP 也可在所有平台上使用。",
  blendedLearning: "混合式學習",
  blendedLearningDescription:
    "資料連結在軟體、內容和作業簿之間，從而在整個學習過程中保持連續性和一致性。",
  personalGuidance: "個人指導",
  personalGuidanceDescription:
    "BZabc APP利用學生的表現來指導他們整個課程。雲端數據的追蹤和處理意味著無論何時何地學習，學生都會在正確的頁面上",
  trackingReporting: "追蹤/報告",
  trackingReportingDescription:
    "在我們的系統上追蹤學生的表現。分數和錯誤報告數據可即時提供給家長。這使家長可以看到孩子何時作業以及他們可能面臨的任何挑戰。",
// END Tech Specs //
// Bottom Titles//
  bottomCourseInfo:
    "通過互動式數據驅動的學習來增強您的功課",
  ourPlatformInfo: "BZabc 學習平台",
// END Bottom Titles //
// Bottom Links //
  store: "商店",
  privacy: "隱私政策",
  terms: "服務條款",
 // END Bottom Links //
//Additional Info //
  learningPlatform: "學習平台",
  learnMore: "了解更多",
  productsNotFound: "找不到產品...",
  appDownloadCenter: "應用程式下載",
  appDownloadCenterDescription: "在使用Kids App之前，學習者必須由其學校或父母/監護人註冊並報名參加課程。",
  more: "更多...",
  parentsApp: "BZabc 家長",
  kidsApp: "BZabc 兒童",
// END Additional Info //
};
