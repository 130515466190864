export default {
    errors: {
        400: "잘못된 요청",
        401: "권리가 없습니다",
        403: "당신은 올바른 권한이 없습니다.",
        404: "기록을 찾을 수 없습니다",
        405: "잘못된 요청입니다. 요청이 허용되지 않습니다 ",
        422: "처리할 수 없는 데이터",
        409: "잘못된 요청",
        429: "시도를 너무 많이 했습니다. 나중에 다시 시도하세요",
        500: "서버 에러. 나중에 다시 시도하세요.",
        canceled: "요청이 취소 되었습니다",
        unknown: "알 수 없는 오류입니다. 나중에 다시 시도하세요"
    }
}