import translations from "./translations";
export default {
  ...translations,
  // 頂部標題//
  giveGiftOfLearning: "學習平台",
  professionallyDesignedCourses:
    "提供幼稚園至六年級所有學科。",
  pageDescription: "學校用戶帳戶",
  pricipalsAndAdmins: "校長和管理員",
  principalAndAdminsDescription:
    "* 自行註冊和註冊所有工作人員、教師和學生。 使用CSV上傳以進行簡便快速處理\n* 將學生和教師分組到班級\n* 將整個班級或個人註冊到我們的已發布課程中，或使用我們的內容或您自己的內容創建您自己的課程\n* 以集合或個體形式查看學生報告\n* 利用短訊服務建立消息組、控制權限並與家長溝通。",
  SAAS: "國家、州或省份的SAAS",
  SAASDescription:
    "* 母公司GravityBrain Inc.提供我們整個平台的低成本軟件即服務，詳情請聯繫我們",
  schoolSignup: "學校\n註冊",
  contactInfo:
    "電子郵件：office@gravitybrain.com\n電話：1-306-570-6488\nSkype：gravity_brain\nPO Box 568 Hamiota, \nR0M 0T0，加拿大",

  gravityBrainInc: "GravityBrain Inc.",
  purpose: "目的",
  purposeDescription: "GravityBrain自2007年成立以來一直在開發電子學習軟件。 我們的使命始終是提供一項服務，滿足整個教育界的需求：學生、家長、學校、出版商和政府機構。 這是一個艱鉅的任務。",
  services: "服務",
  servicesDescription: "就我們的服務而言，我們提供了一個全方位的解決方案，涵蓋所有核心科目的技能培訓：語言、數學、社會研究、科學\n* 個性化的數據驅動學習\n* 學校和家長的報告工具，深入洞察問題領域\n* 基於權限的私人、輕量級的集中式消息系統。 這意味著家長可以在一個地方與他們孩子的老師聯繫\n* 出版工具，允許出版商、藝術家或學校自行發布材料到我們的系統，具有選擇私人或公開發布的能力，並分享收入",
  BZabcKidsApp: "BZabc兒童應用程式",
  BZabcKidsAppDescription: "* 兒童觀看引人入勝的動畫視頻，並進行互動測驗。 參與是學習的關鍵！\n* 學生在課程中得到引導，始終處於正確的頁面\n* 孩子可以使用點擊圖片登錄輕鬆訪問他們的用戶界面\n* 目前提供英文和拼寫課程，撰寫時還在開發數學、西班牙語和法語課程。",
  BZabcParentsApp: "BZabc家長應用程式",
  BZabcParentsAppDescription: "* 用於私人學習或學校鏈接\n* 家長可以通過他們的BZabc ID與孩子的學校鏈接\n* 存取私人或學校課程的實時報告和消息\n* 訂閱使家長可以為學生註冊私人課程",
  schoolPricipalAdmin: "學校用戶帳戶：校長和管理員",
  schoolPrincipalAdminDescription: "* 註冊自己和所有工作人員、教師和學生。 使用CSV上傳以進行簡便快速處理\n* 將學生和教師分組到班級\n* 將整個班級或個人註冊到我們的已發布課程中，或使用我們的內容或您自己的內容創建您自己的課程\n* 以集合或個體形式查看學生報告\n* 利用短訊服務建立消息組、控制權限並與家長溝通",
  teachers: "教師",
  teachersDescription: "* 管理課程/考試排程\n* 查看學生的自動報告，作為團體或個體\n* 生成手動成績單\n* 通過我們的短訊系統向學生和家長發送個體或群組消息\n* 將您的教案、工作表發布到我們的商店，並根據銷售獲得佣金",
  schoolBoardsBandCouncils: "學校委員會、樂團理事會",
  schoolBoardBandCouncilsDescription: "* 所有學校控制權與添加的權限層級\n* 管理您地區的所有學校\n* 聯繫我們以獲取更多信息和支持",
  publishingAuthoringArtist: "出版、創作、藝術家",
  publishers: "出版商",
  publishersDescription: "* 通過在BZabc學習平台上發布互動式、數據驅動的學習來補充您的印刷工作冊\n* 在訂閱和課程許可證購買上賺取收入\n* 通過您目前的渠道或通過我們的網上商店持續賺取印刷材料的收入",
  contentCreators: "內容創作者",
  contentCreatorsDescription: "* BZabc現在為那些希望為BZabc系列做出貢獻的人提供利潤共享\n* 將您的教案、工作表發布到我們的商店。 獲得銷售的80%，減去您控制的折扣和銷售佣金\n* 分享您的學習視頻、音頻和圖像。 每當它們被使用時，您將收到版稅",
  SAASCountryStateProvince: "國家、州或省份的SAAS",
  SAASCountryStateProvinceDescription: "GravityBrain Inc.提供我們整個平台的低成本軟件即服務。 除了上述所有功能外，我們還提供服務器設置協助，構建堅固、高效、安全的系統。 詳情請聯繫我們。",
  // END 頂部標題//
  // 應用程式下載中心 //
  AppDownload: "應用程式下載中心",
  AppDownloadDescription:
    "在使用兒童應用程式之前，學習者必須由學校註冊並註冊在課程中。 請查看我們的文檔或觀看入門電影以獲取詳細信息。",
  // END 應用程式下載中心 //
  // BZabc學習平台 //
  welcomeToWebsite: "BZabc學校網頁工具",
  aboutUsNote:
    "除了提供課程許可證外，BZabc還為學校提供多種免費的Web工具。",
  splashBannerPoint1: "學生和教師的大規模註冊",
  splashBannerPoint2: "按班級輕鬆註冊",
  splashBannerPoint3: "具有詳細內容的強大報告系統",
  splashBannerPoint4: "基於權限的實時消息系統",
  LetsStartButton: "開始",
  // Lets start button to school registration.  END BZabc學習平台 //
  // BZabc方法-好處 //
  BZabcMethod: "BZabc為學校",
  practicalSkillDevelopment: "共同學習",
  practicalSkillDevelopmentDescription:
    "具有大量班級、學生、家長和助教的班級，... 將所有這些與BZabc保持一致。 班級、課程和強大消息系統的簡單設置和管理",
  consistency: "增強教學",
  consistencyDescription:
    "有時孩子需要一個指導； 其他時候，他們需要獨立工作。 BZabc的結構設計為在教師主導的課堂中使用，使您更好地了解每個學生的需求。",
  totalPhysicalResponse: "讓我們動起來！",
  totalPhysicalResponseDescription:
    "孩子們喜歡動。 BZabc將這種方法納入了全身反應的方法，這將帶來更好的學習成果。",
  dailyPractice: "教師的酷工具",
  dailyPracticeDescription:
    "課程管理工具。 學生中心的計劃和評估模板建立者。",
  // END BZabc方法-好處 //
  // 技術規格 //
  techSpecs: "技術規格",
  forAllPlatforms: "適用於所有平台",
  forAllPlatformsDescription:
    "BZabc Kids App在Android、iOS和Windows 7和10上都可用。 我們的家長應用程序也可在所有平台上使用。",
  blendedLearning: "混合學習",
  blendedLearningDescription:
    "軟體、內容和工作簿之間的材料相互關聯，確保學習過程的連貫性和一致性。",
  personalGuidance: "個人指導",
  personalGuidanceDescription:
    "BZabc App利用學生的表現來指導他們完成課程。 基於雲的數據跟蹤和處理意味著學生無論何時何地學習，都在正確的頁面上。",
  trackingReporting: "跟蹤/報告",
  trackingReportingDescription:
    "我們的系統跟踪學生的表現。 成績和錯誤報告數據即時提供給家長。 這使家長能夠查看孩子何時學習以及可能遇到的任何挑戰。",
  // END 技術規格 //
  // 底部鏈接 //
  store: "商店",
  privacy: "隱私政策",
  terms: "服務條款",
  // END 底部鏈接 //
  // 底部標題//
  bottomCourseInfo:
    "提供幼稚園至六年級所有學科。",
  ourPlatformInfo: "BZabc學習平台",
  // END 底部標題 //
  //  額外信息 //
  learningPlatform: "學習平台",
  learnMore: "了解更多",
  productsNotFound: "找不到產品...",
  appDownloadCenter: "應用程式下載中心",
  appDownloadCenterDescription:
    "在使用兒童應用程式之前，學習者必須由他們的學校或家長/監護人註冊並註冊在課程中。",
  more: "更多...",
  parentsApp: "BZabc家長",
  kidsApp: "BZabc兒童",
};
