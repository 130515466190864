import translations from './translations';

export default {
  ...translations,
// Top Titles//
  giveGiftOfLearning: "The BZabc Learning Platform",
  professionallyDesignedCourses:
    "Enhance your workbooks\nwith interactive, data-driven learning",
// END Top Titles //
// App Download Center //
  AppDownload: "Pubtool for Publishers",
  AppDownloadDescription: "We'll help you get started.  Pubtool is your path to creating and delivering online courses to early learners",
// END App Download Center //
// The BZabc Learning Platform //
  welcomeToWebsite: "Pubtool",
  aboutUsNote:
    "Pubtool is created by GravityBrain Inc. to enable traditional publishers to put their content into an interactive system. No programming required.",
  splashBannerPoint1: "Rapid course development",
  splashBannerPoint2: "Quick and efficient media uploader",
  splashBannerPoint3: "Customize or duplicate course elements",
  splashBannerPoint4: "Real-time reporting & messaging",
  LetsStartButton: "Let's Start",
// END The BZabc Learning Platform //
// The BZabc Method - Benefits //
  BZabcMethod: "Go Interactive",
  practicalSkillDevelopment:
    "Keep your Workbooks",
  practicalSkillDevelopmentDescription:
    "Pubtool is an extreme fast and efficient way to turn your traditional workbooks into interactive courses.",
  consistency: "Help a brother!",
  consistencyDescription:
    "GravityBrain's Pubtool gives you interactive courses without having to write a single line of code, meaning your team can focus on authoring.",
  totalPhysicalResponse: "Modular Course Builder",
  totalPhysicalResponseDescription:
    "Digital artists and curriculum writers work independently, uploading their content from wherever they are in the world.",
  dailyPractice: "Customize Courses",
  dailyPracticeDescription:
    "Allows schools or regions to choose their lessons and presentation order. Then deliver privately.",
// END The BZabc Method - Benefits //
// Tech Specs //
  techSpecs: "Tech Specs",
  forAllPlatforms: "For All Platforms",
  forAllPlatformsDescription:
    "The BZabc Kids App is available on both Android, iOS, and Windows 7 & 10.  Our Parents App is also available on all platforms.",
  blendedLearning: "Blended Learning",
  blendedLearningDescription:
    "Materials are connected between software, content and workbooks, providing continuity and consistency throughout the learning process.",
  personalGuidance: "Personal Guidance",
  personalGuidanceDescription:
    "The BZabc App utilizes student performance to guide them throughout their courses. Cloud-based data tracking and processing means wherever and whenever they learn, students are on the right page.",
  trackingReporting: "Tracking / Reporting",
  trackingReportingDescription:
    "Student performance is tracked on our system. Scores and error report data are available in real time to parents. This enables parents to see when kids worked and any challenges they may have enountered.",
// END Tech Specs //
// Bottom Titles//
  bottomCourseInfo:
    "Enhance your workbooks with interactive, data-driven learning",
  ourPlatformInfo: "The BZabc Learning Platform",
// END Bottom Titles //
// Bottom Links //
  store: "Store",
  privacy: "Privacy Policy",
  terms: "Terms of Service",
 // END Bottom Links //
//  Additional Info //
  learningPlatform: "Learning Platform",
  learnMore: "Learn more",
  productsNotFound: "Products Not Found...",
  appDownloadCenter: "App Download Center",
  appDownloadCenterDescription: "Before using the Kids App, Learners must be registered and enrolled in courses by either their school or their parent/guardian.",
  more: "more...",
  parentsApp: "BZabc Parents",
  kidsApp: "BZabc Kids"
};
