import React, { PureComponent } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";

class SplashJumbotron extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      overview: false,
      overviewContent: "",
      mainBannerImage: ""
    };

    this.handleOpenOverview = this.handleOpenOverview.bind(this);
    this.handleCloseOverview = this.handleCloseOverview.bind(this);
    this.openPdf = this.openPdf.bind(this);
  }

  componentDidMount() {
    this.setMainBannerImage(0);
  }

  handleOpenOverview() {
    this.setOverViewUrl();
    this.setState({
      overview: true,
    });
  }

  handleCloseOverview() {
    this.setState({
      overview: false,
    });
  }

  setMainBannerImage(index) {
    const { type } = this.props;
    const prefix = "//d2cnhr6egzpvdl.cloudfront.net/image/bzabc/";
    const backgroundImages = {
      schools: ["teacher_01.jpg", "teacher_02.jpg"],
    };

    if (backgroundImages[type])
      this.setState({
        mainBannerImage: prefix + backgroundImages[type][index],
      });
  }

  setPdfViewUrl() {
    const { type } = this.props;

    const pdfs = {
      schools:
        "//d2cnhr6egzpvdl.cloudfront.net/PDFs/BZabc_Learning_Platform_Overview.pdf",
    };

    if (pdfs[type]) this.setState({ pdfViewUrl: pdfs[type] });
  }

  openPdf() {
    const { type, i18n } = this.props;

    const language = i18n.language || "en";

    const pdfs = {
      schools:
        "//d2cnhr6egzpvdl.cloudfront.net/PDFs/BZabc_Learning_Platform_Overview",
    };

    if (pdfs[type]) {
      window.open(pdfs[type] + "_" + language.toUpperCase() + ".pdf");
    }
  }

  setOverViewUrl() {
    const { type, i18n } = this.props;

    const language = i18n.language || "en";

    const overviews = {
      schools: {
        en: "//youtube.com/embed/qeWbVq_GWZw",
        es: "//youtube.com/embed/qeWbVq_GWZw",
        fr: "//youtube.com/embed/qeWbVq_GWZw",
        pt: "//youtube.com/embed/qeWbVq_GWZw",
        kr: "//youtube.com/embed/qeWbVq_GWZw",
        zh_hans: "//youtube.com/embed/qeWbVq_GWZw",
        zh_hant: "//youtube.com/embed/qeWbVq_GWZw",
      },
    };

    if (overviews[type])
      this.setState({ overviewContent: overviews[type][language] });
  }

  render() {
    const { t } = this.props;

    const {
      overview,
      overviewContent
    } = this.state;

    return (
      <div className="main-banner">
        <div className="main-banner-background-schools">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 jumbotron-text">
                <h2 className="mt-4 mt-3">{t("gravityBrainInc")}</h2>
                <h4 className="mt-4 mt-3">{t("purpose")}</h4>
                <p className="text-dark">{t("purposeDescription")}</p>
                <h4 className="mt-4 mt-3">{t("services")}</h4>
                <p className="text-dark">{t("servicesDescription")}</p>
                <div className="position-relative">
                <span className="position-absolute" id="tools" style={{top: '-100px'}}></span>
                <h2 className="mt-4 mt-3">{t("tools")}</h2>
                <a href="https://www.bzabc.tv/"><h4 className="mt-4 mt-3">{t("BZabcKidsApp")}</h4></a>
                <p className="text-dark">{t("BZabcKidsAppDescription")}</p>
                <a href="https://www.bzabc.tv/student-parents"><h4 className="mt-4 mt-3">{t("BZabcParentsApp")}</h4></a>
                <p className="text-dark">{t("BZabcParentsAppDescription")}</p>
                <a href="https://www.bzabc.tv/school-teachers"><h4 className="mt-4 mt-3">{t("schoolPricipalAdmin")}</h4></a>
                <p className="text-dark">{t("schoolPrincipalAdminDescription")}</p>
                <a href="https://www.bzabc.tv/school-teachers"><h4 className="mt-4 mt-3">{t("teachers")}</h4></a>
                <p className="text-dark">{t("teachersDescription")}</p>
                <a href="https://www.bzabc.tv/school-teachers"><h4 className="mt-4 mt-3">{t("schoolBoardsBandCouncils")}</h4></a>
                <p className="text-dark">{t("schoolBoardBandCouncilsDescription")}</p>
                <a href="https://pubtool.gravitybrain.com/"><h4 className="mt-4 mt-3">{t("publishingAuthoringArtist")}</h4></a>
                <a href="https://pubtool.gravitybrain.com/signup"><h4 className="mt-4 mt-3">{t("publishers")}</h4></a>
                <p className="text-dark">{t("publishersDescription")}</p>
                <a href="https://pubtool.gravitybrain.com/signup/creator"><h4 className="mt-4 mt-3">{t("contentCreators")}</h4></a>
                <p className="text-dark">{t("contentCreatorsDescription")}</p>
                <h4 className="mt-4 mt-3">{t("SAASCountryStateProvince")}</h4>
                <p className="text-dark">{t("SAASCountryStateProvinceDescription")}</p>
                </div>
              </div>
              <div className="col schools-buttons">
                <div className="d-flex justify-content-center pb-5 pt-5 pt-md-0">
                  <a href="https://bzabc.tv/login" className="text-center d-block m-0 signup-btn btn-warning">
                      {t("schoolSignup")}
                  </a>
                  </div>
                  <div className="block d-flex justify-content-center py-5">
                      <div className="pr-5 pb-3 bookstore-background">
                          <h6 className="workbooks pr-4">{t("workBooksAndDownloads")}</h6>
                          <a className="d-block mb-3" href={`https://bzabc.tv/store`}>
                              <button className="bookstore-btn mt-2">
                                  <i className="fa fa-book mr-2"></i>
                                  {t("bookstore")}
                              </button>
                          </a>
                      </div>
                  </div>

                <div className="block py-5 d-flex justify-content-center">
                  <div className="block overview-pdf" onClick={this.openPdf}>
                    <div className="splash-round-btns">
                      <button>
                        {t("overview")} <i className="fa fa-file-pdf-o"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="position-relative contact-box py-5">
                <span className="position-absolute" id="contact-us" style={{top: '-60px'}}></span>
                  <div className="contact-text">
                    <h5>{t("contactUs")}</h5>
                    <h6>{t("contactInfo")}</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={overview}
          onClose={this.handleCloseOverview}
          className="youtube-player-dialog"
        >
          <DialogContent className="youtube-player-content">
            <iframe
              title="content"
              src={overviewContent}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </DialogContent>

          <DialogActions>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={this.handleCloseOverview}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default SplashJumbotron;
