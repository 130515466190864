import { all, takeLatest } from 'redux-saga/effects';
import {LOAD, LOAD_FAIL, LOAD_SUCCESS_AUTHENTICATED} from './actions';

function* onLoad() {
}

function* afterLoadSuccess (action) {

}

function* loadFail () {

}

const appSagas = all([
  takeLatest(LOAD, onLoad),
  takeLatest(LOAD_SUCCESS_AUTHENTICATED, afterLoadSuccess),
  takeLatest(LOAD_FAIL, loadFail)
]);

export default appSagas;