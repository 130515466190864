import React, {PureComponent} from "react";
import { withTranslation } from "react-i18next";
import CldImage from "../../../components/ui/CldImage";



class SplashMethodItem extends PureComponent {
    render() {
        const {index,title, content,url} = this.props;
        return (
            <div className={"method-item col-md-6 mb-lg-5 mb-md-4 mb-3 " + (index%2===1 ? 'odd' : 'even')} >
                <CldImage src={url}/>
                <h3 className="gotham-bold mt-4">{title}</h3>
                <p className="mt-2 mb-3 avenir-normal">{content}</p>
            </div>
        );
    }
};

export default withTranslation("translations")(SplashMethodItem);
