export default {
// Header and main links //
  about: "关于",
  login: "注册",
  signin: "登入",
  signup: "报名",
  sendGift: "送礼物",
  subscription: "订阅",
  publishers: "出版商",
  platform: "平台",
  parentsStudents: "学生和家长",
  schoolsTeachers: "学校和老师",
  subscriptionStartingFrom: "免费使用",
  workBooksAndDownloads: "作业本&下载",
  bookstore: "书店",
  ourbookstore: "我们的书店",
  overview: "概述",
  pdf: "PDF",
// End - Header and main links //
// Top Titles//
  giveGiftOfLearning: "BZabc 学习平台",
  professionallyDesignedCourses:
    "通过互动式数据驱动的学习来增强您的功课",
// END Top Titles //
// App Download Center //
  AppDownload: "发布者的PubTool",
  AppDownloadDescription: "我们将帮助您入门。 Pubtool是您建立在早期学习者提供线上课程的途径",
// END App Download Center //
// The BZabc Learning Platform //
  welcomeToWebsite: "Pubtool",
  aboutUsNote:
    "Pubtool由GravityBrain Inc.创建，使传统出版商将他们的内容转换成一个交互系统。无需编程。",
  splashBannerPoint1: "快速课程开发",
  splashBannerPoint2: "快速且高效的媒体上传器",
  splashBannerPoint3: "客制化或复制课程元素",
  splashBannerPoint4: "即时报告和讯息传递",
  LetsStartButton: "开始吧",
// END The BZabc Learning Platform //
// The BZabc Method - Benefits //
  BZabcMethod: "进入互动",
  practicalSkillDevelopment:
    "保留您的作业簿",
  practicalSkillDevelopmentDescription:
    "Pubtool是一种非常快速且高效的方法将您的传统作业簿转变为互动式课程。",
  consistency: "课程连结",
  consistencyDescription:
    "扫QR码，学生可以轻松找到作业簿课程，以实现即时应用内存取。",
  totalPhysicalResponse: "模块化课程生成器",
  totalPhysicalResponseDescription:
    "数位美术者和课程编写者可以独立作业，无论身在何处都可以上传他们的内容。",
  dailyPractice: "订制课程",
  dailyPracticeDescription:
    "允许学校或区域选择他们要的课程和展示顺序，然后私下提供。",
// END The BZabc Method - Benefits //
// Tech Specs //
  techSpecs: "技术规格",
  forAllPlatforms: "对于所有平台",
  forAllPlatformsDescription:
    "BZabc儿童 APP可在Android，iOS以及Windows 7和10上使用。我们的家长APP也可在所有平台上使用。",
  blendedLearning: "混合式学习",
  blendedLearningDescription:
    "资料连接在软体，内容和作业簿之间，从而在整个学习过程中保持连续性和一致性。",
  personalGuidance: "个人指导",
  personalGuidanceDescription:
    "BZabc APP利用学生的表现来指导他们整个课程。云端数据的追踪和处理意味着无论何时何地学习，学生都会在正确的页面上",
  trackingReporting: "追踪/报告",
  trackingReportingDescription:
    "在我们的系统上追踪学生的表现。分数和错误报告数据可即时提供给家长。这使家长可以看到孩子何时作业以及他们可能面临的任何挑战。",
// END Tech Specs //
// Bottom Titles//
  bottomCourseInfo:
    "通过互动式数据驱动的学习来增强您的功课",
  ourPlatformInfo: "BZabc 学习平台",
// END Bottom Titles //
// Bottom Links //
  store: "商店",
  privacy: "隐私政策",
  terms: "服务条款",
 // END Bottom Links //
//Additional Info //
  learningPlatform: "学习平台",
  learnMore: "了解更多",
  productsNotFound: "找不到产品...",
  appDownloadCenter: "应用程式下载",
  appDownloadCenterDescription: "在使用Kids App之前，学习者必须由其学校或父母/监护人注册并报名参加课程。",
  more: "更多...",
  parentsApp: "BZabc 家长",
  kidsApp: "BZabc 儿童",
// END Additional Info //
};

