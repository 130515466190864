export default {
    home: 'Home',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    kr: '한국어',
    zh_hans: '汉语',
    zh_hant: '漢語',
    pt: 'Portugues',
    freeProduct: 'Grátis', 
tools: "Ferramentas",
company: "Empresa",
contactUs: "Entre em Contato",
    about: "Sobre",
    login: "Login",
    signin: "Entrar",
    signup: "Criar uma Conta",
    sendGift: "Mande um Presente",
    subscription: "Inscrição",
    publishers: "Editores",
    platform: "Plataforma",
    parentsStudents: "Estudantes  & Pais",
    schoolsTeachers: "Escolas & Professores",
    subscriptionStartingFrom: "Começando em <0>$8.99</0>/mês",
    workBooksAndDownloads: "Livros de Exercícios &\nDownloads",
    bookstore: "Livraria",
    ourbookstore: "Nossa Livraria",
    overview: "Visão Geral",
    pdf: "PDF",
// End - Header and main links //
// Top Titles//
    giveGiftOfLearning: "A Plataforma de Aprendizado BZabc",
    professionallyDesignedCourses:
            "Entrega todas as matérias acadêmicas\ndesde o jardim de infância até a sexta série.",
// END Top Titles //
// App Download Center //
    AppDownload: "Centro de Download do App",
    AppDownloadDescription: "Antes de usar o Kids App, Aprendizes devem estar registrados e matriculados \n em cursos ou por suas escolas. Por favor veja a documentação ou assista o vídeo Getting Started para detalhes",
// END App Download Center //
// The BZabc Learning Platform //
    splashBannerPoint1: "Registro & matrícula rápidos",
    splashBannerPoint2: "Aulas engajadoras e interativas",
    splashBannerPoint3: "Aprendizado personalizado e baseado em dados",
    splashBannerPoint4: "Relatórios em Tempo Real",
    splashBannerPoint5: "Mensagens em Tempo Real",
    splashOverviewButton: "Visão Geral",
    aboutUsNote:
            "BZabc é produzido por GravityBraininc, uma corporaçãp canadense privada, dedicada a melhorar os resultados de aprendizagem para crianças em treinamento de idiomas e matemática utilizando tecnologia moderna para melhorar, não substituir, materiais e métodos tradicionais.",
    welcomeToWebsite: "Bem-vindo a BZabc",
    usingOurProduct: "Usando BZabc",
    methods: "Métodos",
    quickstart: "Começo Rápido",
    ourMethod: "O Método BZabc",
    platformOverview: "Visão Geral da Plataforma",
    publishersTab: "Editores",
    practicalSkillDevelopment: "Desenvolvimento de Habilidades Práticas",
    practicalSkillDevelopmentDescription:
            "Escuta. Fala. Leitura. Escrita.\nBZabc ajuda os estudantes a internalizarem estas habilidades essenciais, ao utilizar o encorajamento em participação em uma variedade de atividades.",
    consistency: "Consistência",
    consistencyDescription:
            "Materiais estão conectados entre software, conteúdo e livros de exercícios, fornecendo consistência por todo o processo de aprendizado. Um ambiente de aprendizado realmente mesclado e conectado.",
    totalPhysicalResponse: "Resposta Física Total",
    totalPhysicalResponseDescription:
            "Em casa ou na aula, conteúdo animador encoraja a participação em vários níveis. Metas de aprendizado claras e atingíveis são altamente recompensadoras, mantendo os estudantes engajados e moivados.",
    dailyPractice: "Continuidade: Prática Diária",
    dailyPracticeDescription:
            "O BZabc Kids App é fácil de usar e guia jovens aprendizes em todas as atividades, tanto digitais quanto escritas, por todo o curso. Dias, semanas e meses.",
    techSpecs: "Especificações Técnicas",
    forAllPlatforms: "Para Todas as Plataformas",
    forAllPlatformsDescription:
            "O BZabc Kids App está disponível em Android e iOS. Também estará disponível para desktops em Janeiro de 2019. Nosso Parents App também está disponível em todas as plataformas.",
    blendedLearning: "Aprendizado Mesclado",
    blendedLearningDescription:
            "Materiais são conectados entre software, conteúdo e livros de exercícios, fornecendo contiuidade e consistência por todo o processo de aprendizado.",
    personalGuidance: "Guia Pessoal",
    personalGuidanceDescription:
            "O BZabc App utiliza a performance dos estudantes para guiá-los por seus cursos. Acompanhamento e processamento de dados baseado na nuvem significa que onde e quando eles aprendam, os estudantes estarão sempre na página certa.",
    trackingReporting: "Acompanahmento / Relatórios",
    trackingReportingDescription:
            "A performance dos estudantes é rastreada no nosso sistema. Pontuações e dados de relatório de erros estão disponíveis em tempo real para os pais. Isto permite aos pais verem quando as crianças trabalharam e quaisquer desafios que podem ter encontrado.",
    store: "Loja",
    privacy: "Política de Privacidade",
    terms: "Termos de Serviço",
    learningPlatform: "Plataforma de Aprendizado",
    learnMore: "Saiba mais",
    productsNotFound: "Produtos Não Encontrados...",
    appDownloadCenter: "Centro de Download do App",
    appDownloadCenterDescription: "Antes de usar o Kids App, Aprendizes devem estar registrados e matriculados em cursos ou por suas escolas ou por seus pais/responsável.",
    more: "mais...",
    parentsApp: "BZabc Parents",
    kidsApp: "BZabc Kids",
    schoolsAndTeachersText1:
            " Por www.BZabc.tv as escolas possuem ferramentas Web para registro em massa de equipe e estudantes, agrupamento em homerooms e classes, (que são um grupo de estudantes com um curso). Um sistema robusto de relatórios que fornecem detalhes minuciosos de erros e um sistema de mensagens em tempo real baseado em permissões.",
    schoolsAndTeachersText2:
            " Note: os estudantes devem estar registrados e matriculados em cursos antes de começar trabalhos no BZabc Kids App.",
    schoolsAndTeachersText3:
            "Ferramenta Web da Escola fornece todas as ferramentas necessárias para criar usuários e papéis:",
    schoolsAndTeachersText31:
            "1. Registro de escolas, professores e estudantes,",
    schoolsAndTeachersText32:
            "2. Sistema de agrupamento designando estudantes e professores para homerooms ou salas",
    schoolsAndTeachersText33:
            "3. Matrícula em classes, baseada no agrupamento acima",
    schoolsAndTeachersText34:
            "4. Interface de Usuário do Professor detalhada para organizar aulas por dia, ou semana",
    schoolsAndTeachersText35:
            "5. Relatórios automatizados no progresso e performance dos estudantes",
    schoolsAndTeachersText36:
            "6. Um sistema robusto de mensagens para escolas, professores e pais, baseado nos mesmos grupos, com controle de permissões da parte da escola.",
    schoolsAndTeachersText37:
            "7. Planejamento Centrado em Estudantes e avaliação de construidores de modelos, que pode ser designado para professoresque então podem produzir relatórios únicos como você tem agora.",
    studenstAndParents1:
            "Pais podem matricular crianças em cursos para estudo em casa, ou as escolas podem fazer isto e então o pai pode criar um link para a conta da criança.  O link para a conta da sua criança, o melhor jeito de logar em BZabc.tv online sem as credenciais da sua criança e se adicionar à conta dela. Isto te dará acesso aos seus relatórios assim como mensagens da escola.",
    studenstAndParents2:
            "Para a sua criança fazer o trabalho do curso , ela deve antes estar registrada e matriculada na escola ou em casa.  Pais gerenciam a conta da criança sob a sua própria pelo BZabc PArents App.  Crianças fazem o trabalho do curso no BZabc Kids App.",
    studenstAndParents3: "O BZabc Kids App (iOS, Android, Windows)",
    studenstAndParents4:
            "Aulas interativas estão agrupadas em cursos, que usam apresentações de vídeo e métodos 'drill' tradicionais.  Relevantemente os estudantes não são só acompanhados, mas recebem avaliações baseadas em dados baseadas no seu progresso e performance pessoal",
    studenstAndParents41:
            "1. Apresenta cursos para crianças já matriculadas por seus pais ou escola",
    studenstAndParents42:
            "2. Oferece apresentação em vídeo seguida por um breve teste para cada aula",
    studenstAndParents43:
            "3. Feito usado por um período de meses, fornece avaliações automáticas baseadas na performance e progresso da criança",
    studenstAndParents44:
            "4. Também pode ser usado para entregar exames online em tempo real.",
    studenstAndParents5: "O Parents App (iOS, Android, Browser www.bzabc.tv)",
    studenstAndParents51:
            "Pode ser usado em conjunto com o Sistema da Escola ou sozinho",
    studenstAndParents52:
            "Permite aos pais criar um link para a escola da criança para mensagens e relatórios",
    studenstAndParents53: "Permite aos pais matricularem estudantes em cursos,",
    studenstAndParents54:
            "Oferece dados de curso em tempo real sobre performance e progresso pelos cursos.",

    publishersText1:
            "Enquanto nossa marca, BZabc fornece alguns cursos, outros editores podem acessar nosso sistema para criar seus próprios cursos.  Nós também podemos costumizar cursos para sistemas utilizando nosso próprio material.",
    publishersText2:
            "Editores enviam materiais, criam perguntas em uma variedade de formas: arrastar e soltas, preencher os campos, etc.  Há vários tipos de questões feitos especificamente para matemática ou línguas.  ",
    publishersText3:
            "Nós podemos oferecer cursos em qualquer habilidade central. Nosso sistema é feito especificamente para funcionar com livros já existentes.",
    publishersText4:
            "Todo o software está pronto e nós podemos ter todas as escolas e pais agrupados e conectados dentro de um dia ou dois. No momento nós só temos alguns cursos, mas nós podemos crescer rapidamente para acomodar quaisquer que sejam os requisitos da grade curricular para os cursos e níveis de estudantes. Relevantemente, todas as notas e relatórios dos estudantes para todos os cursos está em um só lugar. Não é necessário ter uma variedade de Apps",
    publishersText5:
            "Se seu grupo gostaria de discutir ser autores de materiais, por favor nos contate em <0>office@gravitybrain.com</0> ou ligue para 1-306-570-6488",
    schoolWebTool: "Ferramenta Web da Escola",
    gettingStarted: "Começando",
    copyright: "Copyrigh © 2020 bzabc",
    // Terms and Conditions //
    termsAndConditions: {
        title: "Termos e Condições",
        paragraphs: [
            {
                title: "Bem-vindo a GravityBrainInc.",
                content: `Esses termos e condições traçam as regras e regulações para o uso de GravityBrainInc.
                          Website. GravityBrainInc. está localizado em:
                          PO Box 568 Hamiota,
                          Manitoba R0M0T0,
                          CANADA.
                          Ao acessar este site nós presumimos que você aceita esses termos e condições completamente. Não continue a usar o website de GravityBrainInc. se você não aceita todos os termos e condições expressos nesta página.
                          A seguinte terminologia se aplica a estes Termos e Condições, Declaração de Privacidade e Aviso de Renúncia Legal e todo ou qualquer Acordos: "Cliente", "Você" e "Seu" se refere a você, a pessoa acessando este website e aceitando os termos e condições da Empresa. "A empresa", "Nós", "Nosso", "Nossa" e "Nossos", se referem à nossa Empresa. "Parte", "Partido", ou "Nós", se referem a tanto o Cliente quanto nós, ou o Cliente ou nós. Todos os termos se referem à oferta, aceitação e consideração de pagamento necessário para empreender o processo de nossa assistência ao Cliente na maneira mais apropriada, seja por reuniões formais de uma duração fixa, ou quaisquer outros meios, para o motivo expresso de satisfazer as necessidades do Cliente em respeito à provisão dos serviços/produtos oferecidos pela Empresa, de acordo com e sujeito a, prevalecer a lei do Canadá. Qualquer uso da terminologia acima ou outras palavras no singular, plural, capitalização e/ou ele/ela ou eles, são tomadas como intercambiáveis e portanto se referem ao mesmo.`
            },
            {
                title: "Cookies",
                content: `Nós fazemos o uso de cookies. Ao usar o website de GravityBrainInc. você consente ao uso de cookies de acordo com a política de privacidade de GravityBrainInc..A maioria dos websites interativos de hoje em dia usam cookies para nos permitir a recolher detalhes do usuário a cada visita. Cookies são usados em algumas áreas do nosso site para permitir a funcionalidade desta área e facilitar o uso para essas pessoas que viitam. Alguns dos nossos parceiros filiados também podem usar cookies.`
            },
            {
                title: "Licença",
                content: `A não ser que dito o contrário, GravityBrainInc. e/ou seus licensores possuem os direitos de propriedade intelectual para todo o material em GravityBrainInc.. Todos os direitos de propriedade intelectual estão reservados. Você pode ver e/ou imprimir páginas de https://gravitybrain.com/ para seu uso pessoal sujeito a restrições expressas nesses termos e condições.
                          Você não deve:
                          - Republicar material de https://www.gravitybrain.com
                          - Vender, alugar ou sub-licenciar material de https://www.gravitybrain.com
                          - Reproduzir, duplicar ou copiar material de https://www.gravitybrain.com
                          Redistribuir conteúdo de GravityBrainInc. (a não ser que o conteúdo seja especificamente feito para redistribuição).`
            },
            {
                title: "Hyperlinking ao nosso Conteúdo",
                content: `As seguintes organizações podem criar um link ao nosso Web site sem permissão prévia escrita:
                        - Agências governamentais;
                        - Mecanismos de busca;
                        - Organizações de notícias;
                       Distribuidores de diretórios online quando nos listam no diretório podem fazre um link ao nosso Web site na mesma maneira que eles hyperlink para os Web sites de outros negócios listados; e System-wide Accredited Businesses exceto organizações sem fins lucrativos solicitadoras, shoppings de caridade e grupos de caridade de angariação de fundos que não podem hyperlink o nosso Website.
                       Estas organizações podem criar um link à nossa página inicial, para publicações ou outras informações do Web site desde que o link: (a) não seja de nenhuma forma enganoso; (b) não sugira falso patrocínio, apoio ou aprovação da parte linking e seus produtos ou serviços; e (c) encaixe dentro do contexto do site da parte linking&#39;s .
                       Nós podemos considerar e aprovar à nossa discrição outros pedidos de link dos seguintes tipos de organizações:fontes de informações bem conhecidas de consumidores e/ou negócios como Câmaras de Comércio, American Automobile Association, AARP e União dos Consumidores; sites comunitários dot.com; associações ou outros grupos representando caridades, incluindo sites de doação à caridade, distribuidores de diretório online; portais de internet; contabilidade, lei e firmas de consultoria cujos clientes primários sejam negócios; e instituições educacionais e associação de comércio.
                       Nós iremos aprovar pedidos de link destas organizações se determinarmos que: (a) o link não iria refletir desfavoravelmente sobre nós ou nossos negócios autorizados (por exemplo, associações de comércio ou outras organizaçoes representando tipos de negócios inerentemente suspeitos, como oportunidades de trabalhar em casa, não terão permissão de criar um link); (b)a organização não tenha um histórico desfavorável conosco; (c) o benefício para nós da visibilidade associada com o hyperlink seja maior que a ausência do mesmo ; e (d) onde o link esteja no contexto de informação de recursos gerais ou seja consistente com conteúdo editorial em uma newsletter ou produto similar aprofundando a missão da organização. Estas organizações podem criar um link à nossa página inicial, para publicações ou outra informação do Web site desde que o link:(a) não seja de nenhum modo enganoso; (b) não sugira falso patrocínio, apoio ou aprovação da parte linking e seus produtos ou serviços; e (c) se encaixe no contexto do site da parte linking.
                       Se você está entre as organizações listadas no parágrafo 2 acima e está interessado em linking ao nosso website, você deve nos notificar mandando um e-mail para admin@gravitybrain.com. Por favor inclua seu nome, o nome da sua organização, informação de contato (como um número de telefone e/ou endereço de e-mail) assim como a URL do seu site, uma lista de quaisquer URLs das quais você pretende link ao nosso Web site, e uma lista de URL(s) no nosso site que você gostaria de linkar. Permita 2-3 semanas para uma resposta.`
            },
            {
                title:
                        "Organizações aprovadas podem criar um hyperlink para nosso Web site como se segue:",
                content: ` - Pelo uso do nome da nossa empresa; ou
                        - Pelo uso do localizador de recurso uniforme (endereço da Web) sendo linked para; ou
                        - Pelo uso de qualquer outra descrição do nosso Web site ou material sendo linked que faça sentido dentro do contexto e formato de conteúdo do site da parte linking.
                       Nenhum uso do logo de GravityBrainInc. ou outra arte será permitido para linking sem um acordo de licença de marca registrada.`
            },
            {
                title: "Iframes",
                content: `Sem autorização prévia e permissão escrita expressa, você não pode criar molduras ao redor da nossa página da Web ou usar outras técnincas que alterem de qualquer modo a apresntação visual ou aparência do nosso Web site.`
            },
            {
                title: "Reserva de Direitos",
                content: `Nós reservamos o direito a qualquer hora e na sua discrição para pedir que você remova todos os links ou qualquer link em particular ao nosso Web site. Você concorda imediatamente remover todos os links ao nosso Web site após tal pedido. Nós também reservamos o direito de emendar esses termos e condições e sua política de linking a qualquer hora. Ao continuar a usar o link para nosso Web site, você concorda de estar restrito e obedecer estes termos e condições de linking.`
            },
            {
                title: "Remoção de links do nosso website",
                content: `Se você achar qualquer link no nosso Web site ou qualquer web site linked repreensível por qualquer razão, você pode nos contatar sobre isto. Nós iremos considerar pedidos para remover links mas não teremos a obrigação de fazer isto ou responder diretamente a você.
                        Enquanto nós nos esforçamos para nos certificarmos que a informação neste website esteja correta, nós não garantimos sua integridade ou exatidão; nem nos compremetemos a nos certificarmos que o website permaneça disponível ou que o material no website seja atualizado.`
            },
            {
                title: "Obrigatoriedade de Conteúdo",
                content: `Nós não teremos responsabilidade ou obrigação para qualquer conteúdo aparecendo no seu Web site. Você concorda de indenizar e defender-nos contra todas as queixas saindo de ou baseadas no seu Website. Nenhum link(s) pode aparecer em qualquer página no seu Web site ou dentro de qualquer contexto contendo conteúdo ou materiais que podem ser interpretados como calunioso, obsceno ou criminoso, ou que infrinja, ou viole, ou promova a infração ou outra violação de, quaisquer direitos de terceiras partes.`
            }
        ]
    },
    privacyPolicyContent: {
        title: "Política de Privacidade de GravityBrain",
        subTitle: "Política de Privacidade",
        paragraphs: [
            {
                title: "Que informações nós coletamos?",
                content: `Nós coletamos informações de você quando você faz um pedido ou se registra no nosso software.
                          Quando fazeno um pedido ou se registrando no nosso site, conforme correto, você pode precisar dizer seu: nome, endereço de e-mail ou outra informação pessoal. Para a sua segurança, nós não processamos, coletamos, ou armazenamos informações financeiras ou de cartões de crédito diretamente no nosso site, mas sim utilizamos os serviços de fornecedores de terceiras partes como Paypal ou Alipay.  Você também pode escolher visitar nosso site anonimamente.`
            },
            {
                title: "Para que nós usamos a informação?",
                content: `Qualquer informação que nós coletarmos de você pode ser usada em uma das seguintes maneiras:
                          Para personalizar sua experiência (sua informação nos ajuda a responder melhor às suas necessidades individuais)
                          Para melhorar o serviço ao cliente (sua informação nos ajuda a responder mais eficazmente aos seus pedidos de serviço ao consumidor e necessidades de suporte)
                          Para processar transações. (sua informação, seja ela pública ou privada, não será vendida, trocada, transferida ou dada para qualquer outra empresa por qulquer razão que seja, sem seu consentimento, a não ser pelo propósito único de entregar o produto comprado ou serviço pedido)
                          Para mandar emails periódicos. O endereço de email que você fornecer para processamento do pedido, pode ser usado para te mandar informações e atualizações se tratando do seu pedido, além de receber ocasionais notícias, atualizações, informações relacionadas a produtos ou serviços da empres, etc.`
            },
            {
                title: "Como nós protegemos su nformação?",
                content: `Nós implementamos uma variedade de medidas de segurança para manter a segurança da sua informação pessoal quando você faz um pedido ou entra, envia ou acessa sua informação pessoal.
                          Nós oferecemos o uso de um servidor seguro. Todas as informações fornecidas sensíveis/de crédito são transmitidas via tecnologia Secure Socket Layer (SSL) e então encriptada no nosso banco de dados forncedores de Pagamento gateway só para ser acessível por aqueles autorizados com direitos de acesso especial para tais sistemas e necessitam manter a informação confidencial.
                          Transações, coleções e armazenamento da sua informação financeira privada (cartões de crédito, números de segurança social, financeiros, etc.) não será processada ou armazenada nos nossos servidores. Tais transações são feitas diretamente por fornecedores de terceiras partes para a sua proteção e segurança.`
            },
            {
                title: "Nós usamos cookies?",
                content: `Cookies no nosso site são usados para acompanhar e avaliar automação dos estudantes.  Nós também usmos esta informação para processar relatórios que estão disponíveis somente para aqueles autorizados por pais ou escolas.  Além do mais, nós usaremos a informação para acompanhar quais inscrições estão ativas e quais não estão.`
            },
            {
                title: "Nós revelamos informações para partes externas?",
                content: `Nós não vendemos, trocamos ou transferimos para partes externas sua informação pessoalmente identificável. Isto não inclui terceiras partes confiáveis que nos ajudam a operar nosso website, conduzir nossos negócios, ou servir você, desde que essas partes concordem de manter esta informação confidencial. Nós também podemos liberar sua informação quando acreditamos que liberá-la será correto para obedecer a lei, reforçar as políticas do site, ou nos proteger nossos direitos ou de outros, propriedades, ou segurança. No entanto, informações de visitantes não pessoalmente identificáveis podem ser fornecidas para outras partes como marketing, publicidade, ou outros usos.`
            },
            {
                title: "California Online Privacy Protection Act Compliance",
                content: `Porque nós valorizamos sua privacidade nós tomamos as precauções necessárias para estar de acordo com California Online Privacy Protection Act. Nós portanto não liberaremos sua informação pessoal para partes externas sem seu consentimento.`
            },
            {
                title: "Childrens Online Privacy Protection Act Compliance",
                content: `Nós seguimos os requisitos de COPPA (Childrens Online Privacy Protection Act), nós não coletamos qualquer informação de ninguém com menos de 13 anos de idade. Nosso website, produtos e serviços são todos direcionados para pessoas que têm 13 anos ou mais. Pais podem escolher entrar com um pseudônimo para sua criança ou usar seu nome verdadeiro.`
            },
            {
                title: "Informação Privada no nosso sistema de treinamento",
                content: `Você tem a opção de entrar com informações pessoais como nome completo e data de nascimento no nosso software. O propósito desta informação é somente para o seu benefício e você não é obrigado a fazer isto. A informação de data de nascimento é usada só para sugerir cursos no nosso sistema que podem te interessar.`
            },
            {
                title: "Termos e Condições",
                content: `Por favor também visite nossa seção de Termos e Condições estabelecendo o uso, renúncia legal e limitações de responsabilidade a respeito do uso de nosso website em`
            },
            {
                title: "Seu Consentimento",
                content: `Ao usar nosso site, você consente a esta política de privacidade.`
            },
            {
                title: "Mudanças à nossa Política de Privacidade",
                content: `Se nós decidirmos mudar nossa política de privacidade, nós iremos postar as mudanças nesta página.`
            },
            {
                title: "Contate-nos",
                content: `Se há dúvidas sobre esta política de privacidade você pode nos contatar usando a informação abaixo.
                          Box 568
                          Hamiota, Manitoba R0M 0T0
                          CANADA`
            }
        ]
    }
}
