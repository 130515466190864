import React, { PureComponent } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";

class SplashJumbotron extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {     
      overview: false,
      overviewContent: "",
      mainBannerImage: "",
    };

    this.handleOpenOverview = this.handleOpenOverview.bind(this);
    this.handleCloseOverview = this.handleCloseOverview.bind(this);
    this.openPdf = this.openPdf.bind(this);
  }

  componentDidMount() {
    this.setMainBannerImage(0);
  }


  handleOpenOverview() {
    this.setOverViewUrl();
    this.setState({
      overview: true
    });
  }

  handleCloseOverview() {
    this.setState({
      overview: false,
    });
  }

  setMainBannerImage(index) {
    const { type } = this.props;
    const prefix = "//d2cnhr6egzpvdl.cloudfront.net/image/bzabc/";
    const backgroundImages = {
      platform: ["overview_01.jpg"],
      students: ["Parents_Cheer.jpg", "Parents_valentines.jpg"],
      publishers: ["publisers_01.jpg", "publisers_02.jpg"],
      schools: ["teacher_01.jpg", "teacher_02.jpg"],
    };

    if (backgroundImages[type])
      this.setState({
        mainBannerImage: prefix + backgroundImages[type][index],
      });
  }

  setPdfViewUrl() {
    const { type } = this.props;

    const pdfs = {
      publishers:
        "//d2cnhr6egzpvdl.cloudfront.net/PDFs/BZabc_Learning_Platform_Overview.pdf",
    };

    if (pdfs[type]) this.setState({ pdfViewUrl: pdfs[type] });
  }

  openPdf() {
    const { type, i18n } = this.props;

    const language = i18n.language || "en";

    const pdfs = {
      publishers:
        "//d2cnhr6egzpvdl.cloudfront.net/PDFs/BZabc_Learning_Platform_Overview",
    };

    if (pdfs[type]) {
      window.open(pdfs[type] + "_" + language.toUpperCase() + ".pdf");
    }
  }

  setOverViewUrl() {
    const { type, i18n } = this.props;

    const language = i18n.language || "en";

    const overviews = {
      publishers: {
        en: "//youtube.com/embed/qeWbVq_GWZw",
        es: "//youtube.com/embed/qeWbVq_GWZw",
        fr: "//youtube.com/embed/qeWbVq_GWZw",
        pt: "//youtube.com/embed/qeWbVq_GWZw",
        kr: "//youtube.com/embed/qeWbVq_GWZw",
        zh_hans: "//youtube.com/embed/qeWbVq_GWZw",
        zh_hant: "//youtube.com/embed/qeWbVq_GWZw",
      },
    };

    if (overviews[type])
      this.setState({ overviewContent: overviews[type][language] });
  }

  render() {
    const { t } = this.props;

    const {     
      overview,
      overviewContent,
      mainBannerImage
    } = this.state;

    return (
      <div className="main-banner">
        <div
          className="main-banner-background-publisher"
          style={{ backgroundImage: `url(${mainBannerImage})` }}
        >
          <div className="container">
            <img
              src={
                "https://d2cnhr6egzpvdl.cloudfront.net/image/bzabc/BZabc_logo_top.png"
              }
              className="img-logo"
              alt="GravityBrain"
            />
            <div className="row">
              <div className="col multimedia jumbotron-text">
                <h2 className="mt-4 mt-3">{t("pageDescription")}</h2>
                <h4 className="mt-4 mt-3">{t("publishers")}</h4>
                <p>{t("publishersDescription")}</p>
                <h4 className="mt-4 mt-3">{t("contentCreators")}</h4>
                <p>{t("creatorsDescription")}</p>
                <h2 className="mt-4 mt-3">{t("sharingFormulas")}</h2>
                <h4 className="mt-4 mt-3">{t("subscriptions")}</h4>
                <h6 className="mt-4 mt-3">{t("subscriptionsFormula")}</h6>
                <p>{t("subscriptionsDescription")}</p>
                <h4 className="mt-4 mt-3">{t("courseLicenses")}</h4>
                <h6 className="mt-4 mt-3">{t("licensesFormula")}</h6>
                <p>{t("licensesDescription")}</p>
              </div>
              <div className="col publisher-buttons">
                <a
                  className="row"
                  href="https://pubtool.gravitybrain.com/signup"
                >
                  <button className="btn-warning signup-btn">
                    {t("publisherSignup")}
                  </button>
                </a>
                <a
                  className="row"
                  href="https://pubtool.gravitybrain.com/signup/creator"
                >
                  <button className="btn-success signup-btn">
                    {t("creatorSignup")}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          open={overview}
          onClose={this.handleCloseOverview}
          className="youtube-player-dialog"
        >
          <DialogContent className="youtube-player-content">
            <iframe
              title="content"
              src={overviewContent}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </DialogContent>

          <DialogActions>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={this.handleCloseOverview}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default SplashJumbotron;
