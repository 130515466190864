import React, {PureComponent} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LanguageSwitcher from "../../../components/ui/LanguageSwitcher";
import Icon from '@material-ui/core/Icon';
import { NavHashLink } from 'react-router-hash-link';

class SplashHeader extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        drawer: false
      }
      this.toggleDrawer = this.toggleDrawer.bind(this);
    }
    toggleDrawer() {
      const {drawer} = this.state;
      this.setDrawer(!drawer);
    }
    setDrawer(open) {
      this.setState({drawer: open });
    }
    render() {
        const {t} = this.props;        
        const {drawer} = this.state;
        return <header className="splash-header" id="splash-header">        
          <div className="d-flex justify-content-between py-3 mx-3 mx-md-3 mx-lg-4 px-xl-2">
            <div className="left-header py-sm-2 align-items-center">
              <div className="drawer-menu-btn d-flex d-md-none" onClick={()=> {this.toggleDrawer()}}><i className="fa fa-bars"></i> </div>
              <NavLink to={`/`}>
                <img src={'https://pubtool.gravitybrain.com/assets/logo.png'} className="img-logo" alt="GravityBrain"/>
              </NavLink>
            </div>
            <div className="links d-none d-md-flex justify-content-end align-items-center text-right">
              <Link to={`/school-teachers`}>{t('company')}</Link>
              <NavHashLink to={`/school-teachers#tools`}>{t('tools')}</NavHashLink>              
              <NavHashLink to={`/school-teachers#contact-us`} className="d-flex">{t('contactUs')}</NavHashLink>
              <LanguageSwitcher />
            </div>
            <Drawer className="header-drawer" anchor="left" open={drawer} onClose={()=> {this.setDrawer(false)}}>
                <List>
                  <ListItem button>
                    <NavLink to={`/school-teachers`} className="d-flex">
                      <Icon>school</Icon>
                      <ListItemText className="drawer-text" primary={t('company')} />
                    </NavLink>
                  </ListItem>
                  <ListItem button>
                    <NavHashLink to={`/school-teachers#tools`} className="d-flex">
                      <Icon>dashboard</Icon>
                      <ListItemText className="drawer-text" primary={t('tools')} />
                    </NavHashLink>
                  </ListItem>
                  <ListItem button>
                    <NavHashLink to={`/school-teachers#contact-us`} className="d-flex">
                      <Icon>face</Icon>
                      <ListItemText className="drawer-text" primary={t('contactUs')} />
                    </NavHashLink>
                  </ListItem>
                </List>
              </Drawer>
          </div>          
        </header>;
    }
}

export default SplashHeader