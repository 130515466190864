import React from "react";
import { withTranslation } from "react-i18next";
import SplashHeader from "./SplashHeader";
import SplashFooter from "./SplashFooter";
import SchoolsJumbotron from "./SchoolsJumbotron";
import "../../../styles/splash.css";

const SchoolsWrapper = (props) => {
  return (
    <div className="splash">
      <SplashHeader {...props} />
      <section className={`${props.className || "splash-section"}`}>
        {props.showJumbotron && <SchoolsJumbotron {...props} />}        
        <SplashFooter {...props} />
      </section>
    </div>
  );
};

export default withTranslation("schools")(SchoolsWrapper);
