export default {
    home: 'Home',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    kr: '한국어',
    zh_hans: '汉语',
    zh_hant: '漢語',
    pt: 'Portugues',
    freeProduct: '免费',
tools: "工具",
company: "公司",
contactUs: "联系我们",
about: "关于",
login: "登录",
signin: "注册",
signup: "注册",
sendGift: "发送礼物",
subscription: "订阅",
platform: "平台",
parentsStudents: "学生与家长",
schoolsTeachers: "学校与教师",
publishers: "出版商",
copyright: "版权 © 2020 bzabc",
splashOverviewButton: "概览",
aboutUsNote:
        "BZabc由加拿大私人公司GravityBraininc制作，致力于通过现代技术改善儿童在语言和数学培训中的学习成果，利用现代技术来增强而不是替代传统材料和方法。",
welcomeToWebsite: "欢迎来到BZabc",
overview: "概览",
pdf: 'PDF',
usingOurProduct: "使用BZabc",
methods: "方法",
quickstart: "快速开始",
ourMethod: "BZabc方法",
platformOverview: "平台概览",
publishersTab: "出版商",
store: "商店",
privacy: "隐私政策",
terms: "服务条款",
learningPlatform: "学习平台",
learnMore: "了解更多",
productsNotFound: "未找到产品...",
appDownloadCenter: "应用下载中心",
appDownloadCenterDescription: "在使用儿童应用程序之前，学习者必须通过学校或其父母/监护人注册并报名参加课程。",
more: "更多...",
parentsApp: "BZabc家长",
kidsApp: "BZabc儿童",
schoolWebTool: "学校网络工具",
gettingStarted: "入门指南",

// Terms and Conditions //
    termsAndConditions: {
        title: "条款及细则",
        paragraphs: [
            {
                title: "欢迎来到 GravityBrainInc.",
                content: `这些条款和细则概述了使用GravityBrainInc.的规则和规定
                          网站 GravityBrainInc.位于：
                          PO Box 568 Hamiota,
                          Manitoba R0M0T0,
                          加拿大
                          通过访问该网站，我们假设您已完全接受这些条款和条细则。如果您不接受本页上所述的所有条款和条件，请不要继续使用GravityBrainInc.的网站。
                          以下术语适用于这些条款和条件，隐私声明和免责声明以及任何或所有协议：“客户”，“您”和“您的”是指您，即访问本网站并接受公司条款和条件的人。 “公司”，“我们自己”，“我们”和“我们的”是指我们的公司。 “当事人”，“当事人们”或“我们”是指客户和我们自己，或者客户或我们自己。所有条款均指以最适当的方式（无论是通过固定期限的正式会议，还是通过其他任何方式）以明确方式满足客户要求的方式，以最适当的方式向客户提供协助的过程，要约，接受和对付款的考虑。根据并遵守加拿大现行法律，客户在提供公司规定的服务/产品方面的需求。以单数，复数，大写字母和/或他（或她）的形式使用以上术语或其他措词，应视为可互换使用，且是相同的。`
            },
            {
                title: "Cookies",
                content: `我们使用cookie。 通过使用GravityBrainInc.的网站，您同意根据GravityBrainInc.的隐私政策使用cookie。大多数现代互动式网站使用cookie来使我们能够为每次访问检索用户详细信息。 Cookies在我们网站的某些区域中使用，以启用该区域的功能并易于访问的人使用。我们的某些会员合作伙伴也可能使用cookie。`
            },
            {
                title: "执照",
                content: `除非另有说明，否则GravityBrainInc.和/或其许可人拥有GravityBrainInc.上所有资讯的知识产权。保留所有知识产权。在这些条款和条件中设置的限制下，您可以出于个人目的从https://gravitybrain.com/查看和/或打印页面。
                          你不能：
                          - 从https://www.gravitybrain.com重新发布资讯
                          - 从https://www.gravitybrain.com出售，出租或再授权资讯
                          - 从https://www.gravitybrain.com复制，复本或拷贝资讯
                          从GravityBrainInc.重新分发内容（除非内容专门用于重新分发）`
            },
            {
                title: "超连结到我们的内容",
                content: `以下组织可能未经您的许可而连结到的网站：
                        - 政府机构;
                        - 搜索引擎;
                        - 新闻机构;
                       线上目录分发者在目录中列出我们时，可以超连结到其他列出的企业网站且以相同的方式也连结到我们的网站； 以及系统范围内的认可企业，但不能建立非营利组织超连结到我们的网站，慈善购物中心和慈善筹款团体除外。
                       这些组织可以连结到我们的主页，出版物或其他网站信息，只要该连结为：（a）绝对不会引起误解； （b）不虚假暗示连结及其产品或服务的赞助，认可或批准； （ c）符合连结方网站的上下文。
                       我们可以全权酌情考虑并批准以下类型组织的其他连结请求：众所周知的消费者和/或商业信息来源，例如商会，美国汽车协会，AARP和消费者联盟； dot.com社区网站； 代表慈善机构的协会或其他团体，包括慈善捐赠网站，线上目录分发者； 互联网门户； 主要客户为企业的会计，法律和咨询公司； 以及教育机构和行业协会。
                       如果我们确定以下条件，我们将批准来自这些组织的连结请求：（a）连结不会对我们或我们认可的企业产生不利影响（例如，行业协会或代表固有可疑业务类型的其他组织，例如从事工作的公司）在家工作，不得连结）； （b）该组织没有我们的不良记录； （c）与超连结相关联的可见性给我们带来的好处大于对超连结的了解； （d）连结位于一般资源信息的上下文中，或者与通讯或类似产品的社论内容相符，从而进一步推动了该组织的使命。
                       如果您属于上面第二段中列出的组织，并且有兴趣连结到我们的网站，则必须通过发送电子邮件至admin@gravitybrain.com来通知我们。请包括您的姓名，您的组织名称，联系方式（例如电话号码和/或电子邮件地址）以及您的网站的网址，您打算连结到我们网站的所有网址列表， 以及您要连结到的我们网站上的URL列表。等待二至三周以作出回应。`
            },
            {
                title:
                        "认可的组织可以超连结到我们的网站，如下所示：",
                content: ` -使用我们的公司名称； 或是
                        - 通过使用连结到的统一资源定位器（网址）； 或是
                        - 通过使用我们网站的其他描述或连结到的资讯，在连结方网站内容的上下文和格式中是有意义的。
                       在没有商标许可协议的情况下，不得使用GravityBrainInc.的徽标或其他艺术品进行连结。`
            },
            {
                title: "构思",
                content: `未经事先认可和明确的书面许可，您不得在我们的网页周围创建框架或使用其他以任何方式改变网站外观或外观的技术。`
            },
            {
                title: "保留权利",
                content: `我们保留随时自行决定要求您删除所有连结或指向我们网站的任何特定连结的权利。您同意在收到此类请求后立即删除所有指向我们网站的连结。我们也保留随时修改这些条款和细则及其连结政策的权利。继续连结到我们的网站，即表示您同意遵守这些连结条款和细则。`
            },
            {
                title: "从我们的网站上删除连结'",
                content: `如果您发现我们网站上的任何连结或出于任何原因令人反感的连结网站，都可以就此与我们联系。我们将考虑删除连结的请求，但没有义务这样做或直接回复您。
                        我们尽力确保本网站上的信息正确无误，但我们不保证其完整性或准确性； 我们也不保证网站保持可用状态或网站上的资料保持最新。`
            },
            {
                title: "内容承担责任",
                content: `对于您网站上出现的任何内容，我们概不负责。您同意因您的网站或基于您的网站而引起的所有索赔进行赔偿并对我们辩护。任何连结都不会出现在您网站的任何页面上，或在任何上下文中出现，其内容或资讯可能被解释为诽谤，淫秽或犯罪，或侵犯，以其他方式侵犯或主张侵犯或以其他方式侵犯任何内容第三方权利。`
            }
        ]
    },
    privacyPolicyContent: {
        title: "GravityBrain隐私政策",
        subTitle: "隐私政策",
        paragraphs: [
            {
                title: "我们收集哪些信息？",
                content: `当您下订单或在我们的软体上注册时，我们会从您那里收集信息。
                          在我们的网站上订购或注册时，可能会要求您输入您的姓名，电子邮件地址或其他个人信息。为了您的安全，我们不会直接在我们的网站上处理，收集或存储信用卡或财务信息，而是利用第三方提供商的服务，例如Paypal或Alipay。您也可以选择匿名访问我们的网站。`
            },
            {
                title: "我们如何使用您的信息？",
                content: `我们从您那里收集的任何信息都可以通过以下方式之一使用：
                          个人化您的体验（您的信息可帮助我们更好地响应您的个人需求）
                          改善客户服务（您的信息可帮助我们更有效地响应您的客户服务要求和支持需求）
                          处理交易（您的信息无论是公开的还是私人的，未经您的同意，不会出于任何原因出售，交换，转让或提供给任何其他公司，除非是出于交付所要求的购买产品或服务的明确目的）
                          发送定期电子邮件。您提供的用于订单处理的电子邮件地址，除了偶尔接收公司新闻，更新，相关产品或服务信息等之外，还可以用于向您发送与订单有关的信息和更新。`
            },
            {
                title: "我们如何保护您的信息？",
                content: `当您下订单或输入、提交或访问您的个人信息时，我们会采取各种安全措施来维护您的个人信息的安全。
                          我们提供使用安全的服务器。所有提供的敏感/信用信息都是通过安全通讯端层（SSL）技术传输的，然后加密到我们的付款网关提供商数据库中，只有拥有此类系统特殊访问权限的授权人员才能访问，并且需要对该信息保密。
                          您的私人财务信息（信用卡，身分证，财务信息等）的交易，收集和存储将不会在我们的服务器上处理或存储。为了保护和安全，此类交易直接通过第三方提供商进行。`
            },
            {
                title: "我们使用cookie吗？",
                content: `我们网站上的Cookies用于自动化追踪和审查学生的情况。我们还将使用此信息来处理仅由家长或学校授权的可用报告。此外，我们还将使用该信息来跟踪哪些订阅处于活动状态，哪些没有激活。`
            },
            {
                title: "我们是否透露任何信息给其他方？",
                content: `我们不会将您的个人身份信息出售，交易或以其他方式转让给外部各方。这不包括协助我们运营我们的网站，开展我们的业务或为您提供服务的受信任的第三方，只要这些第三方同意对这些信息保密。当我们认为发布适合遵守法律，执行我们的网站政策或保护我们或他人的权利，财产或安全时，我们也可能会发布您的信息。但是，可以将非个人可识别的访客信息提供给其他方以进行营销，广告或其他用途。`
            },
            {
                title: "加州线上隐私保护法合规",
                content: `因为我们重视您的隐私，所以我们已采取必要的预防措施，以符合《加利福尼亚线上隐私保护法》。因此，未经您的同意，我们不会将您的个人信息分发给外部各方。`
            },
            {
                title: "符合《儿童线上隐私保护法》",
                content: `我们符合COPPA（儿童线上隐私保护法）的要求，我们不会从13岁以下的任何人那里收集任何信息。我们的网站、产品和服务均针对至少13岁或以上的人群。家长可以选择为孩子输入一个名字缩写或使用其真实姓名。`
            },
            {
                title: "有关我们培训系统的私人信息",
                content: `您可以选择在我们的软体上输入个人信息，例如全名和生日。此信息的目的仅是为了您的利益，而您也不做也行。生日信息仅用于建议您可能会对您的系统感兴趣的课程。`
            },
            {
                title: "条款和细则",
                content: `另请访问我们的条款和细则部分，以了解使用，免责声明和限制我们网站使用的责任限制，网址为：`
            },
            {
                title: "您的同意",
                content: `使用我们的网站，即表示您同意本隐私政策。`
            },
            {
                title: "更改我们的隐私政策",
                content: `如果我們決定更改我們的隱私政策，我們將在頁面上發布這些更改。`
            },
            {
                title: "联络我们",
                content: `如果对此隐私政策有任何疑问，您可以使用以下信息与我们联系。
                          Box 568
                          Hamiota, Manitoba R0M 0T0
                          加拿大`
            }
        ]
    }
}
