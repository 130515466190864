import translations from "./translations";
export default {
  ...translations,
  giveGiftOfLearning: "Dê o Presente da Aprendizagem",
  professionallyDesignedCourses:
    "Oferece todas as disciplinas acadêmicas\ndo jardim de infância até o sexto ano.",
  pageDescription: "Contas de Usuário da Escola",
  pricipalsAndAdmins: "Diretores e Administradores",
  principalAndAdminsDescription:
    "* Registre-se e registre todos os funcionários, professores e alunos. Use o upload de CSV para processamento rápido e fácil\n* Agrupe alunos e professores em salas\n* Matricule salas inteiras ou indivíduos em nossos cursos publicados, ou crie os seus próprios usando nosso conteúdo ou o seu\n* Veja relatórios de alunos em formato agregado ou individual\n* Utilize o serviço de SMS para criar grupos de mensagens, controlar permissões e se comunicar com os pais.",
  SAAS: "SAAS para Países, Estados ou Províncias",
  SAASDescription:
    "* A empresa-mãe GravityBrain Inc. oferece Software como Serviço de baixo custo para toda nossa plataforma, entre em contato conosco para mais detalhes",
  schoolSignup: "Registro de Escolas",
  contactInfo:
    "email: office@gravitybrain.com\nTelefone: 1-306-570-6488\nSkype: gravity_brain\nCaixa Postal 568 Hamiota, \nR0M 0T0, CANADÁ",

  gravityBrainInc: "GravityBrain Inc.",
  purpose: "Propósito",
  purposeDescription:
    "A GravityBrain vem desenvolvendo Software de Aprendizado Online desde sua fundação em 2007. Nossa missão sempre foi fornecer um serviço que englobe as necessidades de toda a comunidade educacional: alunos, pais, escolas, editoras e agências governamentais. É uma grande responsabilidade.",
  services: "Serviços",
  servicesDescription:
    "Em termos de nossos serviços, oferecemos uma solução única que proporciona treinamento em habilidades em todas as disciplinas principais: idiomas, matemática, estudos sociais, ciências\n* Aprendizado personalizado orientado por dados\n* Ferramentas de relatórios para escolas e pais com insights profundos sobre áreas problemáticas\n* Um sistema de mensagens centralizado leve e baseado em permissões. Isso significa que os pais estão conectados aos professores de seus filhos em um só lugar\n* Ferramentas de publicação que permitem que editoras, artistas ou as próprias escolas publiquem material em nosso sistema, com a capacidade de escolher a distribuição privada ou pública e compartilhar na receita",
  BZabcKidsApp: "Aplicativo BZabc Kids",
  BZabcKidsAppDescription:
    "* As crianças assistem a vídeos animados envolventes e fazem quizzes interativos. O envolvimento é a chave para o aprendizado!\n* Os alunos são guiados pelas lições, sempre na página certa\n* As crianças têm acesso fácil à sua interface com login por imagem-clique\n* Cursos em Inglês e Ortografia disponíveis no momento da escrita, com novos cursos sendo desenvolvidos em Matemática, Espanhol e Francês no momento da escrita.",
  BZabcParentsApp: "Aplicativo BZabc para Pais",
  BZabcParentsAppDescription:
    "* Use para estudo privado ou vinculado à escola\n* Os pais podem se conectar à escola de seus filhos por meio de seu ID BZabc\n* Acesse relatórios e mensagens em tempo real para cursos privados ou escolares\n* A assinatura permite que os pais matriculem alunos em cursos privados",
  schoolPricipalAdmin: "Contas de Usuário da Escola: Diretores e Administradores",
  schoolPrincipalAdminDescription:
    "* Registre-se e registre todos os funcionários, professores e alunos. Use o upload de CSV para processamento rápido e fácil\n* Agrupe alunos e professores em salas\n* Matricule salas inteiras ou indivíduos em nossos cursos publicados, ou crie os seus próprios usando nosso conteúdo ou o seu\n* Veja relatórios de alunos em formato agregado ou individual\n* Utilize o serviço de SMS para criar grupos de mensagens, controlar permissões e se comunicar com os pais",
  teachers: "Professores",
  teachersDescription:
    "* Gerencie o cronograma de cursos/exames\n* Veja relatórios automatizados sobre alunos em grupos ou individualmente\n* Gere boletins de notas manualmente\n* Envie mensagens para alunos e pais individualmente ou em grupos dentro de nosso sistema de SMS\n* Publique seus planos de aula, atividades para a nossa loja e receba comissões sobre as vendas",
  schoolBoardsBandCouncils: "Conselhos Escolares, Conselhos de Bandas",
  schoolBoardBandCouncilsDescription:
    "* Todos os controles escolares com camadas adicionais de permissões\n* Gerenciamento de todas as escolas em sua região\n* Entre em contato conosco para mais informações e suporte",
  publishingAuthoringArtist: "Publicação, Autoria, Artista",
  publishers: "Editoras",
  publishersDescription:
    "* Complemente seus livros de exercícios impressos com aprendizado interativo e orientado por dados publicando na Plataforma de Aprendizado BZabc\n* Ganhe receita com assinaturas e compras de licenças de cursos\n* Continue ganhando receita com materiais impressos por meio de seus canais atuais e/ou por meio de nossa loja online",
  contentCreators: "Criadores de Conteúdo",
  contentCreatorsDescription:
    "* A BZabc agora oferece participação nos lucros para aqueles que desejam contribuir para a série BZabc\n* Publique seus planos de aula, atividades para nossa loja. Receba 80% das vendas, menos descontos e comissões de vendas que você controla\n* Compartilhe seus vídeos de aprendizado, sons e imagens. Sempre que forem usados, você receberá uma comissão",
  SAASCountryStateProvince: "SAAS para Países, Estados ou Províncias",
  SAASCountryStateProvinceDescription:
    "A GravityBrain Inc. oferece Software como Serviço de baixo custo para toda nossa plataforma. Além de todos os recursos acima, oferecemos assistência na configuração do servidor para um sistema robusto, eficiente em custos e seguro. Entre em contato conosco para mais detalhes.",

  AppDownload: "Centro de Download de Aplicativos",
  AppDownloadDescription:
    "Antes de usar o Aplicativo para Crianças, os Alunos devem estar registrados e matriculados\n em cursos pela escola. Consulte nossa documentação ou assista ao vídeo de Introdução para obter detalhes",

  welcomeToWebsite: "Ferramentas Web da Escola BZabc",
  aboutUsNote:
    "Além de oferecer licenças de cursos, a BZabc tem várias Ferramentas Web disponíveis gratuitamente para escolas.",
  splashBannerPoint1: "Registro em massa para alunos e professores",
  splashBannerPoint2: "Matrículas fáceis por sala",
  splashBannerPoint3: "Sistemas de relatórios robustos com detalhes minutados",
  splashBannerPoint4: "Sistema de mensagens em tempo real baseado em permissões",
  LetsStartButton: "Começar",

  BZabcMethod: "BZabc para Escolas",
  practicalSkillDevelopment: "Aprendizado Juntos",
  practicalSkillDevelopmentDescription:
    "Salas com várias aulas, alunos, pais e TAs,... Mantenha tudo organizado com a BZabc. Configuração fácil e gerenciamento de salas, cursos e um sistema de mensagens robusto",
  consistency: "Ensino Aprimorado",
  consistencyDescription:
    "Às vezes, as crianças precisam de um guia; outras vezes, elas precisam trabalhar sozinhas. A BZabc é estruturada para funcionar dentro de uma sala de aula orientada pelo professor, fornecendo melhores insights sobre as necessidades de cada aluno.",
  totalPhysicalResponse: "Vamos se Movimentar!",
  totalPhysicalResponseDescription:
    "As crianças adoram movimento.\nA BZabc incorpora isso no método de Resposta Física Total, o que leva a melhores resultados de aprendizagem.",
  dailyPractice: "Ferramentas Legais para Professores",
  dailyPracticeDescription:
    "Ferramentas de gerenciamento de cursos. Construtores de modelos de aulas e avaliações centradas no aluno.",

  techSpecs: "Especificações Técnicas",
  forAllPlatforms: "Para Todas as Plataformas",
  forAllPlatformsDescription:
    "O Aplicativo para Crianças da BZabc está disponível no Android, iOS e Windows 7 e 10. O Aplicativo para Pais também está disponível em todas as plataformas.",
  blendedLearning: "Aprendizado Híbrido",
  blendedLearningDescription:
    "Os materiais estão conectados entre o software, o conteúdo e os livros, proporcionando continuidade e consistência ao longo do processo de aprendizagem.",
  personalGuidance: "Orientação Personalizada",
  personalGuidanceDescription:
    "O Aplicativo BZabc utiliza o desempenho do aluno para guiá-lo ao longo de seus cursos. O rastreamento e processamento de dados baseados em nuvem significa que, onde e quando eles aprendem, os alunos estão na página certa.",
  trackingReporting: "Rastreamento / Relatórios",
  trackingReportingDescription:
    "O desempenho do aluno é rastreado em nosso sistema. Dados de pontuação e relatórios de erros estão disponíveis em tempo real para os pais. Isso permite que os pais vejam quando os filhos estudaram e quais desafios encontraram.",

  store: "Loja",
  privacy: "Política de Privacidade",
  terms: "Termos de Serviço",

  bottomCourseInfo:
    "Oferece todas as disciplinas acadêmicas do jardim de infância até o sexto ano.",
  ourPlatformInfo: "A Plataforma de Aprendizagem BZabc",

  learningPlatform: "Plataforma de Aprendizagem",
  learnMore: "Saiba mais",
  productsNotFound: "Produtos não encontrados...",
  appDownloadCenter: "Centro de Download de Aplicativos",
  appDownloadCenterDescription:
    "Antes de usar o Aplicativo para Crianças, os Alunos devem estar registrados e matriculados em cursos pela escola ou pelo pai/responsável.",

  parentsApp: "BZabc para Pais",
  kidsApp: "BZabc para Crianças",
};
