export default {
    errors: {
        400: '錯誤請求',
        401: '未受權',
        403: '您沒有正確的權限',
        404: '找不到記錄',
        405: '錯誤的請求。 不允許採取行動......',
        422: '無法處理的數據',
        409: '錯誤請求',
        429: '嘗試太多次。 請稍後再試......',
        500: '似服器發生錯誤。 請稍後再試',
        canceled: '請求已被取消',
        unknown: '未知錯誤。 請稍後再試'
    }
}