import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import {unregister} from './registerServiceWorker';

unregister();
ReactDOM.render((<App />), document.getElementById('root'));

const canHover = !(matchMedia('(hover: none)').matches);

if (canHover) {
  document.body.classList.add('can-hover');
}