export default {
    mainTitle: 'BZabc',
    notFound: '404',
    about: '关于我们',
    parentsStudents: '家长和学生',
    schoolsTeachers: '学校和教师',
    termsAndConditions: '条款和条件',
    methodology: '方法',
    techSpecs: '系统技术规范',
    privacyPolicy: '隐私政策',
    parents: '家长',
    home: 'Home',
}