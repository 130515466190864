import React, {PureComponent} from 'react';
import {Link, NavLink} from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';

class SplashFooter extends PureComponent {
    
    constructor(props) {
        super(props);
        this.state = {                 
            footerPosition: -1000            
        };
    }
  
    componentDidMount() {         
        window.addEventListener('scroll', this.setHeaderPosition.bind(this));    
    }

    componentWillUnmount() {        
        window.removeEventListener('scroll', this.setHeaderPosition.bind(this));
    }  

    setHeaderPosition(){    
        let footer = document.getElementById('footer');
        let header = document.getElementById('main-banner');
        let position = this.state.footerPosition;
        
        if (!footer || !header) {
            return false;
        }
                
        position = window.scrollY - (header.clientHeight + footer.clientHeight);
        
        if (position > 0) {
            position = 0;
        }                    
        this.setState({footerPosition: position});        
    }
    
    render() {
        const {t} = this.props;
        
        return (
            <div id="footer" style={{bottom: this.state.footerPosition}}>
                <div className="splash-footer">   
                    <div className="container footer-container d-flex links">
                        <Link to={`/`}>
                            <img src={'https://pubtool.gravitybrain.com/assets/logo.png'} className="img-logo" alt="GravityBrain"/>
                        </Link>
                        <div className="row ml-5">
                            <div className="mx-auto col-lg-8 col-md-12 align-items-right float-right">
                                <div className="links">
                                    <NavLink to={`/school-teachers`}>{t('company')}</NavLink>
                                    <NavHashLink to={`/school-teachers#tools`}>{t('tools')}</NavHashLink>
                                    <NavHashLink to={`/school-teachers#contact-us`}>{t('contactUs')}</NavHashLink>      
                                </div>     
                            </div>
                            <div className="mx-auto col-lg-4 col-md-6 d-flex align-items-right social-btns float-right">
                                <a href={`https://www.facebook.com/GravityBrain`}><div className="social-btn mr-3"><i className="fa fa-facebook"></i></div></a>
                                <a href={`https://www.youtube.com/channel/UCJA5fXcSruy7cI_q-wAX6Aw`}><div className="social-btn"><i className="fa fa-youtube-play"></i></div></a>
                            </div>
                        </div>
                    </div>
                    <div className="copyright text-center avenir-normal">
                        {t('copyright')} | 
                        <NavLink to={`/privacy-policy`}> {t('privacy')} </NavLink> | 
                        <NavLink to={`/terms`}> {t('terms')}</NavLink>
                    </div>
                </div>
            </div>
        );
    }
}

export default SplashFooter