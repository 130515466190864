import translations from "./translations";
export default {
  ...translations,
  // Titres Principaux //
  giveGiftOfLearning: "Plateforme d'Apprentissage",
  professionallyDesignedCourses:
    "Propose toutes les matières académiques\npour la maternelle jusqu'à la sixième année.",
  pageDescription: "Comptes Utilisateurs Scolaires",
  pricipalsAndAdmins: "Directeurs et Administrateurs",
  principalAndAdminsDescription:
    "* Inscrivez-vous ainsi que tout le personnel, les enseignants et les élèves. Utilisez le téléchargement CSV pour un traitement rapide et facile\n* Regroupez les élèves et les enseignants en classes\n* Inscrivez des classes entières ou des individus dans nos cours publiés, ou créez les vôtres en utilisant notre contenu ou le vôtre\n* Consultez les rapports des élèves en agrégat ou individuellement\n* Utilisez le service SMS pour créer des groupes de messages, gérer les permissions et communiquer avec les parents.",
  SAAS: "SAAS pour Pays, États ou Provinces",
  SAASDescription:
    "* La société mère GravityBrain Inc. propose un service de logiciel en tant que service à faible coût pour toute notre plateforme, veuillez nous contacter pour plus de détails.",
  schoolSignup: "Inscription des Écoles",
  contactInfo:
    "email : office@gravitybrain.com\nTéléphone : 1-306-570-6488\nSkype : gravity_brain\nBoîte Postale 568 Hamiota, \nR0M 0T0, CANADA",

  gravityBrainInc: "GravityBrain Inc.",
  purpose: "Objectif",
  purposeDescription:
    "GravityBrain développe des logiciels d'apprentissage en ligne depuis sa création en 2007. Notre mission a toujours été de fournir un service qui englobe les besoins de toute la communauté éducative : élèves, parents, écoles, éditeurs et organismes gouvernementaux. C'est une mission ambitieuse.",
  services: "Services",
  servicesDescription:
    "En ce qui concerne nos services, nous proposons une solution unique qui offre une formation aux compétences dans toutes les matières fondamentales : langues, mathématiques, sciences sociales, sciences\n* Apprentissage personnalisé basé sur les données\n* Outils de reporting pour les écoles et les parents avec des insights approfondis sur les domaines problématiques\n* Un système de messagerie centralisé léger et basé sur les permissions. Les parents sont ainsi connectés aux enseignants de leurs enfants en un seul endroit\n* Des outils de publication qui permettent aux éditeurs, aux artistes ou aux écoles de publier du matériel sur notre système, avec la possibilité de choisir une distribution privée ou publique, et de partager les revenus.",
  BZabcKidsApp: "Appli BZabc pour Enfants",
  BZabcKidsAppDescription:
    "* Les enfants regardent des vidéos animées captivantes et passent des quiz interactifs. L'engagement est la clé de l'apprentissage !\n* Les élèves sont guidés à travers les leçons, toujours sur la bonne page\n* Les enfants ont un accès facile à leur interface utilisateur avec une connexion par clic sur image\n* Des cours d'anglais et d'orthographe sont disponibles au moment de l'écriture, avec de nouveaux cours en développement en mathématiques, espagnol et français au moment de l'écriture.",
  BZabcParentsApp: "Appli BZabc pour Parents",
  BZabcParentsAppDescription:
    "* Utilisation pour l'étude privée ou le lien scolaire\n* Les parents peuvent se connecter avec l'école de leur enfant via leur identifiant BZabc\n* Accès aux rapports et messages en temps réel pour les cours privés ou scolaires\n* L'abonnement permet aux parents d'inscrire les élèves à des cours privés",
  schoolPricipalAdmin: "Comptes Utilisateurs Scolaires : Directeurs et Administrateurs",
  schoolPrincipalAdminDescription:
    "* Inscrivez-vous ainsi que tout le personnel, les enseignants et les élèves. Utilisez le téléchargement CSV pour un traitement rapide et facile\n* Regroupez les élèves et les enseignants en classes\n* Inscrivez des classes entières ou des individus dans nos cours publiés, ou créez les vôtres en utilisant notre contenu ou le vôtre\n* Consultez les rapports des élèves en agrégat ou individuellement\n* Utilisez le service SMS pour créer des groupes de messages, gérer les permissions et communiquer avec les parents",
  teachers: "Enseignants",
  teachersDescription:
    "* Gérez l'emploi du temps des cours/examens\n* Consultez les rapports automatisés sur les élèves en groupes ou individuellement\n* Générez des bulletins de notes manuels\n* Envoyez des messages aux élèves et aux parents en tant qu'individus ou groupes via notre SMS\n* Publiez vos plans de cours, vos fiches d'exercices, dans notre magasin et recevez des commissions sur les ventes",
  schoolBoardsBandCouncils: "Conseils Scolaires, Conseils de Bande",
  schoolBoardBandCouncilsDescription:
    "* Tous les contrôles scolaires avec des couches de permissions supplémentaires\n* Gestion de toutes les écoles de votre région\n* Contactez-nous pour plus d'informations et de soutien",
  publishingAuthoringArtist: "Publication, Création, Artiste",
  publishers: "Éditeurs",
  publishersDescription:
    "* Complétez vos cahiers d'exercices imprimés avec un apprentissage interactif et basé sur les données en publiant sur la Plateforme d'Apprentissage BZabc\n* Gagnez des revenus sur les abonnements et les achats de licences de cours\n* Continuez à générer des revenus sur les matériaux imprimés par le biais de vos canaux actuels et/ou de notre magasin en ligne",
  contentCreators: "Créateurs de Contenu",
  contentCreatorsDescription:
    "* BZabc propose désormais un partage des bénéfices pour ceux qui souhaitent contribuer à la série BZabc\n* Publiez vos plans de cours, vos fiches d'exercices, dans notre magasin. Recevez 80% des ventes, moins les remises et les commissions sur les ventes que vous contrôlez\n* Partagez vos vidéos d'apprentissage, sons et images. Chaque fois qu'ils sont utilisés, vous recevrez une redevance",
  SAASCountryStateProvince: "SAAS pour Pays, États ou Provinces",
  SAASCountryStateProvinceDescription:
    "GravityBrain Inc. propose un logiciel en tant que service à faible coût pour toute notre plateforme. En plus de toutes les fonctionnalités ci-dessus, nous proposons une assistance à la configuration des serveurs pour un système robuste, rentable et sécurisé. Veuillez nous contacter pour plus de détails.",

  // FIN Titres Principaux //
  // Centre de Téléchargement d'Applications //
  AppDownload: "Centre de Téléchargement d'Applications",
  AppDownloadDescription:
    "Avant d'utiliser l'application pour enfants, les apprenants doivent être inscrits et inscrits\n à des cours par leur école. Veuillez consulter notre documentation ou regarder le film de démarrage pour plus de détails",
  // FIN Centre de Téléchargement d'Applications //
  // La Plateforme d'Apprentissage BZabc //
  welcomeToWebsite: "Outils Web de l'École BZabc",
  aboutUsNote:
    "En plus d'offrir des licences de cours, BZabc dispose de multiples Outils Web disponibles gratuitement pour les écoles.",
  splashBannerPoint1: "Inscription massive pour les étudiants et les enseignants",
  splashBannerPoint2: "Inscriptions de classe faciles par homeroom",
  splashBannerPoint3: "Systèmes de reporting robustes avec des détails minutieux",
  splashBannerPoint4: "Système de messagerie en temps réel basé sur les permissions",
  LetsStartButton: "Commencer",
  // Bouton Commencer pour l'inscription à l'école.  FIN La Plateforme d'Apprentissage BZabc //
  // La Méthode BZabc - Avantages //
  BZabcMethod: "BZabc pour Écoles",
  practicalSkillDevelopment: "Apprentissage Ensemble",
  practicalSkillDevelopmentDescription:
    "Homerooms avec de nombreuses classes, élèves, parents et TAs, ... Gardez tout cela ensemble avec BZabc. Configuration et gestion faciles des homerooms, des cours et d'un système de messagerie robuste",
  consistency: "Enseignement Amélioré",
  consistencyDescription:
    "Parfois, les enfants ont besoin d'un guide ; d'autres fois, ils ont besoin de travailler seuls. BZabc est structuré pour fonctionner dans une salle de classe dirigée par un enseignant, vous offrant de meilleures informations sur les besoins de chaque élève.",
  totalPhysicalResponse: "Bougeons !",
  totalPhysicalResponseDescription:
    "Les enfants aiment bouger.\nBZabc intègre cela dans la méthode de réponse physique totale qui conduit à de meilleurs résultats d'apprentissage.",
  dailyPractice: "Outils Sympas pour les Enseignants",
  dailyPracticeDescription:
    "Outils de gestion de cours. Modèles de planification centrés sur l'étudiant et d'évaluation.",
  // FIN La Méthode BZabc - Avantages //
  // Spécifications Techniques //
  techSpecs: "Spécifications Techniques",
  forAllPlatforms: "Pour Toutes les Plates-formes",
  forAllPlatformsDescription:
    "L'application pour enfants BZabc est disponible sur Android, iOS, et Windows 7 & 10. Notre application pour les parents est également disponible sur toutes les plateformes.",
  blendedLearning: "Apprentissage Mixte",
  blendedLearningDescription:
    "Les matériaux sont connectés entre le logiciel, le contenu et les cahiers d'exercices, assurant une continuité et une cohérence tout au long du processus d'apprentissage.",
  personalGuidance: "Guidance Personnelle",
  personalGuidanceDescription:
    "L'application BZabc utilise les performances des élèves pour les guider tout au long de leurs cours. Le suivi et le traitement des données basés sur le cloud signifient que où et quand ils apprennent, les élèves sont sur la bonne page.",
  trackingReporting: "Suivi / Reporting",
  trackingReportingDescription:
    "Les performances des élèves sont suivies sur notre système. Les scores et les données de rapport d'erreur sont disponibles en temps réel pour les parents. Cela permet aux parents de voir quand les enfants ont travaillé et les défis qu'ils ont pu rencontrer.",
  // FIN Spécifications Techniques //
  // Liens en Bas //
  store: "Magasin",
  privacy: "Politique de Confidentialité",
  terms: "Conditions d'Utilisation",
  // FIN Liens en Bas //
  // Titres en Bas//
  bottomCourseInfo:
    "Propose toutes les matières académiques pour la maternelle jusqu'à la sixième année.",
  ourPlatformInfo: "La Plateforme d'Apprentissage BZabc",
  // FIN Titres en Bas //
  //  Informations Supplémentaires //
  learningPlatform: "Plateforme d'Apprentissage",
  learnMore: "En savoir plus",
  productsNotFound: "Produits Non Trouvés...",
  appDownloadCenter: "Centre de Téléchargement d'Applications",
  appDownloadCenterDescription:
    "Avant d'utiliser l'application pour enfants, les apprenants doivent être inscrits et inscrits à des cours par leur école ou leur parent/tuteur.",
  more: "plus...",
  parentsApp: "BZabc Parents",
  kidsApp: "BZabc Enfants",
};
