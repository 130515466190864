export default {
// Header and main links //
  about: "Acerca de",
  login: "Iniciar sesión",
  signin: "Iniciar sesión",
  signup: "Registrarse",
  sendGift: "Enviar un regalo",
  subscription: "Suscripción",
  publishers: "Editoriales",
  platform: "Plataforma",
  parentsStudents: "Estudiantes y Padres",
  schoolsTeachers: "Escuelas y Profesores",
  subscriptionStartingFrom: "Desde <0>$8.99</0>/mes",
  workBooksAndDownloads: "Libros de texto\ny Descargas",
  bookstore: "Librería",
  ourbookstore: "Nuestra librería",
  overview: "Resumen",
  pdf: "PDF",
// End - Header and main links //
// Top Titles//
  welcomeToGravityBrain: "Elearning for Everyone",
  giveGiftOfLearning: "La Plataforma de Aprendizaje BZabc",
  professionallyDesignedCourses:
    "Idiomas, matemáticas\ne ortografía para tus pequeños",
// END Top Titles //
// App Download Center //
  AppDownload: "Centro de Descargas",
  AppDownloadDescription: "Antes de utilizar la App para Niños, los estudiantes deberán ser registrados \n e inscritos en cursos por su escuela o sus padres.",
// END App Download Center //
// The BZabc Learning Platform //
  welcomeToWebsite: "BZabc: en casa o enlace escolar",
  aboutUsNote:
    "Los padres pueden registrar e inscribir a sus hijos en cursos para estudio en casa, o pueden hacerlo las escuelas, y después el padre puede ligar su cuenta con la de su hijo. La mejor manera de enlazarla tu cuenta con la de tu hijo es iniciar sesión en BZabc.tv con los datos del alumno y agregarte a su cuenta. Esto te dará acceso a los reportes y a tener comunicación con la escuela.",
  splashBannerPoint1: "Uso para estudio en casa o enlazado con la escuela ",
  splashBannerPoint2: "La suscripción permite a los padres inscribir a sus hijos en los cursos",
  splashBannerPoint3: "Reporte en tiempo real para cursos escolares o privados",
  splashBannerPoint4: "Mensajería y reportes en tiempo real",
  LetsStartButton: "Let's Start",
// END The BZabc Learning Platform //
// BZabc Kids App //
  BZabcMethod: "La App BZabc Kids",
  practicalSkillDevelopment:
    "iOS. Android. Cuando sea. Donde sea.",
  practicalSkillDevelopmentDescription:
    "Luego de que los padres o las escuelas inscriban al alumno, la App BZabc Kids puede ser descargada en cualquier dispositivo móvil, sin restricción para ser utilizada en cualquier lugar con una conexión a internet.",
  consistency: "Deleite visual",
  consistencyDescription:
    "BZabc ofrece videotutoriales animados seguidos de una breve prueba en cada lección. ¡Los niños motivados tienen las mayores probabilidades de aprender!",
  totalPhysicalResponse: "Los zapatos de baile son opcionales",
  totalPhysicalResponseDescription:
    "Cada suscripción incluye canciones de acción, las cuales siguen el método de Respuesta Física Total del Profesor James Asher.",
  dailyPractice: "Continuidad: práctica diaria",
  dailyPracticeDescription:
    "Dedica algunos minutos cada día a los cursos de BZabc. La constancia es esencial en el proceso de aprendizaje. Una vez configurado su curso, los niños pueden trabajar por sí solos con nuestra guía automática y el inicio de sesión en un clic.",
// END BZabc Kids App //
// Tech Specs //
  techSpecs: "Especificaciones técnicas",
  forAllPlatforms: "Para todas las plataformas",
  forAllPlatformsDescription:
    "La App BZabc Kids está disponible en Android, iOS y Windows 7 y 10. Nuestra App para Padres, BZabc Parents, también está disponible en todas las plataformas.",
  blendedLearning: "Aprendizaje integral",
  blendedLearningDescription:
    "Los materiales están conectados entre software, contenido y libros de texto, ofreciendo continuidad y consistencia en todo el proceso educativo.",
  personalGuidance: "Orientación personal",
  personalGuidanceDescription:
    "La App BZabc utiliza el desempeño de los estudiantes para guiarlos a través de sus cursos. El monitoreo y procesamiento de datos en la nube garantiza que donde sea y dondequiera que aprendan, los estudiantes estarán en la página correcta.",
  trackingReporting: "Monitoreo / Reporte",
  trackingReportingDescription:
    "El desempeño estudiantil es monitoreado en nuestro sistema. Los datos sobre puntajes y errores están disponibles en tiempo real para los padres. Esto les permite saber cuándo trabajaron sus hijos y los retos con los que se enfrentaron.",
// END Tech Specs //
// Bottom Titles//
  bottomCourseInfo:
    "Idiomas, matemáticas e ortografía para tus pequeños",
  ourPlatformInfo: "La Plataforma de Aprendizaje BZabc",
// END Bottom Titles //
// Bottom Links //
  store: "Tienda",
  privacy: "Política de Privacidad",
  terms: "Términos del Servicio",
 // END Bottom Links //
//Additional Info //
  learningPlatform: "Plataforma de Aprendizaje",
  learnMore: "Conoce más",
  productsNotFound: "Productos no encontrados...",
  appDownloadCenter: "Centro de descargas",
  appDownloadCenterDescription: "Antes de usar la App BZabc Kids, los estudiantes deberán haber sido registrados e inscritos en cursos por sus padres o su escuela.",
  more: "más...",
  parentsApp: "BZabc Parents",
  kidsApp: "BZabc Kids",
// END Additional Info //
};
