export default {
// Header and main links //
  about: "关于",
  login: "注册",
  signin: "登入",
  signup: "报名",
  sendGift: "送礼物",
  subscription: "订阅",
  publishers: "出版商",
  platform: "平台",
  parentsStudents: "学生和家长",
  schoolsTeachers: "学校和老师",
  subscriptionStartingFrom: "开始 <0>$8.99</0>/月",
  workBooksAndDownloads: "作业本&下载",
  bookstore: "书店",
  ourbookstore: "我们的书店",
  overview: "概述",
  pdf: "PDF",
// End - Header and main links //
// Top Titles//
  welcomeToGravityBrain: "Elearning for Everyone",
  giveGiftOfLearning: "BZabc 学习平台",
  professionallyDesignedCourses:
    "针对年轻学习者的拼字、数学以及语言",
// END Top Titles //
// App Download Center //
  AppDownload: "应用程式下载",
  AppDownloadDescription: "在使用Kids App之前，学习者必须由其学校或父母/监护人注册并报名参加课程。",
// END App Download Center //
// The BZabc Learning Platform //
  welcomeToWebsite: "BZabc个人学习或学校连结",
  aboutUsNote:
    "家长可以要求孩子参加家庭学习课程，也可以由学校要求，然后家长可以连结到孩子的帐户。连结孩子的帐户最好的方法是使用孩子的凭据线上登录BZabc.tv并将自己添加到他们的帐户中。这将使您能够访问他们的报告以及学校的讯息。",
  splashBannerPoint1: "用于个人学习或学校连结",
  splashBannerPoint2: "订阅可使家长注册学生课程",
  splashBannerPoint3: "个人或学校课程的及时报告",
  splashBannerPoint4: "即时报告和讯息",
  LetsStartButton: "开始吧",
// END The BZabc Learning Platform //
// BZabc Kids App //
  BZabcMethod: "BZabc儿童 App",
  practicalSkillDevelopment:
    "iOS. Android. 无论时间、地点",
  practicalSkillDevelopmentDescription:
    "一旦家长或学校注册了课程，BZabc儿童 App可以不受限制地安装在任何移动设备上，并可以在任何具有互联网连接的地方使用。 ",
  consistency: "Eye Candy",
  consistencyDescription:
    "BZabc提供动画教学电影，然后每节课都有一个简短的测验。孩子拥有最好的学习改变！",
  totalPhysicalResponse: "Dancing Shoes Optional",
  totalPhysicalResponseDescription:
    "每次订阅都包含动作歌曲，因为詹姆斯·阿舍（James Asher）教授采用了“全身反应”方法 ",
  dailyPractice: "Continuity: 每日練習",
  dailyPracticeDescription:
    "每天在您的每个BZabc课程上花费几分钟。一致性对于您的学习道路上很重要。设置完成后，孩子们可以通过我们的自动指导和一键式登入轻松地独自作业。",
// END BZabc Kids App //
// Tech Specs //
  techSpecs: "技术规格",
  forAllPlatforms: "对于所有平台",
  forAllPlatformsDescription:
    "BZabc儿童 APP可在Android，iOS以及Windows 7和10上使用。我们的家长APP也可在所有平台上使用。",
  blendedLearning: "混合式学习",
  blendedLearningDescription:
    "资料连接在软体，内容和作业簿之间，从而在整个学习过程中保持连续性和一致性。",
  personalGuidance: "个人指导",
  personalGuidanceDescription:
    "BZabc APP利用学生的表现来指导他们整个课程。云端数据的追踪和处理意味着无论何时何地学习，学生都会在正确的页面上",
  trackingReporting: "追踪/报告",
  trackingReportingDescription:
    "在我们的系统上追踪学生的表现。分数和错误报告数据可即时提供给家长。这使家长可以看到孩子何时作业以及他们可能面临的任何挑战。",
// END Tech Specs //
// Bottom Titles//
  bottomCourseInfo:
    "针对您孩子的拼写、数学和语言",
  ourPlatformInfo: "BZabc学习平台",
// END Bottom Titles //
// Bottom Links //
  store: "商店",
  privacy: "隐私政策",
  terms: "服务条款",
 // END Bottom Links //
//Additional Info //
  learningPlatform: "学习平台",
  learnMore: "了解更多",
  productsNotFound: "找不到产品...",
  appDownloadCenter: "应用程式下载",
  appDownloadCenterDescription: "在使用Kids App之前，学习者必须由其学校或父母/监护人注册并报名参加课程。",
  more: "更多...",
  parentsApp: "BZabc 家长",
  kidsApp: "BZabc 儿童",
// END Additional Info //
};
