export default {
    home: 'Home',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    kr: '한국어',
    zh_hans: '汉语',
    zh_hant: '漢語',
    pt: 'Portugues',
    freeProduct: 'GRÁTIS!',
    tools: "Ferramentas",
    company: "Empresa",
    contactUs: "Contate-nos",
    about: "Sobre",
    login: "Login",
    signin: 'Entrar',
    signup: "Registrar-se",
    sendGift: "Enviar um Presente",
    subscription: "Assinatura",
    platform: "Plataforma",
    parentsStudents: "Estudantes e Pais",
    schoolsTeachers: "Escolas e Professores",
    publishers: "Editores",
    copyright: "Direitos Autorais © 2020 bzabc",
    splashOverviewButton: "Visão Geral",
    aboutUsNote:
            "BZabc é produzido pela GravityBraininc, uma corporação privada canadense, dedicada a melhorar os resultados de aprendizagem para crianças em treinamento de linguagem e matemática utilizando tecnologia moderna para alavancar, não substituir, materiais e métodos tradicionais.",
    welcomeToWebsite: "Bem-vindo ao BZabc",
    overview: "Visão Geral",
    pdf: 'PDF',
    usingOurProduct: "Usando o BZabc",
    methods: "Métodos",
    quickstart: "Início Rápido",
    ourMethod: "O Método BZabc",
    platformOverview: "Visão Geral da Plataforma",
    publishersTab: "Editores",
    store: "Loja",
    privacy: "Política de Privacidade",
    terms: "Termos de Serviço",
    learningPlatform: "Plataforma de Aprendizado",
    learnMore: "Saiba mais",
    productsNotFound: "Produtos não encontrados...",
    appDownloadCenter: "Centro de Download do App",
    appDownloadCenterDescription: "Antes de usar o App Kids, os alunos devem estar registrados e matriculados em cursos por sua escola ou pelo responsável.",
    more: "mais...",
    parentsApp: "Pais do BZabc",
    kidsApp: "Crianças do BZabc",
    schoolWebTool: "Ferramenta Web Escolar",
    gettingStarted: "Começando",
// Terms and Conditions //
    termsAndConditions: {
        title: "Términos y Condiciones",
        paragraphs: [
            {
                title: "Bienvenido a GravityBrainInc.",
                content: `Estos términos y condiciones describen las reglas para el uso del sitio web de GravityBrain, Inc.
                          GravityBrainInc. se ubica en:
                          PO Box 568 Hamiota,
                          Manitoba R0M0T0,
                          CANADÁ.
                          Al acceder a este sitio web, asumimos que aceptas estos términos y condiciones en su totalidad. No continúes utilizando el sitio web de GravityBrain, Inc. si no aceptas todos los términos y condiciones estipulados en esta página.
                          La siguiente terminología aplica a los Términos y Condiciones, la Política de Privacidad, el Aviso y cualquier otro Acuerdo: "Cliente","Tú" y "Tu" se refieren a ti, la persona utilizando este sitio web y aceptando los Términos y Condiciones de la Compañía. "Compañía","Nosotros" y "Nuestro" ser refieren a nuestra Compañía. "Partes" se refiere tanto al Cliente como a nosotros. Todos los términos se refieren a la oferta, aceptación y consideración del pago necesario para llevar a cabo el proceso de nuestra asistencia al Cliente de la manera más apropiada, ya sea mediante juntas formales de una duración específica o cualquier otro medio, para el propósito explícito de satisfacer las necesidades del Cliente con respecto a la provisión de los servicios y productos especificados de la Compañía, de acuerdo con y sujeto a la ley prevaleciente en Canadá. Cualquier uso de la terminología antes mencionada u otras palabras en el singular, plural, capitalización y/o él/ella, ellos/ellas, se consideran intercambiables y por ende se refieren a lo mismo.`
            },
            {
                title: "Cookies",
                content: `Utilizamos cookies. Al usar el sitio de GravityBrainInc., otorgas tu consentimiento al uso de cookies de acuerdo con la Política de Privacidad de GravityBrainInc. La mayoría de los sitios web interactivos modernos utilizan cookies para obtener detalles del usuario para cada visita. Las cookies se utilizan en algunas áreas de nuestro sitio para habilitar la funcionalidad del área y facilitar el uso de los visitantes. Algunos de nuestros socios afiliados también pueden utilizar cookies.`
            },
            {
                title: "Licencia",
                content: `A menos que se indique o contrario, GravityBrainInc. y/o sus licenciatarios poseen los derechos de propiedad intelectual para todo el material disponible en GravityBrain, Inc. Todos los derechos de propiedad intelectual están reservados. Puedes consultar y/o imprimir páginas de https://gravitybrain.com/ para uso personal sujeto a las restricciones establecidas en estos Términos y Condiciones.
                          Está prohibido:
                          - Republicar material de https://www.gravitybrain.com
                          - Vender o rentar material de https://www.gravitybrain.com
                          - Reproducir, duplicar o copiar material de https://www.gravitybrain.com
                          Redistribuir contenido de GravityBrain, Inc. (a menos que el contenido sea creado específicamente para redistribución).`
            },
            {
                title: "Enlace a nuestro contenido",
                content: `Las siguientes organizaciónes podrán enlazarse a nuestro sitio web sin autorización previa por escrito:
                        - Agencias gubernamentales;
                        - Motores de búsqueda;
                        - Organizaciones periodísticas;
                       Distribuidores de directorios en línea (cuando nos enlisten en el directorio) pueden enlazarse a nuestro sitio web de la misma manera en la que enlazarían los sitios web de otros negocios anunciados; y negocios acreditados en todo el sistema excepto organizaciones sin fines de lucro, centros comerciales de caridad y grupos recaudadores de fondos caritativos que no podrán enlazarse a nuestro sitio web.
                       Estas organizaciones podrán enlazarse a nuestra página de inicio, a publicaciones o a otra información del sitio siempre y cuando el enlace: (a) no sea engañoso; (b) no sugiera un falso patrocinio o aprobación de la parte enlazadora y sus productos o servicios; y (c) quepa en el contexto del sitio de la parte enlazadora.
                       Podremos considerar y autorizar bajo nuestra propia discreción otras solicitudes de enlace de los siguientes tipos de organizaciones: fuentes comúnmente conocidas de información para consumidores y/o negocios tales como Cámaras de Comercio, la AAA, AAARP y la Consumers Union; sitios comunitarios punto.com; asociaciones u otros grupos representantes de caridades, incluyendo sitios caritativos, distribuidores de directorios en línea; portales de internet; firmas de contaduría, servicios legales y consultoría con negocios como clientes primarios; e instituciones educativas y asociaciones comerciales.
                       Aprobaremos las solicitudes de enlace de tales organizaciones si determinamos que: (a) el enlace no impactaría negativamente en la imagen de nuestro negocio acreditado (por ejemplo, asociaciones comerciales u otras organizaciones que representen tipos de negocio inherentemente sospechosos, tales como oportunidades de trabajo en casa); (b) la organización no cuenta con un historial insatisfactorio con nosotros; (c) el beneficio obtenido por nosotros de la visibilidad asociada con el enlace supera la ausencia del mismo; y (d) cuando el enlace esté en el contexto de la información general o sea consistente con el contenido editorial de un boletín o producto similar que comunique la misión de la organización. Estas organizaciones podrán enlazarse a nuestra página de inicio, a publicaciones o a otra información del sitio web siempre y cuando el enlace: (a) no sea engañoso; (b) no sugiera un falso patrocinio o aprobación de la parte enlazadora y sus productos o servicios; y (c) quepa en el contexto del sitio de la parte enlazadora.
                       Si te encuentras dentro de las organizaciones enlistadas en el párrafo 2 y estás interesado en enlazarte a nuestro sitio web, deberás notificarnos enviando un correo a admin@gravitybrain.com. Por favor incluye tu nombre, el nombre de tu organización, tu información de contacto y la URL de tu sitio, una lista de cualquier URL desde la que pretendas enlazar a nuestro sitio y la lista de URLs en nuestro sitio que deseas enlazar. Recibirás respuesta dentro de 2 a 3 semanas.`
            },
            {
                title:
                        "Las organizaciones autorizadas podrán enlazarse a nuestro sitio web conforme a lo siguiente:",
                content: ` - Mediante el uso de nuestro nombre corporativo; o
                        - Mediante el uso del localizador de recursos uniformes (dirección web) a la que se está enlazando; o
                        - Mediante el uso de cualquier otra descripción de nuestro sitio web o recurso material enlazado que haga sentido dentro del contexto y formato del contenido en el sitio web de la parte enlazadora.
                       No se permitirá el uso del logo de GravityBrain, Inc. u otro gráfico para enlazar un acuerdo de licencia de marca registrada.`
            },
            {
                title: "Marcos",
                content: `Sin autorización previa por escrito, no podrás crear marcos alrededor de nuestros sitios web o utilizar otras técnicas que alteren la presentación visual o apariencia de nuestro sitio web.`
            },
            {
                title: "Reservación de Derechos",
                content: `Nos reservamos el derecho en cualquier momento y bajo nuestra propia discreción de solicitar el retiro de todos los enlaces o cualquier enlace particular a nuestro sitio web. Tú aceptas retirar inmediatamente todos los enlaces a nuestro sitio web ante tal solicitud. También nos reservamos el derecho de corregir estos términos y condiciones y su política de enlace en cualquier momento. Al continuar enlazando a nuestro sitio web, aceptas estar sujeto a y obedecer estos términos y condiciones.`
            },
            {
                title: "Retiro de enlaces de nuestro sitio web",
                content: `Si encuentras algún enlace en nuestro sitio web o algún sitio web enlazado que sea cuestionable por cualquier razón, podrás contactarnos al respecto. Consideraremos las solicitudes para retirar enlaces, pero no estaremos obligados a hacerlo o a responderte directamente.
                        Aunque nos esforzamos para garantizar que la información en este sitio sea correcta, no garantizamos que sea completa o precisa; tampoco nos comprometemos a asegurar que el sitio permanezca disponible o que el material en el sitio se mantenga actualizado.`
            },
            {
                title: "Responsabilidad de Contenido",
                content: `No tendremos responsabilidad por el contenido que aparezca en tu sitio web. Tú aceptas indemnizarnos y defendernos contra cualquier reclamación proveniente de o basada en tu sitio web. Ningún enlace podrá aparecer en ninguna página de tu sitio web o dentro de cualquier contexto que contenga contenido o materiales que puedan ser interpretados como difamatorios, obscenos o criminales, o que infrinja, viole o promueva la infracción u otra violación de los derechos de un tercero.`
            }
        ]
    },
    privacyPolicyContent: {
        title: "Política de Privacidad de GravityBrain",
        subTitle: "Política de Privacidad",
        paragraphs: [
            {
                title: "¿Qué información recolectamos?",
                content: `Recolectamos tu información cuando haces una orden o te registras en nuestro software.
                          Al ordenar o registrarte en nuestro sitio, conforme sea apropiado, es posible que se te solicite ingresar tu: nombre, correo u otra información personal. Para tu seguridad, no procesamos, recolectamos o almacenamos información financiera o de tarjetas de crédito directamente en nuestro sitio, sino que utilizamos los servicios de proveedores terceros como PayPal o Alipay. También puedes optar por visitar nuestro sitio de manera anónima.`
            },
            {
                title: "¿Para qué utilizamos tu información?",
                content: `Cualquier información tuya que recolectemos podrá ser utilizada de una de las siguientes maneras:
                          Para personalizar tu experiencia (tu información nos ayuda a responder de mejor manera a tus necesidades individuales)
                          Para mejorar el servicio al cliente (tu información nos ayuda a responder de manera más efectiva a tus solicitudes de soporte)
                          Para procesar transacciones (tu información, ya sea pública o privada, no será vendida, intercambiada, transferida o proporcionada a alguna otra compañía por ninguna razón, sin tu consentimiento, excepto con el propósito explícito de otorgar el producto adquirido o el servicio solicitado)
                          Para enviar correos periódicos. La dirección de correo que proporciones para procesar órdenes podrá ser utilizada para enviarte información y actualizaciones pertinentes a tu orden, además de que ocasionalmente podrás recibir noticias de la empresa, información de servicios o productos relacionados, etc.`
            },
            {
                title: "¿Cómo protegemos tu información?",
                content: `Implementamos diversas medidas de seguridad para mantener la seguridad de tu información personal cuando haces una orden o ingresas, envías o accedes a tu información personal.
                          Ofrecemos el uso de un servidor seguro. Toda la información sensible/de crédito es transmitida vía tecnología SSL y posteriormente es encriptada en las bases de datos de nuestros proveedores de servicios de pagos, en donde podrá ser consultada sólo por aquellos autorizados con derechos de acceso especial a tales sistemas, quienes deberán mantener la información confidencial.
                          Las transacciones, la recolección y el almacenamiento de tu información financiera personal (tarjetas de crédito, números de seguridad social, etc.) no serán procesados o almacenados en nuestros servidores. Tales transacciones se llevan a cabo directamente a través de proveedores terceros para tu seguridad y protección.`
            },
            {
                title: "¿Utilizamos cookies?",
                content: `Las cookies en nuestro sitio se utilizan para monitorear y revisar la automatización para los estudiantes. También utilizamos esta información para procesar reportes que quedan disponibles sólo para aquellos autorizados por los padres o las escuelas. Por ende, también utilizaremos la información para monitorear cuáles suscripciones están activas y cuáles no.`
            },
            {
                title: "¿Revelamos información a terceras partes?",
                content: `No vendemos, intercambiamos o transferimos tu información personal a terceras partes. Esto no incluye a terceras partes de confianza que nos brinden asistencia en la operación del sitio, la conducción de nuestro negocio o en el servicio que te ofrecemos, siempre y cuando tales terceras partes acepten mantener esta información confidencial. También podremos divulgar tu información cuando consideremos que la divulgación sea apropiada para cumplir con la ley, aplicar las políticas de nuestro sitio o proteger los derechos, la propiedad o la seguridad de nosotros y de otros. Sin embargo, la información no relacionada con identificación personal de los visitantes podrá ser proporcionada a terceras partes para propósitos de publicidad.`
            },
            {
                title: "Cumplimiento con la Ley de Protección de Privacidad en Línea de California",
                content: `Dado que valoramos tu privacidad, hemos tomado las precauciones necesarias para cumplir con la Ley de Protección de Privacidad en Línea del estado de California. Por ende, no distribuiremos tu información personal a terceras partes sin tu consentimiento.`
            },
            {
                title: "Cumplimiento con la Ley de la Protección de la Privacidad de Niños en Línea",
                content: `Cumplimos con los requerimientos de la Ley COPPA. Por lo tanto, no recolectamos información de personas de 13 años o menos. Nuestro sitio, nuestros productos y servicios están dirigidos a personas con al menos 13 años de edad. Los padres pueden ingresar un seudónimo para sus hijos o utilizar su nombre real.`
            },
            {
                title: "Información Privada en nuestro sistema de entrenamiento",
                content: `Cuentas con la opción de ingresar tu información personal en nuestro sitio, tal como nombre completo y fecha de nacimiento. El propósito de esta información es solamente tu beneficio, y no estás obligado a hacerlo. La información sobre fecha de nacimiento sólo se utiliza para sugerir cursos en nuestro sistema que podrían ser de tu interés.`
            },
            {
                title: "Términos y Condiciones",
                content: `Por favor consulta también nuestra sección de Términos y Condiciones, la cual establece el uso, los avisos y los límites de responsabilidad que gobiernan el uso de nuestro sitio en`
            },
            {
                title: "Tu Consentimiento",
                content: `Al usar nuestro sitio, aceptas esta política de privacidad.`
            },
            {
                title: "Cambios en nuestra Política de Privacidad",
                content: `Si decidimos modificar nuestra política de privacidad, anunciaremos tales cambios en esta página..`
            },
            {
                title: "Cómo Contactarnos",
                content: `Si tienes preguntas acerca de esta política de privacidad, puedes contactarnos a:.
                          Box 568
                          Hamiota, Manitoba R0M 0T0
                          CANADÁ`
            }
        ]
    }
}
