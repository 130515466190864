export default {
    mainTitle: "BZabc",
    notFound: "404",
    about: "소개",
    parentsStudents: "학부모& 학생",
    schoolsTeachers: "학교 & 선생님",
    termsAndConditions: "이용 약관",
    methodology: "방식",
    techSpecs: "기술 사양",
    privacyPolicy: "개인 정보 정책",
    parents: "학부모",
    home: 'Home',
}