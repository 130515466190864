export default {
// Header and main links //
  about: "Sobre",
  login: "Login",
  signin: 'Entre',
  signup: "Crie uma Conta",
  sendGift: "Mande um Presente",
  subscription: "Inscrição",
  publishers: "Editores",
  platform: "Plataforma",
  parentsStudents: "Estudantes & Pais", 
  schoolsTeachers: "Escolas & Professores",
  subscriptionStartingFrom: "Começando em <0>$8.99</0>/mês",
  workBooksAndDownloads: "Livros de Exercícios &\nDownloads",
  bookstore: "Livraria",
  ourbookstore: "Nossa Livraria",
  overview: "Visão Geral",
  pdf: "PDF",
// End - Header and main links //
// Top Titles//
  welcomeToGravityBrain: "Elearning for Everyone",
  giveGiftOfLearning: "A Plataforma de Aprendizado BZabc",
  professionallyDesignedCourses:
    "Soletração, matemática e idiomas\npara os seus pequenos",
// END Top Titles //	
// App Download Center //
  AppDownload: "Centro de Download do App",
  AppDownloadDescription: "Antes de usar o Kids App, Aprendizes devem estar registrados e matriculados \n em cursos ou pela sua escola ou seus pais/responsável.",
// END App Download Center //
// The BZabc Learning Platform //
  welcomeToWebsite: "BZabc Estudo Privado ou Link Escolar",
  aboutUsNote:
    "Pais podem matricular crianças em cursos para educação caseira, ou as escolas podem fazer isto e então o responsável pode se conectar à conta da criança. O link para a conta da sua criança, o melhor jeito é fazer login em BZabc.tv online com as credenciais da sua criança e se adicionar à sua conta. Isto te dará acesso aos seus relatórios assim como mensagens da escola.",
  splashBannerPoint1: "Uso para estudo privado ou link escolar",
  splashBannerPoint2: "Inscrição permite aos pais matricular estudantes no curso",
  splashBannerPoint3: "Relatório em tempo real para cursos particulares ou escolares",
  splashBannerPoint4: "Relatórios & mensagens em tempo real",
  LetsStartButton: "Vamos Começar",
// END The BZabc Learning Platform //
// BZabc Kids App //
  BZabcMethod: "O BZabc Kids App",
  practicalSkillDevelopment:
    "iOS. Android. Em qualquer lugar. A qualquer hora.",
  practicalSkillDevelopmentDescription:
    "Uma vez matriulado em cursos pelos pais ou escola, o BZabc Kids App pode ser instalado em qualquer dispositivo móvel sem restrição e usado em qualquer lugar com conexão à internet. ",
  consistency: "Eye Candy",
  consistencyDescription:
    "BZabc oferece filmes tutoriais animados seguidos por um breve teste para cada aula. Crianças engajadas têm a melhor chance de aprender!",
  totalPhysicalResponse: "Sapatos de Dança Opcional",
  totalPhysicalResponseDescription:
    "Músicas de ação são incluídas em cada inscrição, assim como adotado o método Resposta Física Total pelo Professor James Asher ",
  dailyPractice: "Continuidade: Prática Diária",
  dailyPracticeDescription:
    "Passe alguns minutos por dia em cada um dos seus cursos BZabc. Consistência é vital no seu caminho ao aprendizado. Uma vez logadas, as crianças podem facilmente trabalhar sozinhass com nosso guia automático e login de um clique só.",
// END BZabc Kids App //
// Tech Specs //
  techSpecs: "Especificações de Tecnologia",
  forAllPlatforms: "Para Todas As Plataformas",
  forAllPlatformsDescription:
    "O BZabc Kids App está disponível para Android, iOS, e Windows 7 & 10.  Nosso Parents App também está disponível em todas as plataformas.",
  blendedLearning: "Aprendizado Mesclado",
  blendedLearningDescription:
    "Materiais são conectados entre software, conteúdo e livros de exercícios, fornecendo continuidade e consistência pelo processo de aprendizado.",
  personalGuidance: "Guia Pessoal",
  personalGuidanceDescription:
    "O BZabc App utiliza a performance dos estudantes para guiá-los pelos seus cursos. Acompanhamento e processamento de dados com base na nuvem significa que em qualquer hora e em qualquer lugar que eles aprendam, os estudantes estão sempre na página correta.",
  trackingReporting: "Acompanhamento / Relatórios",
  trackingReportingDescription:
    "A performance dos estudantes é acompanhada no nosso sistema. Relatórios de notas e dados de erro estão disponíveis em tempo real para os pais. Isto permite aos pais verem quando as crianças trabalharam e quaisquer desafios que eles podem ter encontrado.",
// END Tech Specs //
// Bottom Titles//
  bottomCourseInfo:
    "Soletração, matemática e idiomas para os seus pequenos",
  ourPlatformInfo: "A Plataforma de Aprendizado BZabc",
// END Bottom Titles //
// Bottom Links //  
  store: "Loja",
  privacy: "Política de Privacidade",
  terms: "Termos de Serviço",
 // END Bottom Links //
//  Additional Info //
  learningPlatform: "Plataforma de Aprendizado",
  learnMore: "Saiba mais",
  productsNotFound: "Produtos Não Encontrados...",
  appDownloadCenter: "Centro de Download do App",
  appDownloadCenterDescription: "Antes de usar o Kids App, Aprendizes devem estar registrados e matriculados em cursos ou por suas escolas ou por seus pais/responsável.",
  more: "mais...",
  parentsApp: "BZabc Pais",
  kidsApp: "BZabc Kids",
// END Additional Info //
};
