export default {
    errors: {
        400: 'Error en la Solicitud',
        401: 'No Autorizado',
        403: 'No tienes los permisos adecuados',
        404: 'No se encontró el registro',
        405: 'Error en la Solicitud. Acción no Permitida...',
        422: 'Datos no procesables',
        409: 'Error en la Solicitud',
        429: 'Demasiados Intentos. Por favor intenta más tarde...',
        500: 'Error del servidor. Por favor intenta más tarde',
        canceled: 'La solicitud ha sido cancelada',
        unknown: 'Error desconocido. Por favor intenta más tarde'
    }
}