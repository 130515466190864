export default {
    home: 'Home',
    en: "English",
    es: "Español",
    fr: "Français",
    kr: "한국어",
    zh_hans: "汉语",
    zh_hant: "漢語",
    pt: "Portugues",
    freeProduct: "무료!",
    tools: "도구",
    company: "회사",
    contactUs: "문의하기",
    about: "소개",
    login: "로그인",
    signin: "로그인",
    signup: "가입하기",
    sendGift: "선물 보내기",
    subscription: "구독",
    platform: "플랫폼",
    parentsStudents: "학생과 학부모",
    schoolsTeachers: "학교 & 선생님",
    publishers: "출판사",
    copyright: "저작권 © 2020 bzabc",
    splashOverviewButton: "개요",
    aboutUsNote: "BZabc는 캐나다에 있는 GravityBrainInc에서 제작되었으며, 전통적은 공부를 대체하는 것이 아니라, 과학적인 방법을 사용하여 언어와 수학을 학생들이 쉽게 배울 수 있도록 환경을 제공합니다.",
    welcomeToWebsite: "BZabc에 오신 것을 환영합니다.",
    overview: "개요",
    pdf: "PDF",
    usingOurProduct: "BZabc를 사용하기",
    methods: "방법",
    quickstart: "쉽게 시작하기",
    ourMethod: "BZabc의 방식",
    platformOverview: "플랫폼 개요",
    publishersTab: "출판사",
    store: "스토어",
    privacy: "개인 정보 정책",
    terms: "서비스 약관",
    learningPlatform: "학습 플랫폼",
    learnMore: "더 배우기",
    productsNotFound: "상품을 찾을 수 없습니다.",
    appDownloadCenter: "앱 다운로드 센터",
    appDownloadCenterDescription: "학생 앱을 사용하기 전에 부모님 또는 학교가 등록해야 합니다.",
    more: "더 알아보기...",
    parentsApp: "BZabc 학부모",
    kidsApp: "BZabc 학생",
    schoolWebTool: "학교 웹 도구",
    gettingStarted: "시작하기",
    termsAndConditions: {
        title: "이용 약관",
        paragraphs: [
            {
                title: "GravityBrainInc.에 오신 것을 환영합니다.",
                content: `GravityBrainInc. 웹 사이트에서 이용 약관을 보실 수 있습니다.
                          GravityBrainInc. 주소:
                          PO Box 568 Hamiota,
                          Manitoba R0M0T0,
                          CANADA.
                          이 웹사이트를 열람하는 것은 귀하가 이 이용 약관을 완전히 수락하는 것을 의미합니다. 이 페이지에 명시된 모든 약관을 동의하지 않는 경우, GravityBrainInc. 웹사이트를 사용하지 않기를 바랍니다.
                          다음 용어는 본 이용 약관 개인 정보 보호 정책 및 면책 고지 및 일부 또는 모든 계약에 적용됩니다. "고객", "귀하" 및 "귀하의" 는 이 웹 사이트를 사용하며 회사의 이용 약관을 수락하는 사람을 의미합니다. "The Company", "Ourselves", "We", "Our" 및 "Us" 는 회사를 의미합니다. "당사자", "당사자들", 고객" 또한 "회사"를 의미합니다. 모든 조건은 정해진 기간의 공식 회의 또는 기타 수단을 통해 고객에 대한 지원 프로세스를 수행하는 데 필요한 지불 제안, 수락 및 고려를 의미합니다. 캐나다의 현행법에 따라 회사가 명시한 서비스 / 제품의 제공에 대한 고객의 요구. 단수, 복수, 대문자 및 / 또는 그 / 그녀 또는 이들에서 위의 용어 또는 다른 단어의 사용은 상호 교환 가능하므로 동일한 의미로 간주됩니다.`
            },
            {
                title: "쿠키",
                content: `저희는 쿠키를 사용합니다. GravityBrainInc.의 웹사이트를 사용함으로써 귀하는 GravityBrainInc.의 개인 정보 보호 정책에 따라 쿠키 사용에 동의하는 것으로 간주하며, 또한 다수의 대화형 웹사이트는 방문하는 사용자에 대한 세부 정보를 검색 할 수 있도록 쿠키를 사용합니다. 쿠키는 사이트의 기능을 활성화하며 사용자의 편의를 위해 사용됩니다. 일부 제휴 파트너도 쿠키를 사용할 수 있습니다.`,
            },
            {
                title: "라이센스",
                content: `이에 대한 세부적인 설명이 없다면 GravityBrainInc. 또는 라이센스 제공자는 GravityBrainInc.의 모든 자료에 대한 지적 재산권을 소유합니다. 모든 지적 재산권을 보유합니다. 귀하는 이용 약관에 설정된 제한에 따라 개인적인 용도로 https://gravitybrain.com/에서 페이지를 보거나 인쇄를 할 수 있습니다.
                          이와 같은 것을 할 수 없습니다.:
                          - https://www.gravitybrain.com에 있는 자료를 출판 할 수 없습니다.
                          - https://www.gravitybrain.com에 있는 자료를 판매, 대여 또는 라이센스를 재 판매 할 수 없습니다.
                          - https://www.gravitybrain.com에서 자료를 복사, 복제를 할 수 없습니다.
                          GravityBrainInc.의 콘텐츠 재배포 (콘텐츠 재배포용으로 만들어진 경우 제외).`,
            },
            {
                title: "당사 콘텐츠에 대한 하이퍼링크",
                content: `이 기관들은 사전 서면 승인 없이 당사 웹 사이트에 링크 할 수 있습니다:
                        - 정부 기관;
                        - 검색 엔진;
                        - 언론 기관;
                       온라인 디렉토리 배포자는 디렉로리에 저희웹 사이트를 다른 옥록에 있는 비즈니스와 동일한 방식으로 링크 할 수 있습니다. 비영리 단체, 자선 쇼핑몰 및 자선 기금 그룹을 제외한 다른 단체는 웹 사이트 하이퍼 링크를 연결할 수 없습니다.
                       이 단체들은 저희 웹 사이트, 출판물 또는 웹 사이트 출판물에 링크를 할 수 있지만, 이와 같은 규정을 준수 해야 합니다. (a) 어떠한 오해 소지가 없어야 합니다. (b) 링크를 건 당사자와 해당 제품 또는 서비스의 후원, 보증 또는 승인에 대한 허위로 암시하면 안됩니다. (c) 링크 당사자의 사이트가 합당한 내용으로 접근해야 합니다.
                       당사는 독단적으로 링크 요청을 고려와 승인을 할 수 있습니다: 상공 회의소, 미국 자동차 협회, AARP 및 소비자 연대와 같이 일반적으로 알려진 소비자 및 비즈니스 정보 소스; 닷컴 커뮤니티 사이트, 온라인 디렉토리 배포자를 포함한 자선 단체를 대표하는 협회 또는 기타 그룹; 인터넷 포털; 회계, 법륩 및 컨설팅 회사; 및 교육 기관 및 무역 협회.
                       당사는 다음과 같은 경우에 이러한 조직의 링크 요청을 승인합니다. (a) 링크가 당사 또를 당사의 공인 된 비즈니스 (예: 무역 협회 또는 근로 활동과 같이 본질적으로 의심되는 비즈니스 유형, 재택근무 기회 등, 저희 웹 사이트를 링크를 할 수 없습니다.) (b) 당사와 불편한 관계를 가졌던 단체. (c) 하이퍼 링크를 승인하지 않는 경우가 더 이득인 경우. (d) 링크가 일반 리소스 정보의 맥락에 있거나 당사의 사명을 설명하는 뉴스레터 또는 제품 설명 내용과 일치하는 경우. 이러한 조직은 링크가 다음과 같은 경우에 한해 당사 홈 페이지, 출판물 또는 기타 웬 사이트 정보로 링크 할 수 있습니다: (a) 어떤 식으로 오해 소지가 없어야 됩니다. (b) 연결한 당사자의 제품 또는 서비스의 후원, 보증 또는 승인을 허위롤 암사하면 안됩니다. (c) 링크 당사자 사이크의 맥락에 부합해야 됩니다.
                       위 2항에 나열된 조직 중 하나이며 당사 웹 사이츠에 연결하는 것에 대한 관심이 있다면, admin@gravitybrain.com으로 이메일을 보내 당사에게 알려야 합니다. 귀하의 이름, 소속 단체, 연락처 정보 (예: 전화번호 및 이메일 주소), 단체 웹 사이트 주소 당사 사이트의 URL 목록. 답장 보내는데 2-3 주가 소요 될 수 있습니다.`,
            },
            {
                title:
                        "승인된 단체는 다음과 같이 당사 웹 사이트에 하이퍼 링크를 연결할 수 있습니다.",
                content: ` - 저희 회사 이름 사용 또는,
                        - 링크되는 Uniform Resource Locator (웹 사이트 주소)를 사용하거나
                        - 저희 사이트에 있는 콘텐츠를 연결하면서 연걸한 웹 사이트에 있는 정보가 콘텐츠와 컨텍스가 납득 가능해야 합니다.
                       상표 라이센스 계약 없이 GravityBrain Learnng Inc.의 로고 및 기타 아트위크를 도용할 수 없습니다.`,
            },
            {
                title: "Iframes",
                content: `사전 승인 및 명시적인 서면 허가 없으면 웹 페이지 주위 프레임을 만들거나 웹 사이트의 시각적 표현이나 모양을 변경할 수 없습니다.`,
            },
            {
                title: "법적 권리",
                content: `저희는 언제든지 당사 웹 사이트에 대한 모든 링크 또는 특정한 링크를 제거하도록 요청할 권리가 있습니다. 그러한 요청이 있다면 그 링크를 즉시 제가해야 합니다. 또한 저희는 언제든지 이러한 이용 약관 및 연결 정책을 수정할 권리가 있습니다. 저희 웹 사이트에 링크를 거는 것은 이에 대한 이용 약관을 준수하며 동의하는 것입니다.`,
            },
            {
                title: "웹 사이트에서 링크 제거",
                content: ` 저희 웹 사이트에 있는 링크에 대해 이의를 제기하기 위해 당사에 연락 할 수 있습니다. 저희는 링크 제거 요청을 고려하겠지만 그렇게 실행하거나 직접 응답 할 의무는 없습니다.
                        저희는 웹 사이트에 정확한 정보를 사용하는 것을 노력하지만 신뢰성과 정확성은 보증하지 않습니다. 또한 웹 사이트를 계속 사용할 수 있도록 웹 사이트의 자료를 최신 상태로 유지하지 않습니다.`,
            },
            {
                title: "콘텐츠에 대한 책임",
                content: `저희는 귀하의 웹 사이트에 있는 콘텐츠에 대해 어떠한 책임을 지지 않습니다. 귀하는 귀하의 웹 사이트에서 발생하거나 귀하의 웹 사이트에서 발생한 모든 문제에 대해 당사는 법적인 책임이 없으며, 또한 그 문제에 대해 당사를 방어해야 합니다. 귀하의 웹 사이트의 페이지 또는 컨텐츠에 불미스러운, 음란한 자료가 포함되어 있으면 그것을 옹호하거나, 3자 권리를 침해할 수 없습니다.`,
            },
        ],
    },
    privacyPolicyContent: {
        title: "GravityBrain개인 정보 보호 정책",
        subTitle: "개인 정보 정책",
        paragraphs: [
            {
                title: "정보 수집",
                content: `저희는 귀하가 구독하거나 당사 소프크웨어에 등록할 때 귀하의 정보를 수집합니다.
                          저희 사이트에서 주문하거나 등록을 하면 이름, 이메일 등 개인 정보를 입력 요청을 받을 수 있습니다. 귀하의 보안을 위해 저희는 직접 신용카드 또는 금융 정보를 처리, 수집 또한 저장을 하지 않고 대신 Paypal, Alipay와 같은 타사 서비스를 활용합니다. 익명으로 저희 사이트를 사용할 수도 있습니다.`,
            },
            {
                title: "저희는 귀하의 정보를 어디에 사용하나요?",
                content: `귀하로부터 수집한 정보는 이와 같이 사용될 수 있습니다.:
                          귀하에게 더 잘 맞는 서비스 제공을 위해 (귀하의 정보는 귀하에게 필요한 요구사항을 더 잘 반영할 수 있습니다.)
                          고객 서비스를 개선하는 것에 도움이 됩니다. (귀하의 정보는 고객 서비스 요청 및 지원 요구에 보다 더 효율적으로 대응하기 위해 사용됩니다)
                          거래를 처리하기 위해 사용됩니다. (공개 또는 비공개 여부와 관계없이 귀하의 정보는 구매한 제품 또는 서비스를 제공하는 목적을 제외하고 귀하의 동의없이 다른 회사에 판매, 교환, 양도 또는 제공되지 않습니다)
                          주기적으로 이메일을 보내기 위해 사용됩니다. 주문 처리를 위해 제공 한 이메일 주소는 때때로 회사 뉴스, 업데이트, 관련 제품 또는 서비스 정보 등을 수신하는 것 외에도 주문과 관련된 정보 및 업데이트를 보내는 데 사용될 수 있습니다.`,
            },
            {
                title: "귀하의 정보를 어떻게 보호합니까?",
                content: `저희는 당신이 주문 정보를 입력,제출 또는 액세스 할 때 당신의 개인 정보의 보호하기 위해 다양한 보안 장치를 사용하고 있습니다.
                          저희는 보안 서버를 사용합니다. 제공된 모든 민감한/ 신용 정보는 SSL (Secure Socket Layer) 기술을 통해 전송 된 다음 해당 시스템에 대한 특별 사용 권한이 있는 사람만 액세스 할 수 있도록 결제 게이트웨이 공급자가 정보를 암호화 하며 정보를 보호하고 있습니다.
                          귀하의 개인 금융 정보 (신용카드, 주민등록번호, 금융정보 등) 의 거래 수집 및 저장은 저희 서버에서 처리되거나 저장 되지 않습니다.이러한 거래는 귀하의 정보를 보호와 안전을 위해 타사 제공 업체를 통해 직접 수행됩니다.`,
            },
            {
                title: "쿠키 (Cookies)를 사용합니까?",
                content: `저희 사이트의 쿠키는 학생들을 추적 및 자동 검토를 위해 사용됩니다. 그 정보는 학부모나 학교에서 승인 된 보고서를 처리하기 위해 사용됩니다. 또한 활성된 구독과 그렇지 않은 구독을 추적하기 위해 사용됩니다.`,
            },
            {
                title: "외부에 정보를 공개합니까?",
                content: `저희는 귀하의 개인 식별정보를 외부에 판매, 거래 또는 양도하지 않습니다. 여기에는 당사 웹 사이트 운영, 비즈니스 수행 또는 귀하에게 서비스를 제공하는 신뢰할 수 있는 제 3자는 포함되지 않습니다. 다만 법률 준수, 사이트 청책 시행 또는 타인의 권리, 재산 및 안전을 보호하는 데 적절하다고 판단되는 경우 귀하의 정보를 공개 할 수 있습니다. 그러나 개인 식별이 불가능한 방문자 정보는 마케팅, 광고 또는 다른 용도로 다른 곳에 제공 될 수 있습니다.`,
            },
            {
                title: "캘리포니아 주 온라인 개인 정보 보호법",
                content: `저희는 당신의 개인 정보를 소중히 여기기 때문에 캘리포니아 온라인 개인 정보 보호법을 준수하며 여러 예방 조치를 취했습니다. 따라서 저희는 귀하의 동의없이 귀하의 개인 정보를 외부에 배포하지 않습니다.`,
            },
            {
                title: "아동 온라인 개인 정보 보호법",
                content: `저희는 COPPA (Childrens Online Private Protection Act)를 준수하며, 13세 미만의 사람의 정보를 수집하지 않습니다. 저희 웹 사이트, 제품 및 서비스는 모두 최소 13세 이상의 이용자을 위해 만들어 졌습니다. 학부모께서 자녀를 실명 또는 가명으로 등록할 수 있습니다.`,
            },
            {
                title: "교육 시스템에 대한 개인 정보",
                content: `귀하는 당사 소프트웨어에 성명 및 생년월일과 같은 개인 정보를 제공 요청을 받을 수 있습니다. 이 정보는 귀하를 위해 사용되나 이를 입력하지 않아도 됩니다. 생년월일 정보는 귀하가 관심을 가질 수 있는 교육 과정을 제안하기 위해 사용됩니다.`,
            },
            {
                title: "이용 약관",
                content: `저희 웹 사이트에서 사용 약관, 면책 및 책임 제한에 대한 설명을 볼 수 있습니다.`,
            },
            {
                title: "귀하의 동의",
                content: `저희 사이트를 사용하는 것은 귀하는 이 개인 정보 보호 정책에 동의하는 것과 같습니다.`,
            },
            {
                title: "개인 정보 보호 정책 변경",
                content: `개인 정보 보호 정책을 변경하면 변경 사항은 이 페이지에 게시합니다.`,
            },
            {
                title: "문의하기",
                content: `개인 정보 보호 정책에 대한 질문이 있는 경우, 당사에 연락 할 수 있습니다.
                          Box 568
                          Hamiota, Manitoba R0M 0T0
                          CANADA`,
            }
        ]
    }
}
