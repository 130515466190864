import translations from './translations';
export default {
  ...translations,
  
    // 상단 타이틀 //
    giveGiftOfLearning: "BZabc 학습 플랫폼",
    professionallyDesignedCourses:
      "어린 학습자를 위한 철자, 수학 및 언어",
    generalInfoGB:
      "GravityBrain Inc.는 교육 커뮤니티를 위한 의미 있는 결과물, 인사이트 및 유용한 도구를 생산하기 위해 전통적인 자료와 방법을 활용하는 데이터 주도 기술을 사용하는 e러닝 시스템을 전문적으로 개발합니다.",
    // 상단 타이틀 끝 //
    // 앱 다운로드 센터 //
    AppDownload: "앱 다운로드 센터",
    AppDownloadDescription:
      "어린이 앱을 사용하기 전에 학습자는 학교 또는 부모/보호자를 통해 과목에 등록 및 수강해야 합니다.",
    // 앱 다운로드 센터 끝 //
    // BZabc 학습 플랫폼 //
    aboutGravityBrainNote: "GravityBrain에 대한 1-3문장 단락",
    welcomeToGravityBrain: "모두를 위한 E러닝",
    InteractiveTestingAndTraining: "테스트 및 트레이닝: 초기 학습자",
    aboutBZabcNote:
      "BZabc는 어린 학습자를 위해 설계되었으며, 핵심 과목 언어 및 수학 교육에서 기술 습득을 크게 향상시키도록 설계되었습니다. 매우 대화식이며 미디어 리치하며 실시간 보고서 및 즉각적인 메시지 기능이 있습니다.",
    authoringTools: "게시 및 저작 도구",
    aboutAuthoringToolsNote:
      "독립 출판사는 GravityBrain Pubtool을 통해 GravityBrain에서 상호 작용 및 비디오 기반 과정을 게시할 수 있습니다. 작가, 작가, 시각 및 녹음 아티스트는 자신의 커리큘럼을 작성하고 수익 공유 기회를 활용할 수 있습니다.",
    multimediaDevelopment: "멀티미디어 개발",
    aboutMMDevelopmentNote:
      "GravityBrain Inc.은 Inno-Care.ca와 협력하여 시스템 내 모든 멀티미디어를 생산합니다. Inno-Care는 특수 필요를 가진 사람들에게 의미 있는 고용을 제공하면서 3D 애니메이션, A/V 및 음향 제작에 대해 경쟁력 있는 스튜디오 요금을 제공하는 비영리 단체입니다.",
    trainingSystemSAAS: "E러닝 SAAS",
    aboutTrainingSystemsSAASNote:
      "자체 서비스를 운영하려는 정부나 대규모 학교를 위해 우리는 다양한 기능을 제공하는 강력한 학습 관리 시스템을 제공합니다. 개인화된 데이터 주도 학습. 보고. 게시 도구, 앱. 메시징. 그리고 많은 기능이 있습니다.",
    contactInfo:
      "이메일: office@gravitybrain.com\n전화: 1-306-570-6488\nSkype: gravity_brain\nPO Box 568 Hamiota, \nR0M 0T0, 캐나다",
      splashBannerPoint1: "빠른 등록 및 수강",
    splashBannerPoint2: "매력적이고 대화식 레슨",
    splashBannerPoint3: "개인화된 데이터 주도 학습",
    splashBannerPoint4: "실시간 보고 및 메시징",
    LetsStartButton: "시작해보세요",
    // BZabc 학습 플랫폼 끝 //
    // BZabc 메소드 - 이점 //
    BZabcMethod: "BZabc 메소드",
    practicalSkillDevelopment: "실용 기술 개발",
    practicalSkillDevelopmentDescription:
      "듣기. 말하기. 읽기. 쓰기.\n BZabc는 이러한 필수 기술을 학생들이 다양한 활동에 참여하도록 장려하여 내재화하는 데 도움이 됩니다.",
    consistency: "일관성",
    consistencyDescription:
      "소프트웨어, 콘텐츠 및 작업북 간에 연결된 자료는 학습 과정 전체에 일관성을 제공합니다. 실제로 통합되고 연결된 학습 환경입니다.",
    totalPhysicalResponse: "전체 신체 반응",
    totalPhysicalResponseDescription:
      "집이나 교실에서, 흥미로운 콘텐츠가 다양한 수준의 참여를 유도합니다. 명확하고 달성 가능한 학습 목표는 학생들을 적극 참여시키고 동기부여를 유지합니다.",
    dailyPractice: "지속성: 매일 연습",
    dailyPracticeDescription:
      "BZabc Kids App은 모든 활동에서 어린 학습자를 안내하기 쉽게 설계되었으며, 디지털 및 서면 활동을 통해 전체 과정 동안 안내합니다. 일, 주, 달.",
    // BZabc 메소드 - 이점 끝 //
    // 기술 사양 //
    techSpecs: "기술 사양",
    forAllPlatforms: "모든 플랫폼용",
    forAllPlatformsDescription:
      "BZabc Kids App은 Android, iOS 및 Windows 7 및 10에서 사용할 수 있습니다. 우리의 부모 앱도 모든 플랫폼에서 사용할 수 있습니다.",
    blendedLearning: "통합 학습",
    blendedLearningDescription:
      "소프트웨어, 콘텐츠 및 작업북 간에 연결된 자료는 학습 과정 전체에 일관성과 연속성을 제공합니다.",
    personalGuidance: "개인적인 안내",
    personalGuidanceDescription:
      "BZabc App은 학생의 성적을 활용하여 학습 과정 전체를 안내합니다. 클라우드 기반 데이터 추적 및 처리를 통해 어디서든 언제든 학습할 수 있으며, 학생들은 올바른 페이지에 있습니다.",
    trackingReporting: "추적 / 보고",
    trackingReportingDescription:
      "학생의 성적은 우리 시스템에서 추적됩니다. 점수 및 오류 보고 데이터는 부모에게 실시간으로 제공됩니다. 이를 통해 부모는 언제 어린이가 작업하고 어떤 문제에 직면했는지 확인할 수 있습니다.",
    // 기술 사양 끝 //
    // 하단 타이틀 //
    bottomCourseInfo: "어린 학습자를 위한 철자, 수학 및 언어",
    ourPlatformInfo: "BZabc 학습 플랫폼",
    // 하단 타이틀 끝 //
    // 하단 링크 //
    store: "상점",
    privacy: "개인정보 보호 정책",
    terms: "이용 약관",
    // 하단 링크 끝 //
    // 추가 정보 //
    learningPlatform: "학습 플랫폼",
    learnMore: "자세히 알아보기",
    productsNotFound: "제품을 찾을 수 없습니다...",
    appDownloadCenter: "앱 다운로드 센터",
    appDownloadCenterDescription:
      "어린이 앱을 사용하기 전에 학습자는 학교 또는 부모/보호자를 통해 과목에 등록 및 수강해야 합니다.",
    more: "더 보기...",
    parentsApp: "BZabc 부모",
    kidsApp: "BZabc 어린이",
  
  
  // END Additional Info //
};
