import React, { PureComponent } from "react";
import { withTranslation } from "react-i18next";
import SplashMethodItem from "./sections/SplashMethodItem";
import SplashTechSpecItem from "./sections/SplashTechSpecItem";
import AppLink from "../../components/ui/AppLink";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@material-ui/core";
import SchoolsWrapper from "./sections/SchoolsWrapper";
import CldImage from "../../components/ui/CldImage";

class SchoolTeacherContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      videoContent: "",
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setVideoContent();
    this.setState({
      modal: true,
    });
  }

  handleCloseModal() {
    this.setState({
      modal: false,
    });
  }

  _renderPlatFormExp() {
    const { t } = this.props;
    const exps = [
      t("splashBannerPoint1"),
      t("splashBannerPoint2"),
      t("splashBannerPoint3"),
      t("splashBannerPoint4"),
    ];
    return exps.map((exp, key) => (
      <p index={key} key={key} className="platform-exp-label avenir-normal">
        <i className="fa fa-check-circle mr-2"></i>
        {exp}
      </p>
    ));
  }

  _renderBzabcMethod() {
    const { t } = this.props;
    const methods = [
      {
        url: "Benefits_Teachers_01.jpg",
        title: t("practicalSkillDevelopment"),
        content: t("practicalSkillDevelopmentDescription"),
      },
      {
        url: "Benefits_Teachers_02.jpg",
        title: t("consistency"),
        content: t("consistencyDescription"),
      },
      {
        url: "Benefits_Teachers_03.jpg",
        title: t("totalPhysicalResponse"),
        content: t("totalPhysicalResponseDescription"),
      },
      {
        url: "Benefits_Teachers_04.jpg",
        title: t("dailyPractice"),
        content: t("dailyPracticeDescription"),
      },
    ];
    return methods.map((method, key) => (
      <SplashMethodItem
        index={key}
        key={key}
        url={method.url}
        title={method.title}
        content={method.content}
      ></SplashMethodItem>
    ));
  }

  _renderBzabcTechSpec() {
    const { t } = this.props;
    const specs = [
      {
        url: "techspecs_for_all_platforms.png",
        title: t("forAllPlatforms"),
        content: t("forAllPlatformsDescription"),
      },
      {
        url: "techspecs_blended_learning.png",
        title: t("blendedLearning"),
        content: t("blendedLearningDescription"),
      },
      {
        url: "techspecs_personal_guidance.png",
        title: t("personalGuidance"),
        content: t("personalGuidanceDescription"),
      },
      {
        url: "techspecs_tracking_reporting.png",
        title: t("trackingReporting"),
        content: t("trackingReportingDescription"),
      },
    ];
    return specs.map((spec, key) => (
      <SplashTechSpecItem
        index={key}
        key={key}
        url={spec.url}
        title={spec.title}
        content={spec.content}
      ></SplashTechSpecItem>
    ));
  }

  setVideoContent() {
    const { i18n } = this.props;
    const language = i18n.language;
    const videos = {
      en: "//youtube.com/embed/qeWbVq_GWZw",
      es: "//youtube.com/embed/qeWbVq_GWZw",
      fr: "//youtube.com/embed/qeWbVq_GWZw",
      kr: "//youtube.com/embed/qeWbVq_GWZw",
      zh_hans: "//youtube.com/embed/qeWbVq_GWZw",
      zh_hant: "//youtube.com/embed/qeWbVq_GWZw",
    };
    this.setState({ videoContent: videos[language] });
  }

  render() {
    const { t } = this.props;
    const { modal, videoContent } = this.state;

    return (
      <SchoolsWrapper showJumbotron type="schools">
        <div className="bzabc-application">
          <div className="app-download container">
            <div className="row">
              <div className="col-lg-7 col-md-8 col-sm-12 py-sm-4 px-sm-5">
                <h2>{t("AppDownload")}</h2>
                <p className="description avenir-normal mx-2">
                  {t("AppDownloadDescription")}
                </p>
              </div>
              <div className="col-lg-5 col-md-4 col-sm-12 app-download-icons text-center">
                <AppLink type="students">
                  <CldImage src="bzabc_kids_1024.png" />{" "}
                </AppLink>
                <AppLink type="parents">
                  <CldImage src="bzabc_parents_1024.png" />{" "}
                </AppLink>
              </div>
            </div>
          </div>
          <div className="learning-platform">
            <h1 className="mt-4 text-center">{t("welcomeToWebsite")}</h1>
            <div className="schools-pl-bg learning-platform-background mt-4">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-10 mx-auto about-us-note avenir-normal">
                    {t("aboutUsNote")}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-5 platform-video-area">
                    <CldImage
                      className="platform-video"
                      src="schools_vidstill.png"
                    />
                    <div className="play-btn" onClick={this.handleOpenModal}>
                      <CldImage
                        className="play-btn"
                        src="platform_play_button.png"
                      />
                    </div>
                  </div>
                  <div className="col-md-6 platform-start pt-4">
                    {this._renderPlatFormExp()}
                    <a href="//d2cnhr6egzpvdl.cloudfront.net/PDFs/BZabc-parents-and-kids-quiskstart.pdf">
                      <button className="start-btn mt-2">
                        {t("LetsStartButton")}
                      </button>{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bzabc-tech-container container">
            <h1 className="gotham-bold text-center">{t("BZabcMethod")}</h1>
            <div className="row bzabc-method-list">
              {this._renderBzabcMethod()}
            </div>
            <h1 className="techspec-title text-center">{t("techSpecs")}</h1>
            <div className="row bzabc-spec-list">
              {this._renderBzabcTechSpec()}
            </div>
          </div>
        </div>
        <Dialog
          open={modal}
          onClose={this.handleCloseModal}
          className="youtube-player-dialog"
        >
          <DialogContent className="youtube-player-content">
            <iframe
              title="content"
              src={videoContent}
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="secondary"
              size="small"
              variant="contained"
              onClick={this.handleCloseModal}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </SchoolsWrapper>
    );
  }
}

export default withTranslation("schools")(SchoolTeacherContainer);
