import React from "react";
import { withTranslation } from "react-i18next";
import SplashHeader from "./SplashHeader";
import SplashFooter from "./SplashFooter";
import SplashJumbotron from "./SplashJumbotron";
import "../../../styles/splash.css";

const SplashWrapper = (props) => {
  const minHeight = window.document.documentElement.clientHeight - 1560;

  return (
    <div className="splash">
      <SplashHeader {...props} />
      <section className={`${props.className || "splash-section"}`}>
        {props.showJumbotron && <SplashJumbotron {...props} />}
        <div style={{ minHeight: minHeight }}>{props.children}</div>
      </section>
      <SplashFooter {...props} />
    </div>
  );
};

export default withTranslation("platform")(SplashWrapper);
