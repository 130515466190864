export default {
    mainTitle: 'GravityBrain',
    notFound: '404',
    products: 'Products',
    company: 'Company',
    contactUs: 'Contact Us',
    about: 'About Us',
    parentsStudents: 'Parents & Students',
    schoolsTeachers: 'Schools & Teachers',
    termsAndConditions: 'Terms & Conditions',
    methodology: 'Methodology',
    techSpecs: 'Tech Specs',
    privacyPolicy: 'Privacy Policy',
    parents: 'Parents',
    home: 'Home',
}