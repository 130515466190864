export default {
    mainTitle: 'BZabc',
    notFound: '404',
    about: 'Sobre Nós',
    parentsStudents: 'Pais & Estudantes',
    schoolsTeachers: 'Escolas & Professores',
    termsAndConditions: 'Termos & Condições',
    methodology: 'Metodologia',
    techSpecs: 'Especificações Técnicas',
    privacyPolicy: 'Política de Privacidade',
    parents: 'Pais',
    home: 'Home',
}