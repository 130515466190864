import translations from './translations';
export default {
  ...translations,
  
    // Títulos Principales //
    giveGiftOfLearning: "La Plataforma de Aprendizaje BZabc",
    professionallyDesignedCourses:
      "Ortografía, matemáticas e idiomas\npara jóvenes aprendices",
    generalInfoGB:
      "GravityBrain Inc. se dedica a producir sistemas de aprendizaje electrónico con resultados significativos, ideas útiles y herramientas útiles para la comunidad educativa, utilizando tecnología impulsada por datos para aprovechar materiales y métodos tradicionales.",
    // FIN Títulos Principales //
    // Centro de Descarga de Aplicaciones //
    AppDownload: "Centro de Descarga de la Aplicación",
    AppDownloadDescription:
      "Antes de usar la aplicación para niños, los aprendices deben estar registrados e inscritos\n en cursos ya sea por su escuela o por sus padres/tutores.",
    // FIN Centro de Descarga de Aplicaciones //
    // La Plataforma de Aprendizaje BZabc //
    aboutGravityBrainNote: "Párrafo sobre GravityBrain de 1 a 3 oraciones",
    welcomeToGravityBrain: "Aprendizaje electrónico para Todos",
    InteractiveTestingAndTraining: "Pruebas y Entrenamiento: Aprendices Tempranos",
    aboutBZabcNote:
      "BZabc está diseñado para jóvenes aprendices, con el propósito de mejorar sustancialmente la adquisición de habilidades en materias básicas como el idioma y las matemáticas. Altamente interactivo, rico en medios, informes en tiempo real y mensajería instantánea son solo algunas de las características.",
    authoringTools: "Herramientas de Publicación y Autoría",
    aboutAuthoringToolsNote:
      "Los editores independientes pueden publicar cursos interactivos basados en video en GravityBrain a través de GravityBrain Pubtool. Escritores, autores, artistas visuales y de grabación pueden colaborar para construir sus propios planes de estudio, con oportunidades de compartir ingresos.",
    multimediaDevelopment: "Desarrollo Multimedia",
    aboutMMDevelopmentNote:
      "GravityBrain Inc. se ha asociado con Inno-Care.ca para producir todo el contenido multimedia en nuestro sistema. Inno-Care es una organización sin fines de lucro que ofrece tarifas competitivas de estudio en animación 3D, A/V y producción de sonido, al mismo tiempo que brinda empleo significativo a personas con necesidades especiales.",
    trainingSystemSAAS: "SAAS de Aprendizaje Electrónico",
    aboutTrainingSystemsSAASNote:
      "Para gobiernos o escuelas más grandes que deseen operar su propio servicio, ofrecemos un sólido sistema de gestión de aprendizaje con numerosas características: aprendizaje personalizado impulsado por datos, herramientas de informes, publicación, aplicaciones, mensajería y muchas más características.",
    contactInfo:
      "Email: office@gravitybrain.com\nTeléfono: 1-306-570-6488\nSkype: gravity_brain\nPO Box 568 Hamiota, \nR0M 0T0, CANADÁ",
      splashBannerPoint1: "Registro y inscripción rápida",
    splashBannerPoint2: "Lecciones atractivas e interactivas",
    splashBannerPoint3: "Aprendizaje personalizado impulsado por datos",
    splashBannerPoint4: "Informes y mensajería en tiempo real",
    LetsStartButton: "Comencemos",
    // FIN La Plataforma de Aprendizaje BZabc //
    // El Método BZabc - Beneficios //
    BZabcMethod: "El Método BZabc",
    practicalSkillDevelopment: "Desarrollo de Habilidades Prácticas",
    practicalSkillDevelopmentDescription:
      "Escuchar. Hablar. Leer. Escribir.\n BZabc ayuda a los estudiantes a internalizar estas habilidades esenciales, fomentando la participación en una variedad de actividades.",
    consistency: "Consistencia",
    consistencyDescription:
      "Los materiales están conectados entre el software, el contenido y los libros de trabajo, proporcionando consistencia a lo largo del proceso de aprendizaje. Un entorno de aprendizaje verdaderamente integrado y conectado.",
    totalPhysicalResponse: "Respuesta Física Total",
    totalPhysicalResponseDescription:
      "En casa o en clase, el contenido emocionante fomenta la participación en muchos niveles. Los objetivos de aprendizaje claros y alcanzables son muy gratificantes, manteniendo a los estudiantes comprometidos y motivados.",
    dailyPractice: "Continuidad: Práctica Diaria",
    dailyPracticeDescription:
      "La aplicación BZabc Kids es fácil de usar y guía a los jóvenes aprendices en todas las actividades, tanto digitales como escritas, a lo largo de todo su curso. Días, semanas y meses.",
    // FIN El Método BZabc - Beneficios //
    // Especificaciones Técnicas //
    techSpecs: "Especificaciones Técnicas",
    forAllPlatforms: "Para Todas las Plataformas",
    forAllPlatformsDescription:
      "La aplicación BZabc Kids está disponible en Android, iOS y Windows 7 y 10. Nuestra aplicación para padres también está disponible en todas las plataformas.",
    blendedLearning: "Aprendizaje Combinado",
    blendedLearningDescription:
      "Los materiales están conectados entre el software, el contenido y los libros de trabajo, proporcionando continuidad y consistencia a lo largo del proceso de aprendizaje.",
    personalGuidance: "Orientación Personal",
    personalGuidanceDescription:
      "La aplicación BZabc utiliza el rendimiento del estudiante para guiarlo a lo largo de sus cursos. El seguimiento y procesamiento de datos basados en la nube significa que donde y cuando sea que aprendan, los estudiantes están en el camino correcto.",
    trackingReporting: "Seguimiento / Informes",
    trackingReportingDescription:
      "El rendimiento del estudiante se rastrea en nuestro sistema. Los datos de puntaje y reporte de errores están disponibles en tiempo real para los padres. Esto les permite ver cuándo trabajaron los niños y cualquier desafío que puedan haber encontrado.",
    // FIN Especificaciones Técnicas //
    // Títulos Inferiores//
    bottomCourseInfo: "Ortografía, matemáticas e idiomas\n para jóvenes aprendices",
    ourPlatformInfo: "La Plataforma de Aprendizaje BZabc",
    // FIN Títulos Inferiores //
    // Enlaces Inferiores //
    store: "Tienda",
    privacy: "Política de Privacidad",
    terms: "Términos de Servicio",
    // FIN Enlaces Inferiores //
    // Información Adicional //
    learningPlatform: "Plataforma de Aprendizaje",
    learnMore: "Aprende más",
    productsNotFound: "Productos no encontrados...",
    appDownloadCenter: "Centro de Descarga de la Aplicación",
    appDownloadCenterDescription:
      "Antes de usar la aplicación para niños, los aprendices deben estar registrados e inscritos en cursos por su escuela o sus padres/tutores.",
    more: "más...",
    parentsApp: "BZabc Padres",
    kidsApp: "BZabc Kids",
  
  
// END Additional Info //
};
