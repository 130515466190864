export default {
    home: 'Home',
    en: 'English',
    es: 'Español',
    fr: 'Français',
    kr: '한국어',
    zh_hans: '汉语',
    zh_hant: '漢語',
    pt: 'Portugues',
    freeProduct: 'Gratuit',
tools: "Outils",
company: "Entreprise",
contactUs: "Contactez-nous",
about: "À propos",
login: "Connexion",
signin: "Se connecter",
signup: "S'inscrire",
sendGift: "Envoyer un cadeau",
subscription: "Abonnement",
platform: "Plateforme",
parentsStudents: "Étudiants et parents",
schoolsTeachers: "Écoles et enseignants",
publishers: "Éditeurs",
copyright: "Droits d'auteur © 2020 bzabc",
splashOverviewButton: "Aperçu",
aboutUsNote:
        "BZabc est produit par GravityBraininc, une société privée canadienne, dédiée à l'amélioration des résultats d'apprentissage des enfants dans la formation linguistique et mathématique en utilisant la technologie moderne pour renforcer, et non pas remplacer, les matériaux et méthodes traditionnels.",
welcomeToWebsite: "Bienvenue sur BZabc",
overview: "Vue d'ensemble",
pdf: 'PDF',
usingOurProduct: "Utilisation de BZabc",
methods: "Méthodes",
quickstart: "Démarrage rapide",
ourMethod: "La méthode BZabc",
platformOverview: "Aperçu de la plateforme",
publishersTab: "Éditeurs",
store: "Magasin",
privacy: "Politique de confidentialité",
terms: "Conditions d'utilisation",
learningPlatform: "Plateforme d'apprentissage",
learnMore: "En savoir plus",
productsNotFound: "Produits non trouvés...",
appDownloadCenter: "Centre de téléchargement de l'application",
appDownloadCenterDescription: "Avant d'utiliser l'application pour enfants, les apprenants doivent être inscrits et inscrits à des cours par leur école ou leur parent/tuteur.",
more: "plus...",
parentsApp: "BZabc Parents",
kidsApp: "BZabc Enfants",
schoolWebTool: "Outil Web scolaire",
gettingStarted: "Pour commencer",

    // Terms and Conditions //
    termsAndConditions: {
        title: "Conditions générales",
        paragraphs: [
            {
                title: "Bienvenue à GravityBrainInc.",
                content: `Les présentes conditions générales décrivent les règles et réglementations relatives à l'utilisation du site web de GravityBrainInc.
                          GravityBrainInc. est situé à :
                          PO Box 568 Hamiota,
                          Manitoba R0M0T0,
                          CANADA.
                          En accédant à ce site web, nous partons du principe que vous acceptez intégralement ces conditions générales. Nous vous prions de ne pas continuer à utiliser le site web de GravityBrainInc. si vous n'acceptez pas toutes les conditions générales énoncées sur cette page.
                          La terminologie suivante s'applique aux présentes conditions générales, à la déclaration de confidentialité et à l'avis de non-responsabilité, ainsi que pour tous les Accords : "Client", "Vous", "Votre" et "Vos" font référence à vous, la personne qui accède à ce site web et qui accepte les conditions générales de l'Entreprise. "l'Entreprise", "Nous-mêmes", "Nous", "Notre" et "Nos", font référence à notre Entreprise. "Parti", "Parties", ou "Nous", font référence à la fois au Client et à Nous-mêmes, ou au Client ou à Nous-mêmes. Tous les termes se réfèrent à l'offre, l'acceptation et la considération du paiement nécessaire pour entreprendre notre processus d'assistance au Client de la manière la plus appropriée, que ce soit par des réunions formelles avec une durée déterminée, ou par tout autre moyen, dans le but formel de répondre aux besoins du Client en ce qui concerne la prestation des services/produits indiqués de l'Entreprise, conformément et sous réserve du droit en vigueur au Canada. Toute utilisation de la terminologie ci-dessus ou d'autres mots au singulier, au pluriel, en majuscules et/ou il/elle, ou ils/elles sont considérés comme interchangeables et donc se réfèrent aux mêmes conditions.`
            },
            {
                title: "Cookies",
                content: `Nous utilisons des cookies. En utilisant le site web de GravityBrainInc. vous consentez à l'utilisation de cookies conformément à la politique de confidentialité de GravityBrainInc. La plupart des sites web interactifs modernes utilisent des cookies pour nous permettre de récupérer des informations sur les utilisateurs à chacune de leurs visites. Les cookies sont utilisés sur certaines sections de notre site pour permettre le bon fonctionnement de cette section et la facilité d'utilisation pour les personnes qui la visitent. Certains de nos partenaires affiliés peuvent également utiliser des cookies.`
            },
            {
                title: "Licence",
                content: `Sauf indication contraire, GravityBrainInc. et/ou ses concédants de licence détiennent les droits de propriété intellectuelle pour tout le contenu sur GravityBrainInc. Tous les droits de propriété intellectuelle sont protégés. Vous pouvez consulter et/ou imprimer les pages de https ://gravitybrain.com/ pour votre usage personnel, sous réserve des limitations fixées dans les présentes conditions générales.
                          Vous ne devez pas :
                          - Republier le contenu présent sur https ://www.gravitybrain.com
                          - Vendre, louer ou sous-louer une licence pour du contenu provenant de https ://www.gravitybrain.com
                          - Reproduire, dupliquer ou copier du contenu présent sur https ://www.gravitybrain.com
                          Redistribuer le contenu de GravityBrainInc. (sauf si le contenu est spécifiquement conçu pour être redistribué).`
            },
            {
                title: "Hyperliens vers notre contenu",
                content: `Les organisations suivantes peuvent créer des liens vers notre site web sans autorisation écrite préalable :
                        - organismes gouvernementaux ;
                        - moteurs de recherche ;
                        - agences de presse ;
                       Les distributeurs d'annuaires en ligne, lorsqu'ils nous inscrivent dans l'annuaire, peuvent établir un lien vers notre site de la même manière qu'ils établissent un hyperlien vers les sites d'autres entreprises inscrites ; et les entreprises accréditées à l'échelle du système, à l'exception des organismes de sollicitation à but non lucratif, des centres commerciaux de bienfaisance et des groupes de collecte de fonds à des fins caritatives qui ne peuvent pas établir d'hyperlien vers notre site web.
                       Ces organisations peuvent établir un lien vers notre page d'accueil, vers des publications ou vers d'autres informations du site web, de l'instant que le lien soit bien établi : (a) ne soit en aucune façon trompeur ; (b) n'implique pas faussement le parrainage, l'appui ou l'approbation de la partie qui établit le lien et de ses produits ou services ; et (c) S'inscrive dans le contexte du site de la partie qui établit le lien.
                       Nous pouvons examiner et approuver, à notre seule discrétion, d'autres demandes de liens émanant des types d'organisations suivants : sources d'informations courantes sur les consommateurs et/ou les entreprises, telles que les chambres de commerce, l’Association américaine des automobilistes, l'AARP et l'Union des consommateurs ; sites communautaires dot.com ; associations ou autres groupes représentant des organisations caritatives, y compris les sites de dons, les distributeurs d'annuaires en ligne ; les portails Internet ; les cabinets comptables, juridiques et de conseil dont les principaux clients sont des entreprises ; les établissements d'enseignement et les associations commerciales.
                       Nous approuverons les demandes de liens de ces organisations si nous le jugeons que : (a) le lien n'aura pas une incidence négative sur nous ou sur nos entreprises accréditées (par exemple, les associations commerciales ou autres organisations représentant des types d'entreprises intrinsèquement suspects, comme les possibilités de travailler de la maison, ne seront pas autorisées à établir un lien) ; (b) l'organisation n'a pas un dossier insatisfaisant à notre égard ; (c) l'avantage que nous tirons de la visibilité associée à l'hyperlien l'emporte sur l'absence de ; et (d) le lien se situe dans le contexte d'informations générales sur les ressources ou est autrement compatible avec le contenu éditorial d'un bulletin d'information ou d'un produit similaire favorisant la mission de l'organisation. Ces organisations peuvent établir un lien vers notre page d'accueil, vers des publications ou vers d'autres informations sur le site web à condition que le lien : a) ne soit en aucune façon trompeur ; b) n'implique pas faussement le parrainage, l'approbation ou l'aval de la partie qui établit le lien et de ses produits ou services ; et c) S'inscrive dans le contexte du site de la partie qui établit le lien.
                       Si vous faites partie des organisations énumérées au paragraphe 2 ci-dessus et que vous souhaitez créer un lien vers notre site web, vous devez nous en informer en envoyant un courriel à admin@gravitybrain.com. Veuillez indiquer votre nom, le nom de votre organisation, vos coordonnées (telles qu'un numéro de téléphone et/ou une adresse électronique) ainsi que l'URL de votre site, une liste des URL à partir desquelles vous avez l'intention d'établir un lien vers notre site web et une liste des URL de notre site vers lesquelles vous souhaitez établir un lien. Prévoyez un délai de 2 à 3 semaines pour une réponse.`
            },
            {
                title:
                        "Les organisations agréées peuvent établir un hyperlien vers notre site internet comme suit :",
                content: ` - par l'utilisation de notre dénomination sociale ; ou
                        - par l'utilisation du localisateur de ressources uniformes (adresse Internet) auquel il est lié ; ou
                        - par l'utilisation de toute autre description de notre site internet ou de tout autre matériel lié à celui-ci qui a un sens dans le contexte et le format du contenu du site de la partie qui établit le lien.
                      Aucune utilisation du logo ou d'autres illustrations de GravityBrainInc. ne sera autorisée pour les liens en l'absence d'un accord de licence de marque.`
            },
            {
                title: "Iframes",
                content: `Sans autorisation préalable et écrite formelle, vous ne pouvez pas créer de frames autour de nos pages Web ou utiliser d'autres techniques qui altèrent de quelque manière que ce soit la présentation visuelle ou l'apparence de notre site internet.`
            },
            {
                title: "Réservation des droits",
                content: `Nous nous réservons le droit, à tout moment et à notre seule discrétion, de vous demander de supprimer tous les liens ou tout lien particulier vers notre site internet. Vous acceptez de supprimer immédiatement tous les liens vers notre site Web sur demande. Nous nous réservons également le droit de modifier à tout moment les présentes conditions générales ainsi que notre politique en matière de liens. En continuant à créer des liens vers notre site internet, vous acceptez d'être lié et de respecter les présentes conditions générales.`
            },
            {
                title: "Suppression des liens de notre site internet",
                content: `Si vous trouvez un lien sur notre site internet ou tout autre site internet lié répréhensible pour une raison quelconque, vous pouvez nous contacter à ce sujet. Nous prendrons en considération les demandes de suppression de liens, mais nous n'aurons aucune obligation de le faire ou de vous répondre directement.
                        Bien que nous nous efforcions d'assurer l'exactitude des informations figurant sur ce site internet, nous ne garantissons pas leur exhaustivité ou leur exactitude ; nous ne nous engageons pas non plus à assurer que le site internet reste disponible ou que le matériel figurant sur le site internet soit maintenu à jour.`
            },
            {
                title: "Responsabilité du contenu",
                content: `Nous n'avons aucune responsabilité quant au contenu de votre site internet. Vous acceptez de nous indemniser et de nous défendre contre toute réclamation découlant de votre site internet ou basée sur celui-ci. Aucun lien ne peut apparaître sur une page de votre site web ou dans un contexte contenant un contenu ou des éléments pouvant être interprétés comme diffamatoires, obscènes ou criminels, ou qui enfreint, viole ou préconise l'infraction ou toute autre violation des droits d'un tiers.`
            }
        ]
    },
    privacyPolicyContent: {
        title: "Politique de confidentialité de GravityBrain",
        subTitle: "Politique de confidentialité",
        paragraphs: [
            {
                title: "Quelles informations récupérons-nous ?",
                content: `Nous récoltons des informations sur vous lorsque vous passez une commande ou que vous vous enregistrez sur notre logiciel.
                          Lorsque vous passez commande ou vous inscrivez sur notre site, selon le cas, il peut vous être demandé d'entrer votre : nom, courriel ou autres informations personnelles. Pour votre sécurité, nous ne traitons pas, ne collectons pas et ne stockons pas les informations relatives aux cartes de crédit ou les informations financières directement sur notre site, mais nous utilisons plutôt les services de fournisseurs tiers tels que Paypal ou Alipay. Vous pouvez également choisir de visiter notre site de manière anonyme.`
            },
            {
                title: "À quelles fins utilisons-nous vos informations ?",
                content: `Toutes les informations que nous récoltons auprès de vous peuvent être utilisées de l'une des manières suivantes :
                          Pour personnaliser votre expérience (vos informations nous aident à mieux répondre à vos besoins individuels)
                          Pour améliorer le service client (vos informations nous aident à répondre plus efficacement à vos demandes de service client et à vos besoins de soutien)
                          Pour traiter les transactions. (vos informations, qu'elles soient publiques ou privées, ne seront pas vendues, échangées, transférées ou données à une autre société pour quelque raison que ce soit, sans votre consentement, sauf dans le but formel de livrer le produit acheté ou le service demandé)
                          Pour envoyer des courriels périodiques. Le courriel que vous fournissez pour le traitement des commandes peut être utilisé pour vous envoyer des informations et des mises à jour relatives à votre commande, en plus de recevoir occasionnellement des nouvelles de l'entreprise, des mises à jour, des informations sur les produits ou services connexes, etc.`
            },
            {
                title: "Comment protégeons-nous vos informations ?",
                content: `Nous mettons en œuvre diverses mesures de sécurité pour maintenir la sécurité de vos informations personnelles lorsque vous passez une commande ou lorsque vous saisissez, soumettez ou accédez à vos informations personnelles.
                          Nous proposons l'utilisation d'un serveur sécurisé. Toutes les informations sensibles/de crédit fournies sont transmises via la technologie Secure Socket Layer (SSL) puis cryptées dans la base de données de nos fournisseurs de passerelle de paiement pour être accessibles uniquement aux personnes autorisées disposant de droits d'accès spéciaux à ces systèmes, et sont tenues de garder les informations confidentielles.
                          Les transactions, la collecte et le stockage de vos informations financières privées (cartes de crédit, numéros de sécurité sociale, données financières, etc.) ne seront pas traités ou stockés sur nos serveurs. Ces transactions sont effectuées directement par des fournisseurs tiers pour votre protection et votre sécurité.`
            },
            {
                title: "Utilisons-nous des cookies ?",
                content: `Les cookies de notre site sont utilisés pour le suivi et l'automatisation des examens des élèves. Nous utilisons également ces informations pour traiter des rapports qui ne sont accessibles qu'aux personnes autorisées par les parents ou les écoles. En plus de cela, nous utiliserons également ces informations pour suivre les abonnements actifs et ceux qui ne le sont pas.`
            },
            {
                title: "Divulguons-nous des informations à des tiers ?",
                content: `Nous ne vendons pas, n'échangeons pas et ne transférons pas à des tiers vos informations personnelles identifiables. Cela n'inclut pas les tiers de confiance qui nous aident à exploiter notre site web, à mener nos activités ou à vous servir, pour autant que ces parties acceptent de garder ces informations confidentielles. Nous pouvons également divulguer vos informations lorsque nous pensons que cette divulgation est appropriée pour se conformer à la loi, appliquer les politiques de notre site ou protéger nos droits ou ceux d'autres personnes, la propriété ou la sécurité. Cependant, des informations non personnelles sur les visiteurs peuvent être fournies à d'autres parties à des fins de marketing, de publicité ou autres.`
            },
            {
                title: "California Online Privacy Protection Act Compliance",
                content: `Parce que nous attachons de l'importance à votre vie privée, nous avons pris les précautions nécessaires pour être en conformité avec la loi californienne sur la protection de la vie privée en ligne. Nous ne distribuerons donc pas vos informations personnelles à des tiers sans votre consentement.`
            },
            {
                title: "Children's Online Privacy Protection Act Compliance",
                content: `Nous sommes en conformité avec les exigences de la COPPA (Childrens Online Privacy Protection Act), nous ne récoltons aucune information sur les personnes de moins de 13 ans. Notre site web, nos produits et nos services sont tous destinés aux personnes âgées d'au moins 13 ans. Les parents peuvent choisir d'entrer un pseudonyme pour leur enfant ou d'utiliser leur nom réel.`
            },
            {
                title: "Informations privées sur notre système de formation",
                content: `Vous avez la possibilité d'entrer des informations personnelles telles que votre nom et prénom et votre date de naissance sur notre logiciel. Ces informations ne sont utilisées qu'à votre avantage, et vous n'êtes pas tenu de le faire. Les informations relatives à la date de naissance ne sont utilisées que pour vous proposer des cours sur notre système qui pourraient vous intéresser.`
            },
            {
                title: "Conditions générales",
                content: `Veuillez également consulter notre section Conditions générales qui établit l'utilisation, les clauses de non-responsabilité et les limitations de responsabilité régissant l'utilisation de notre site internet à`
            },
            {
                title: "Votre consentement",
                content: `En utilisant notre site, vous acceptez la présente politique de confidentialité.`
            },
            {
                title: "Modifications de notre politique de confidentialité",
                content: `Si nous décidons de modifier notre politique de confidentialité, nous publierons ces changements sur cette page.`
            },
            {
                title: "Nous contacter",
                content: `Si vous avez des questions concernant cette politique de confidentialité, vous pouvez nous contacter en utilisant les informations ci-dessous.
                          Box 568
                          Hamiota, Manitoba R0M 0T0
                          CANADA`
            }
        ]
    }
}
