export default {
  // Header and main links //
  about: "대하여",
  login: "로그인",
  signin: "로그인",
  signup: "가입하기",
  sendGift: "선물하기",
  subscription: "구독",
  publishers: "출판사",
  platform: "플랫폼",
  parentsStudents: "학생과 학부모",
  schoolsTeachers: "학교와 선생님",
  subscriptionStartingFrom: "<0>$8.99</0>/매월",
  workBooksAndDownloads: "Workbooks &\nDownloads",
  bookstore: "서점",
  ourbookstore: "저희 서점",
  overview: "개요",
  pdf: "PDF",
  // End - Header and main links //
  // Top Titles//
 giveGiftOfLearning: "학습 플랫폼",
  professionallyDesignedCourses:
    "책으로만 배우는 것 보다\n 대화하면서 데이터화한 학습을 하세요.",
  // END Top Titles //
  // App Download Center //
  AppDownload: "출판사를 위한 pubtool",
  AppDownloadDescription:
    "시작하는 것을 도와 드리겠습니다. Pubtool은 처음 시작하는 학생을 위해 온라인 과정을 만들 수 있습니다.",
  // END App Download Center //
  // The BZabc Learning Platform //
  welcomeToWebsite: "Pubtool",
  aboutUsNote:
    "Pubtool은 GravityBrainInc. 에서 제작되었으며 이것을 사용하여 기존에 있던 콘텐츠를 저희 시스템에 쉽게 넣을 수 있습니다.",
  splashBannerPoint1: "코스를 신속하게 개발하기",
  splashBannerPoint2: "빠르고 효율적으로 미디어를 업로드 하기",
  splashBannerPoint3: "코스 요소를 사용자 지정하기 또는 복제하기",
  splashBannerPoint4: "실시간으로 메시지 하기 및 보고서를 보기",
  LetsStartButton: "시작하기",
  // END The BZabc Learning Platform //
  // The BZabc Method - Benefits //
  BZabcMethod: "인터렉티브 하기",
  practicalSkillDevelopment: "위크북을 가지고 있으세요",
  practicalSkillDevelopmentDescription:
    "Pubtool을 사용하면 기존에 있는 문서를 인터렉티브 과정으로 효율적으로 전환 할 수 있습니다.",
  consistency: "레슨을 연계하기",
  consistencyDescription:
    "QR 코드를 활용하여 학생들은 즉시 인앱 액세스를 통해 필요한 레슨을 위크북에서 찾을 수 있습니다",
  totalPhysicalResponse: "모듈식으로 코스 제작",
  totalPhysicalResponseDescription:
    "디지털 제작자와 커리큘럼 작성자는 독립적으로 작업할 수 있으며, 전 세계 어디에서나 콘텐츠를 업로드 할 수 있습니다.",
  dailyPractice: "사용자 지정 코스",
  dailyPracticeDescription:
    "각 학교나 지역별로 수업 및 영상을 맞춤 선택을 할 수 있습니다. 이것을 비공개 하셔도 됩니다.",
  // END The BZabc Method - Benefits //
  // Tech Specs //
  techSpecs: "기술 정보",
  forAllPlatforms: "모든 플랫폼",
  forAllPlatformsDescription:
    "BZabc 학생 앱은 Anriod, iOS, Windows 7 & 10 에서 사용할 수 있습니다. 부모님 앱도 다양한 플랫폼에서 사용할 수 있습니다.",
  blendedLearning: "복합적인 학습법",
  blendedLearningDescription:
    "자료들은 소프트웨어, 콘텐츠 및 다양한 자료들과 연결되어 있어서 지속적이며 일관적인 학습을 할 수 있습니다.",
  personalGuidance: "개인 지도",
  personalGuidanceDescription:
    "BZabc 앱은 학생의 개인 성과에 따라 코스를 안내합니다. 클라우드를 사용하여 학생들이 지속적으로 학습을 이어 나갈 수 있습니다.",
  trackingReporting: "보고서",
  trackingReportingDescription:
    "S학생의 수행 평과는 기록으로 남습니다. 점수와 오답은 보고서에 기록되고 부모님에게 실시간으로 제공됩니다. 부모님은 자녀가 언제 공부하며 어떤 어려움을 겪었는지 알 수 있습니다.",
  // END Tech Specs //
  // Bottom Links //
  store: "Store",
  privacy: "Privacy Policy",
  terms: "Terms of Service",
  // END Bottom Links //
  // Bottom Titles//
  bottomCourseInfo:
    "Delivers all academic subjects for kindergarten through grade six.",
  ourPlatformInfo: "The BZabc Learning Platform",
  // END Bottom Titles //
  //  Additional Info //
  learningPlatform: "Learning Platform",
  learnMore: "Learn more",
  productsNotFound: "Products Not Found...",
  appDownloadCenter: "App Download Center",
  appDownloadCenterDescription:
    "Before using the Kids App, Learners must be registered and enrolled in courses by either their school or their parent/guardian.",
  more: "more...",
  parentsApp: "BZabc Parents",
  kidsApp: "BZabc Kids",
  // END Additional Info //
};
